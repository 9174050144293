const my = {
    "DARE TO DASH": "敢于冲刺，",
    "Dashboard": "仪表板、",
    "About Us": "关于我们，",
    "Games": "游戏，",
    "Speed Farming": "快速农业，",
    "Swap For DTD": "交换为 DTD，",
    "Tokenomic": "代币经济，",
    "English": "English",
    "简体中文": "简体中文,",

    "Click Here To Scroll Down": "单击此处向下滚动，",
    "Your Gateway to Revolutionary Gaming <br /> in the Web 3.0 Era": "Web 3.0 时代通往革命性游戏的门户，",
    "Dare To Dash (DTD) is a pioneering multiverse gaming platform that harnesses the power of blockchain technology to create a fully immersive, community-driven gaming environment. At DTD, we’re not just playing games; we’re setting new standards in how digital assets and player interactions are managed in the virtual world.": "Dare To Dash (DTD) 是一个开创性的多元宇宙游戏平台，它利用区块链技术的力量来创建一个完全身临其境、社区驱动的游戏环境。 在 DTD，我们不只是玩游戏；而是玩游戏。 我们正在为如何在虚拟世界中管理数字资产和玩家互动制定新标准。",
    "Vision": "想象，",
    "Our vision at DTD is to build more than just a gaming platform—we aim to cultivate a thriving digital ecosystem where every player is an integral part of the community. By empowering players with ownership of their gaming assets, DTD transforms traditional gaming dynamics, allowing users to control, trade, and optimize their digital assets without any limitations.": "我们 DTD 的愿景不仅仅是建立一个游戏平台，我们的目标是培育一个蓬勃发展的数字生态系统，让每个玩家都是社区不可或缺的一部分。 通过赋予玩家对其游戏资产的所有权，DTD 改变了传统的游戏动态，允许用户不受任何限制地控制、交易和优化他们的数字资产。",
    "Innovative Gaming Universe": "创新的游戏宇宙，",
    "Dare To Dash introduces a wide array of games, including high-stakes car racing and dynamic horse racing, all set in a meticulously crafted virtual universe. Each game is built on the foundation of blockchain technology, ensuring transparency, fairness, and security for all players.": "Dare To Dash 引入了一系列广泛的游戏，包括高风险赛车和动态赛马，所有游戏都设置在精心制作的虚拟宇宙中。 每个游戏都建立在区块链技术的基础上，确保所有玩家的透明度、公平性和安全性。,",
    "Unique Tokenomics": "独特的代币经济学，",
    "DTD operates on a unique economic model designed to benefit players and investors alike. With our native token, DTD, players can engage in games, participate in special events, and trade in-game items on a secure marketplace. Our tokenomics include rewarding mechanisms that ensure active players and investors gain substantial returns on their engagement and investments.": "DTD 采用独特的经济模式运作，旨在让玩家和投资者受益。 使用我们的原生代币 DTD，玩家可以参与游戏、参加特殊活动并在安全的市场上交易游戏内物品。 我们的代币经济学包括奖励机制，确保活跃的参与者和投资者的参与和投资获得可观的回报。",
    "Join the Revolution": "加入革命，",
    "Dare To Dash is not just a game; it’s a movement towards redefining the gaming industry. Join us as we break barriers and build a new world where gaming meets limitless possibilities. Whether you are a gamer, developer, or investor, DTD offers you a platform to achieve your dreams and redefine the boundaries of digital gaming.": "Dare To Dash 不仅仅是一款游戏；更是一款游戏。 这是重新定义游戏行业的运动。 加入我们，打破障碍，构建一个让游戏充满无限可能的新世界。 无论您是游戏玩家、开发者还是投资者，DTD 都为您提供实现梦想并重新定义数字游戏边界的平台。,",
    "Genre": "类型，",
    "Car Racing": "赛车，",
    "Developer": "开发商,",
    "DTD Entertainment": "DTD娱乐，",
    "Development<br /> Status": "发展<br />现状，",
    "COMING SOON": "即将推出，",
    "Platform": "平台，",
    "Android": "安卓，",
    "Learn More": "了解更多，",
    "Horse Racing": "赛马，",
    "Match 4": "第 4 场比赛，",
    "DTD Entertainment": "DTD娱乐，",
    "Causal": "因果，",
    "Musta Sport": "穆斯塔运动，",
    "Battle Royale": "大逃杀，",
    "Dancing": "跳舞，",
    "“SPEED” Farming Package I": "“SPEED”农业套餐一、",
    "“SPEED” Farming Package I desc": "<p>SPEED 代币旨在打开 DTD 游戏宇宙中的盲盒或神秘盒子。</p> <p>立即开始耕种，在游戏上线之前提前获得 SPEED TOKEN。</p> < p>使用 DTD 代币进行挖矿获取 SPEED 代币。<br /> 最低 3,500 DTD 即可开始挖矿。</p> <p>挖矿奖励将根据使用的 DTD 总量（已使用的 DTD 的 0.3%）计算。<br / > 耕种奖励将以SPEED代币支付</p> <p>注意：所有用于耕种的DTD将被销毁（100%）</p>，",
    "“SPEED” Farming Package II": "“SPEED”农业套餐II，",
    "“SPEED” Farming Package II desc": "<p>SPEED 代币旨在打开 DTD 游戏宇宙中的盲盒或神秘盒子。</p> <p>立即开始耕种，在游戏上线之前提前获得 SPEED TOKEN。</p> < p>成为DTD流动性提供者，<br /> 最低0.5 BNB开始挖矿，作为DTD流动性提供者的额外奖励</p> <p>挖矿奖励将根据每日DTD价值（DTD的0.3%） 价值 @ 2359 GMT+8)</p> <p>农业奖励将以 SPEED 代币形式支付</p>,",
    "Connect Wallet": "连接钱包，",
    "Start Farming": "开始耕种，",
    "Claim Rewards": "领取奖励，",
    "Stake LP Pools": "质押 LP 池，",
    "Countdown:": "倒数：，",
    "Accumulated Raffles Pool (BNB):": "累计莱佛士池 (BNB):,",
    "Market": "市场，",
    "TWAP": "TWAP，",
    "LIMIT": "限制，",
    "Swap": "交换，",
    "Trade DTD in an instants": "瞬间交易DTD，",
    "BNB": "币安银行,",
    "DTD": "DTD，",
    "DTD utilizes a distinctive economic model that benefits both players and investors. Players can use our native DTD token to participate in games, engage in special events, and trade items on a secure marketplace. Our tokenomics feature rewarding systems that provide substantial returns to both active players and investors for their participation and investment.": "DTD 采用独特的经济模型，让玩家和投资者都受益。 玩家可以使用我们的原生 DTD 代币参与游戏、参加特殊活动以及在安全市场上交易物品。 我们的代币经济学以奖励系统为特色，为活跃玩家和投资者的参与和投资提供丰厚的回报。",
    "Tokenomics": "代币经济学，",
    "We are On Pancake Swap": "我们正在进行煎饼交换，",
    "Swap For DTD Now": "立即换成 DTD，",
    "Total Supply:": "总供应量:,",
    "Circulated Supply:": "流通供应：,",
    "Supply Burned:": "供应被烧毁：，",
    "Swap For DTD Now": "立即换成 DTD，",
    "Slippage Fees On Pancake: 4%": "煎饼滑点费：4%，",
    "37.5% of Fees will be distribute to LP Holders Revenue Pool<br /> 25% of Fees will be distribute DTD Marketing and operation’s Wallet, Used for Marketing and Operation<br /> 12.5% of Fees used as daily rewards to Top 100 DTD holders.<br /> 12.5% of Fees Used for DTD ultimate Raffle Pool Rewards<br /> 12.5% of Fees used to buy back DTD and Burn.": "37.5%的费用将分配给LP持有者收入池<br /> 25%的费用将分配给DTD营销和运营的钱包，用于营销和运营<br /> 12.5%的费用将作为每日奖励给DTD前100名 持有者。<br /> 费用的 12.5% 用于 DTD 最终抽奖池奖励<br /> 费用的 12.5% 用于回购 DTD 和销毁。,",
    "Check On Our Smart Contract": "检查我们的智能合约，",
    "Benefits To Be DTD’s Liquidity Providers": "成为 DTD 流动性提供者的好处，",
    "Check On Our Smart Contract": "检查我们的智能合约，",
    "Ultimate Raffle Pool Reward System": "终极抽奖池奖励系统，",
    "2024 Dare To Dash  All rights reserved.": "2024 敢于冲刺 版权所有。,",
};
export default my;