import React from "react";
import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import image from "../../config/image";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, storeUserData } from "../../components/Util";
import images from "../../config/image";
import DeliveryMenuBar from "../../components/DeliveryMenuBar";


class CartEmptySuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
        }
    }

    comingSoon() {
        toast.warning(strings.coming_soon);
    }

    render() {
        return (
            <>
                <DeliveryMenuBar />
                <>
                    <section className="cart-empty-section">
                        <div className="cart-empty-container">
                            <a><img src={images['screen-cart-empty']} alt="" /></a>
                            <p>Item removed from your cart</p>

                        </div>
                        <Link className="card-empty-btn" to={"/delivery"}>Cart is empty</Link>
                    </section>
                </>
            </>
        );
    }
}
export default CartEmptySuccess;
