import React from "react";
import GoogleMap from 'google-maps-react-markers'
import Autocomplete from "react-google-autocomplete";
import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import image from "../../config/image";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, storeUserData } from "../../components/Util";
import images from "../../config/image";
import SubHeader from "../../components/SubHeader";
const GOOGLE_MAPS_APIKEY = 'AIzaSyA_c6HVjCXCO6fSRNvxmiRxtHg3jAKzjz0';

class ApplicationFoodMerchantScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            allow_to_apply: false,
            message: '',
            infodata: {},

            name: "",
            ic_number: "",
            latitude: 2.296471,
            longitude: 111.8897003,
            address1: "",
            address2: "",
            locality: "",
            state: "",
            postcode: "",
            country: "",
            registration_file: null,
            trade_license_file: null,

            errorMessage: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            setTimeout(() => {
                if (this.state.access_token) {
                    this.getData()
                }
            });
        }
    }

    getData() {
        let self = this;
        self.setState({ 'isLoading': true })
        axios({
            method: 'GET',
            url: api.ApplyforMerchantCheck + "?lang=" + self.state.lang,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })

            self.setState({ message: ress.data.message });
            self.setState({ allow_to_apply: (ress.data && ress.data.allow_to_apply) ? ress.data.allow_to_apply : false })
            self.setState({ infodata: ress.data.data });

            navigator.geolocation.getCurrentPosition((position) => {
                self.setState({ latitude: position.coords.latitude });
                self.setState({ longitude: position.coords.longitude });
                setTimeout(() => {
                    self.getAddressFromCoordinates(position.coords.latitude, position.coords.longitude);
                });
            });

        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false })
        });
    }

    changeUploadIC = (event) => {
        if (event && event.target && event.target.files) {
            this.setState({ registration_file: event.target.files[0] });
        }
    };

    changeUploadDL = (event) => {
        if (event && event.target && event.target.files) {
            this.setState({ trade_license_file: event.target.files[0] });
        }
    };

    handleBind() {
        let self = this;

        if (!this.state.name) {
            self.setState({ 'isLoading': false })
            toast.error(strings.please_merchant_name);
            return false;
        }
        if (this.state.registration_file == null) {
            self.setState({ 'isLoading': false })
            toast.error(strings.please_upload_registration_file);
            return false;
        }
        if (this.state.trade_license_file == null) {
            self.setState({ 'isLoading': false })
            toast.error(strings.please_upload_trade_license_file);
            return false;
        }
        if (this.state.address1 == null) {
            self.setState({ 'isLoading': false })
            toast.error(strings.enter_select_address);
            return false;
        }

        let formData = new FormData();
        formData.append("name", self.state.name);
        formData.append("address1", self.state.address1);
        formData.append("address2", self.state.address2);
        formData.append("latitude", self.state.latitude);
        formData.append("longitude", self.state.longitude);
        formData.append("locality", self.state.locality);
        formData.append("state", self.state.state);
        formData.append("postcode", self.state.postcode);
        formData.append("country", self.state.country);
        formData.append("registration_file", self.state.registration_file);
        formData.append("trade_license_file", self.state.trade_license_file);

        axios({
            method: 'POST',
            url: api.ApplyforMerchant,
            data: formData,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'multipart/form-data',
            }
        }).then(function (response) {
            self.setState({ 'isLoading': false })
            if (response.data.error != 200) {
                toast.error(response.data.message);
            } else if (response.data.error === 200) {
                toast.success(response.data.message);
                self.timeoutHandle = setTimeout(() => {
                    self.props.history.go('/application');
                }, 1000);
            }
        }).catch(function (error) {
            self.setState({ 'isLoading': false });
        })
    }

    handleSelect = async (value) => {
        try {
            if (value && value.geometry && value.geometry.location) {
                this.setState({ setMapReady: false })
                var lat = value.geometry.location.lat();
                var lng = value.geometry.location.lng();
                this.getAddressFromCoordinates(lat, lng, true);
            }
            this.setState({ address: (value && value.formatted_address) ? value.formatted_address : "" });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    getAddressFromCoordinates(latitude, longitude, is_start_address = false) {
        let self = this;
        var URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_APIKEY}`
        fetch(URL).then(response => response.json()).then(responseJson => {
            if (responseJson['status'] == 'OK') {
                var format_address = responseJson['results'][0]['formatted_address'];
                self.setState({ address: format_address });
                self.setState({ address1: format_address });
                self.setState({ latitude: latitude });
                self.setState({ longitude: longitude });

                var address_components = responseJson['results'][0]['address_components'];
                if (address_components) {
                    address_components.map((component, key) => {
                        const componentType = component.types[0];

                        var search_string = '';
                        switch (componentType) {
                            case "street_number": {
                                search_string = `${component.long_name} ${format_address}`;
                                self.setState({ search_string: search_string });
                                break;
                            }

                            case "route": {
                                search_string += component.short_name;
                                self.setState({ search_string: search_string });
                                break;
                            }

                            case "postal_code": {
                                var postcode = `${component.long_name}`;
                                self.setState({ postcode: postcode });
                                break;
                            }

                            case "postal_code_suffix": {
                                var postcode = `${component.long_name}`;
                                self.setState({ landmark: postcode });
                                break;
                            }
                            case "locality":
                                var locality = component.long_name;
                                self.setState({ city: locality });
                                break;

                            case "administrative_area_level_1": {
                                var state = component.short_name;
                                self.setState({ state: state });
                                break;
                            }
                            case "country":
                                var country = component.long_name;
                                self.setState({ country: country });
                                break;
                        }
                    });

                    self.setState({ setMapReady: true });
                }
            }
        }).catch(e => {
            console.warn(e)
        });
    }

    render() {
        return (
            <>
                <>
                    <SubHeader data={{ headerTitle: strings["Apply_as_Food_Merchant"] }} />

                    <section className="form-section delivery-rider-section">
                        <h2>{this.state.message}</h2>
                        {this.state.allow_to_apply ?
                            <>
                            </>
                            : null}
                        {!this.state.allow_to_apply ?
                            <>
                                <form action="javascript:void(0)" className="form">
                                    <div className="input-box">
                                        <label>{strings["Name_of_Business_Shop"]}<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ name: e.target.value })} defaultValue={this.state.name} type="text" placeholder={strings.enter_Name_of_Business_Shop} required />
                                        <div className='row'> {this.state.errorMessage.name && <div className='errorMessage'> {this.state.errorMessage.name[0]} </div>}</div>
                                    </div>
                                    <div className="input-box">
                                        <label>{strings.ic_number}<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ ic_number: e.target.value })} defaultValue={this.state.ic_number} type="text" placeholder={strings.please_ic_number} required />
                                        <div className='row'> {this.state.errorMessage.ic_number && <div className='errorMessage'> {this.state.errorMessage.ic_number[0]} </div>}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>{strings["Business_Registration_File"]}<span className="compulsory">*</span></label>
                                        <input onChange={this.changeUploadIC} defaultValue={this.state.ic_file} type="file" name="ic" />
                                        <div className='row'> {this.state.errorMessage.ic_file && <div className='errorMessage'> {this.state.errorMessage.ic_file[0]} </div>}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>{strings["Trade_License_File"]}<span className="compulsory">*</span></label>
                                        <input onChange={this.changeUploadDL} defaultValue={this.state.dl_file} type="file" name="drivinglicense" />
                                        <div className='row'> {this.state.errorMessage.dl_file && <div className='errorMessage'> {this.state.errorMessage.dl_file[0]} </div>}</div>
                                    </div>

                                    <div className="input-box">
                                        <div style={{ height: '230px', width: '100%' }}>
                                            {this.state.setMapReady ?
                                                <GoogleMap
                                                    apiKey={GOOGLE_MAPS_APIKEY}
                                                    defaultCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
                                                    defaultZoom={12}
                                                    mapMinHeight="230"
                                                    onGoogleApiLoaded={({ map, maps }) => {
                                                        console.log("map, maps", map, maps);
                                                        this.setState({ setMapReady: true })
                                                    }}
                                                    options={{ gestureHandling: 'none'}}
                                                    onChange={(map) => console.log('Map moved', map)}
                                                >
                                                </GoogleMap>
                                                : null}
                                        </div>
                                    </div>

                                    <div className="input-box">
                                        <label>{strings["Address"]}</label>
                                        <Autocomplete
                                            apiKey={GOOGLE_MAPS_APIKEY}
                                            onPlaceSelected={this.handleSelect}
                                            value={this.state.address1}
                                            onChange={(e) => this.setState({ address1: e.target.value })}
                                            options={{
                                                componentRestrictions: { country: "my" },
                                            }}
                                        />
                                        <div className='row'> {this.state.errorMessage.address && <div className='errorMessage'> {this.state.errorMessage.address[0]} </div>}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>{strings["Postcode"]}<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ postcode: e.target.value })} defaultValue={this.state.postcode} type="text" placeholder={strings["Enter_Postcode"]} required />
                                        <div className='row'> {this.state.errorMessage.postcode && <div className='errorMessage'> {this.state.errorMessage.postcode[0]} </div>}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>{strings["Locality"]}<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ locality: e.target.value })} defaultValue={this.state.locality} type="text" placeholder={strings["Enter_Locality"]} required />
                                        <div className='row'> {this.state.errorMessage.locality && <div className='errorMessage'> {this.state.errorMessage.locality[0]} </div>}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>{strings["state"]}<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ state: e.target.value })} defaultValue={this.state.state} type="text" placeholder={strings["enter_state"]} required />
                                        <div className='row'> {this.state.errorMessage.state && <div className='errorMessage'> {this.state.errorMessage.state[0]} </div>}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>{strings["country"]}<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ country: e.target.value })} defaultValue={this.state.country} type="text" placeholder={strings["enter_country"]} required />
                                        <div className='row'> {this.state.errorMessage.country && <div className='errorMessage'> {this.state.errorMessage.country[0]} </div>}</div>
                                    </div>

                                    <button onClick={() => this.handleBind()} className="">Send Application</button>

                                </form>
                            </>
                            : null}
                    </section>
                </>
            </>
        );
    }
}
export default ApplicationFoodMerchantScreen;
