import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";
import _ from "lodash";

class WithdrawScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            amount: 30,
            data: [],
            paymentType: '',
            USER_DATA: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
        }
    }

    onPrssTopup() {
        const self = this;

        if (parseInt(this.state.amount) <= 0) {
            toast.error(strings.enter_amount);
            return false;
        }

        self.setState({ 'isLoading': true })
        axios({
            method: 'POST',
            url: api.withdraw,
            data: {
                amount: self.state.amount
            },
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            self.setState({ isLoading: false });
            if (ress.data.error == "200" || ress.data.error == 200) {
                toast.success(ress.data.message);
                self.props.history.go('/profile/credit');
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (error) {
            self.setState({ 'isLoading': false })
            toast.error(strings.no_internet);
        });
    }

    render() {
        return (
            <>
                <div className="home-content-wrapper">
                    <SubHeader data={{ headerTitle: strings["Withdrawal_Belibeli_Credit"], not_display_help: true }} />

                    <section className="topup-credit-section">
                        <div className="topup-credit-container">
                            <div className="topup-amount-box">
                                <h2>{strings["Withdraw"]}</h2>
                                <div className="topup-input-box">
                                    <label>{strings["Withdrawal_Amount"]}</label>
                                    <input onChange={(e) => this.setState({ amount: e.target.value })} defaultValue={this.state.amount} type="number" min={0} placeholder={strings["topup_enter_amount"]} />
                                    <p>{strings["rm"]} <span></span></p>
                                </div>
                            </div>

                            <hr className="topup-credit-bottom-line" />

                            <div className="topup-credit-bottom">
                                <hr />
                                <div className="topup-credit-total-box">
                                    <p className="topup-total-title">{strings["total"]}</p>
                                    <p className="topup-total-amount"> {strings.rm + " " + number_format((this.state.amount && this.state.amount != "NaN") ? this.state.amount : 0, 2, '.', ',')}</p>
                                </div>
                                <hr />
                                <div className="topup-credit-confirm-container">
                                    <button onClick={() => this.onPrssTopup()} className="topup-credit-confirm-button">Confirm</button>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </>
        );
    }
}
export default WithdrawScreen;
