import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";

class ForgotPasswordScreen extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.history);
        this.state = {
            selectedLang: localStorage.getItem("language"),
            successModal: false,
            mobile: null,
            otp_code: null,
            password: null,
            password_show: false,
            errorMessage: [],
        }
    }

    async componentDidMount() {
    }

    onSubmit() {
        const self = this;
        axios({
            method: 'POST',
            url: api.ForgotPassword,
            data: {
                mobile: this.state.mobile ?? '',
                otp_code: this.state.otp_code ?? '',
                password: this.state.password ?? '',
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': this.state.lang,
            }
        }).then(async function (ress) {
            console.log(ress.data);
            if (ress.data.error == 202 || ress.data.error == '202') {
                if (ress.data.errors && ress.data.errors.length != 0) {
                    self.setState({ errorMessage: ress.data.errors });
                } else {
                    toast.error(ress.data.message);
                }
            } else {
                toast.success(ress.data.message);
                self.setState({ successModal: true });
                // self.props.history.replace({ pathname: `/forgot-successful` })
                return false;
            }
        }).catch(function (err) {
            if (err && err.response && (err.response.status === '422')) {
                if (err && err.response && err.response.data && err.response.data.errors) {
                    self.setState({ errorMessage: err.response.data.errors });
                }
            }
            if (err && err.response && err.response.data && (err.response.data.error === '202' || err.response.data.error === 202)) {
                let msg = err.response.data && err.response.data.message ? err.response.data.message : strings.something_wrong;
                toast.error(msg);
            } else {
                toast.error(strings.invalid_login_details);
            }
        });
    }

    sendOtp() {
        const self = this;
        axios({
            method: 'POST',
            url: api.ForgotPasswordOtp,
            data: {
                mobile: this.state.mobile ?? '',
            },
            headers: { 'Content-Type': 'application/json', }
        }).then(function (ress) {
            console.log(ress.data);
            if (ress.data.error == 202 || ress.data.error == '202') {
                if (ress.data.errors && ress.data.errors.length != 0) {
                    self.setState({ errorMessage: ress.data.errors });
                } else {
                    toast.error(ress.data.message);
                }
            } else {
                toast.success(ress.data.message);
            }
        }).catch(function (err) {
            if (err && err.response && (err.response.status === '422')) {
                if (err && err.response && err.response.data && err.response.data.errors) {
                    self.setState({ errorMessage: err.response.data.errors });
                }
            }
            if (err && err.response && err.response.data && (err.response.data.error === '202' || err.response.data.error === 202)) {
                let msg = err.response.data && err.response.data.message ? err.response.data.message : strings.something_wrong;
                toast.error(msg);
            } else {
                toast.error(strings.server_error);
            }
        });
    }

    render() {
        return (
            <>
                <SubHeader data={{ headerTitle: "Forgot Password" }} />
                <>
                    <section className="form-section password-section">
                        <div className="form">
                            <div className="input-box otp-box">
                                <label>Mobile Number<span className="compulsory">*</span></label>
                                <input onChange={(e) => this.setState({ mobile: e.target.value })} defaultValue={this.state.mobile} type="text" placeholder="Enter Mobile Number" required />
                                <span className="send-otp" onClick={() => this.sendOtp()}>Send OTP</span>
                                <div className='row'> {this.state.errorMessage.mobile && <div className='errorMessage'> {this.state.errorMessage.mobile[0]} </div>}</div>
                            </div>
                            <div className="input-box">
                                <label>OTP<span className="compulsory">*</span></label>
                                <input onChange={(e) => this.setState({ otp_code: e.target.value })} defaultValue={this.state.otp_code} type="text" placeholder="Enter OTP" required />
                                <div className='row'> {this.state.errorMessage.otp_code && <div className='errorMessage'> {this.state.errorMessage.otp_code[0]} </div>}</div>
                            </div>

                            <div className="new-password-box">
                                <h3>Enter New Password</h3>
                                <div className="input-box password-input">
                                    <label>Password<span className="compulsory">*</span></label>
                                    <input onChange={(e) => this.setState({ password: e.target.value })} defaultValue={this.state.password} type={this.state.password_show ? "text" : "password"} placeholder="Enter Password" />
                                    <span onClick={() => this.setState({ password_show: !this.state.password_show })}><i className="fa-solid fa-eye-slash" id="toggler"></i></span>
                                    <div className='row'> {this.state.errorMessage.password && <div className='errorMessage'> {this.state.errorMessage.password[0]} </div>}</div>
                                </div>
                            </div>

                            <div className="button-box"><button onClick={() => this.onSubmit()} className="save-button">Save</button></div>

                        </div>
                    </section>

                    <Modal
                        isOpen={this.state.successModal}
                        ariaHideApp={false}
                        className={"modal_main_container"}
                    >
                        <section className="modal_section">
                            <div className="popup-body">
                                <section className="popup-section">
                                    <div className="popup-container">
                                        <div className="top">
                                            <img src={images["password-changed"]} alt="" />
                                            <p>Password Changed</p>
                                        </div>

                                        <div className="back-button"><Link to={"/"}>Back</Link></div>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </Modal>
                </>
            </>
        );
    }
}
export default withRouter(ForgotPasswordScreen);
