import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";

class TopupScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            amount: "1",
            data: [],
            paymentType: 1,
            USER_DATA: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
        }
    }

    onPrssTopup() {
        const self = this;

        if (parseInt(this.state.amount) <= 0) {
            toast.error(strings.enter_amount_min_1);
            return false;
        }

        self.setState({ 'isLoading': true })
        axios({
            method: 'POST',
            url: api.TopUpAdd,
            data: {
                amount: self.state.amount,
                type: self.state.paymentType
            },
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            console.log(ress.data)
            self.setState({ isLoading: false });
            if (ress.data.error == "200" || ress.data.error == 200) {
                var queryString = Object.keys(ress.data).map(key => key + '=' + ress.data[key]).join('&');
                console.log(queryString);
                if (self.state.paymentType == "0") {
                    setTimeout(function () {
                        window.location.replace('DepositWebBrowser', { url: api.Deposit2C2P + "?" + queryString + "&lang=" + self.state.lang });
                    }, 3000);
                } else if (self.state.paymentType == "1" || self.state.paymentType == "2") {
                    var paymentId = ress.data.data;
                    console.log(paymentId);
                    if(paymentId){
                        window.location.href = 'https://www.wego.global/eghl/public?data=' + paymentId;
                    }
                }
                toast.success(ress.data.message);
            }
            else {
                toast.error(ress.data.message);
            }
        }).catch(function (error) {
            console.log(error)
            self.setState({ 'isLoading': false })
            toast.error(strings.no_internet);
        });
    }

    render() {
        return (
            <>
                <div className="home-content-wrapper">
                    <SubHeader data={{ headerTitle: strings["Top_Up_Belibeli_Credit"], not_display_help: true }} />

                    <section className="topup-credit-section">
                        <div className="topup-credit-container">
                            <div className="topup-amount-box">
                                <h2>{strings["Top_Up"]}</h2>
                                <div className="topup-input-box">
                                    <label>{strings["Top_Up_Amount"]}</label>
                                    <input onChange={(e) => this.setState({ amount: e.target.value })} defaultValue={this.state.amount} type="text" />
                                    <p>{strings["rm"]} <span></span></p>
                                </div>
                            </div>

                            <hr className="topup-credit-bottom-line" />
                            {/* 
                            <div onClick={() => this.setState({ "paymentType": "0" })} className="fpx-box" style={{ marginBlock: 10 }}>
                                <img src={images["sarawake"]} alt="" style={{ width: 50, height: 50 }} />
                                {this.state.paymentType == "0" ?
                                    <div className="tick-button tick-green"><i className="fa-solid fa-check"></i></div>
                                    :
                                    <div className="tick-button"><i className="fa-solid fa-check"></i></div>
                                }
                            </div> */}
                            <div onClick={() => this.setState({ "paymentType": "1" })} className="fpx-box" style={{ marginBlock: 10 }}>
                                <img src={images["fpx-logo"]} alt="" style={{ width: 50, height: 50 / 2.66 }} />
                                {this.state.paymentType == "1" ?
                                    <div className="tick-button tick-green"><i className="fa-solid fa-check"></i></div>
                                    :
                                    <div className="tick-button"><i className="fa-solid fa-check"></i></div>
                                }
                            </div>
                            {/* <div onClick={() => this.setState({ "paymentType": "2" })} className="fpx-box" style={{ marginBlock: 10 }}>
                                <i className="fa-solid fa-wallet"></i>
                                {this.state.paymentType == "2" ?
                                    <div className="tick-button tick-green"><i className="fa-solid fa-check"></i></div>
                                    :
                                    <div className="tick-button"><i className="fa-solid fa-check"></i></div>
                                }
                            </div> */}

                            <div className="topup-credit-bottom">
                                <hr />
                                <div className="topup-credit-total-box">
                                    <p className="topup-total-title">{strings["total"]}</p>
                                    <p className="topup-total-amount"> {strings.rm + " " + number_format((this.state.amount && this.state.amount != "NaN") ? this.state.amount : 0, 2, '.', ',')}</p>
                                </div>
                                <hr />
                                <div className="topup-credit-confirm-container">
                                    <button onClick={() => this.onPrssTopup()} className="topup-credit-confirm-button">Confirm</button>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </>
        );
    }
}
export default TopupScreen;
