import React from "react";
import axios from "axios";
import api from "../config/api";
import { toast } from 'react-toastify';
import { Link, NavLink } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import strings from "./Language";
import { Helmet, HelmetProvider } from 'react-helmet-async';

class Header extends React.Component {
    constructor(props) {
        super(props);
        var headerTitle = (this.props && this.props.data && this.props.data.headerTitle) ? this.props.data.headerTitle : "";
        this.state = {
            lang: strings.getLanguage(),
            is_mobile_menu: false,
            is_lang_menu: false,
            headerTitle: headerTitle,
        }
    }

    async componentDidMount() {
        console.log("this.state.lang", this.state.lang);
    }

    render() {
        return (
            <>
                <HelmetProvider>
                    <Helmet>
                        <title>{strings["BeliBeli"]} | {this.state.headerTitle}</title>
                    </Helmet>
                </HelmetProvider>

                {/* Top Bar */}
                <section className="topbar-section">
                    <div className="topbar-container">
                        <div className="topbar-left-home"><i className="fa-solid fa-chevron-left"></i></div>
                        <div className="topbar-center-home">{this.state.headerTitle}</div>
                    </div>
                </section>

            </>
        );
    }
}

export default Header;