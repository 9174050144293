import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";
import moment from "moment";

class CreditScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            activityTab: "Top_Up_History",
            TopUpList: [],
            TopUpList_next_page_url: null,
            WithdrawalList: [],
            WithdrawalList_next_page_url: null,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            setTimeout(() => {
                this.setState({ USER_DATA: userData.data });
                this.getReport(api.TopUpList);
                this.getWithdrawalHistoryReport(api.withdraw);
            });
        }
    }

    getReport(API) {
        const self = this;
        self.setState({ 'isLoading': true, TopUpList_next_page_url: null })
        axios({
            method: 'GET', url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })
            if (ress.data.error == 200) {
                self.setState({
                    TopUpList: [...self.state.TopUpList, ...ress.data.message.data],
                    TopUpList_next_page_url: ress.data.message.next_page_url
                });
            }
        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false });
        });
    }

    getWithdrawalHistoryReport(API) {
        const self = this;
        self.setState({ 'isLoading': true, WithdrawalList_next_page_url: null })
        axios({
            method: 'GET', url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })
            if (ress.data.error == 200) {
                self.setState({
                    WithdrawalList: [...self.state.WithdrawalList, ...ress.data.data.data],
                    WithdrawalList_next_page_url: ress.data.message.next_page_url
                });
            }
        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false });
        });
    }

    render() {
        return (
            <>
                <div className="credit-body" style={{ height: window.innerHeight }}>

                    <section className="topbar-section credit-topbar-section">
                        <SubHeader data={{ headerTitle: strings["bego_credit"] }} />

                        <div className="credit-amount">
                            <Link to={"/profile/credit/reports"}>
                                <p>{strings["rm"]} <span>{this.state.USER_DATA && this.state.USER_DATA.credit && this.state.USER_DATA.credit.bego_credit ? number_format(this.state.USER_DATA.credit.bego_credit, 2, '.', ',') : '0.00'}</span></p>
                            </Link>
                        </div>
                        <div className="credit-mainbox">
                            <Link to={"/topup-credit"} className="topup-box">
                                <div className="topup-img"><img src={images["topup-icon"]} alt="" /></div>
                                <p>{strings["Top_Up"]}</p>
                            </Link>
                            <div className="vertical-line"></div>
                            <Link to={"/withdraw-credit"} className="withdraw-box">
                                <div className="withdraw-img"><img src={images["withdraw-icon"]} alt="" /></div>
                                <p>{strings["Withdraw"]}</p>
                            </Link>
                        </div>
                    </section>

                    <section className="credit-history-section">
                        <div className="tabs credit-tabs">
                            <input type="radio" id="tab1" className="credit-tab1" name="tab-control" defaultChecked />
                            <input type="radio" id="tab2" className="credit-tab2" name="tab-control" />

                            <ul className="credit-tab-ul">
                                <li key={"top_up"} title="Top Up History">
                                    <label className="credit-label" htmlFor="tab1" role="button">{strings["Top_Up_History"]}</label>
                                </li>
                                <li key={"Withdrawal"} title="Withdrawal History">
                                    <label className="credit-label" htmlFor="tab2" role="button">{strings["Withdrawal_History"]}</label>
                                </li>
                            </ul>

                            <div className="credit-content">
                                <section className="topup-history-section">
                                    <div className="topup-container">
                                        {this.state.TopUpList.map((item, i) =>
                                            <>
                                                <div key={"1000_" + i}>
                                                    <div className="topup-row">
                                                        <div className="topup-col-1">
                                                            <div className="topup-icon">
                                                                <img src={images["topup-icon"]} alt="" />
                                                            </div>
                                                            <div className="topup-detail">
                                                                <h2 className="topup-id">{item.order_id}</h2>
                                                                <h3 className="topup-process">{item.status_message}</h3>
                                                                <h3 className="topup-date">{moment(item.created_at).format("DD MMM YYYY")}</h3>
                                                            </div>
                                                        </div>
                                                        <div className="topup-col-2">
                                                            <div className="topup-amount">
                                                                <p>{strings.rm} <span>{(item.amount > 0) ? "+" : ""}{number_format(item.amount, 2, '.', ',')}</span></p>
                                                            </div>
                                                            <div className="topup-status"><p style={{ color: item.status_color }}>{item.status_word}</p></div>
                                                        </div>
                                                    </div>

                                                    <hr className="credit-row-bottom-line" />
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="topup-view-button-container">
                                        <Link className="topup-view-button" to={"/profile/credit/transaction"}>View More</Link>
                                    </div>
                                </section>

                                <section className="withdrawal-history-section">
                                    <div className="withdraw-container">
                                        {this.state.WithdrawalList.map((item, i) =>
                                            <div key={"2000_" + i}>
                                                <div className="withdraw-row">
                                                    <div className="withdraw-col-1">
                                                        <div className="withdraw-icon">
                                                            <img src={images["topup-icon"]} alt="" />
                                                        </div>
                                                        <div className="withdraw-detail">
                                                            <h2 className="withdraw-id">{item.bank_account_no.replace(/.(?=.{5})/g, 'x')}</h2>
                                                            <h3 className="withdraw-process">{item._status}</h3>
                                                            <h3 className="withdraw-date">{moment(item.created_at).format("DD MMM YYYY")}</h3>
                                                        </div>
                                                    </div>
                                                    <div className="withdraw-col-2">
                                                        <div className="withdraw-amount">
                                                            <p>{strings.rm} <span>{(item.amount > 0) ? "+" : ""}{number_format(item.amount, 2, '.', ',')}</span></p>
                                                        </div>
                                                        <div className="withdraw-status processing"><p style={{ color: item.status_color }}>{item.status_word}</p></div>
                                                    </div>
                                                </div>

                                                <hr className="credit-row-bottom-line" />
                                            </div>
                                        )}
                                    </div>
                                    <div className="withdraw-view-button-container">
                                        <Link className="withdraw-view-button" to={"/profile/withdrawal/transaction"}>View More</Link>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}
export default CreditScreen;
