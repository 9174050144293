import React from "react";
import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import image from "../../config/image";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, storeUserData } from "../../components/Util";
import images from "../../config/image";
import SubHeader from "../../components/SubHeader";


class ApplicationMarketplaceMerchantScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            allow_to_apply: false,
            message: '',
            name: '',
            file1: null,
            file2: null,
            errorMessage: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            setTimeout(() => {
                if (this.state.access_token) {
                    this.getData()
                }
            });
        }
    }

    getData() {
        let self = this;
        self.setState({ 'isLoading': true })
        axios({
            method: 'GET', url: api.ApplyforVendorCheck + "?lang=" + self.state.lang,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })

            self.setState({ message: ress.data.message });
            self.setState({ allow_to_apply: (ress.data && ress.data.allow_to_apply) ? ress.data.allow_to_apply : false })
            self.setState({ infodata: ress.data.data });
        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false })
        });
    }

    changeUploadIC = (event) => {
        if (event && event.target && event.target.files) {
            this.setState({ file1: event.target.files[0] });
        }
    };

    changeUploadDL = (event) => {
        if (event && event.target && event.target.files) {
            this.setState({ file2: event.target.files[0] });
        }
    };

    handleBind() {
        let self = this;

        let formData = new FormData();
        formData.append("name", self.state.name);
        formData.append("lang", self.state.lang);
        formData.append("file1", self.state.file1);
        formData.append("file2", self.state.file2);

        axios({
            method: 'POST',
            url: api.ApplyforVendor,
            data: formData,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'multipart/form-data',
            }
        }).then(function (response) {
            self.setState({ 'isLoading': false })
            if (response.data.error != 200) {
                toast.error(response.data.message);
            } else if (response.data.error === 200) {
                toast.success(response.data.message);
                self.timeoutHandle = setTimeout(() => {
                    self.props.history.go('/application');
                }, 1000);
            }
        }).catch(function (error) {
            self.setState({ 'isLoading': false });
        })
    }

    render() {
        return (
            <>
                <>
                    <SubHeader data={{ headerTitle: strings["Apply_as_Marketplace_Merchant"] }} />

                    <section className="form-section delivery-rider-section">
                        <h2>{this.state.message}</h2>

                        {this.state.allow_to_apply ?
                            <>
                                <form action="javascript:void(0)" className="form">
                                    <div className="input-box">
                                        <label>{strings["Name_of_Business_Shop"]}<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ name: e.target.value })} defaultValue={this.state.name} type="text" placeholder={strings["enter_Name_of_Business_Shop"]} required />
                                        <div className='row'> {this.state.errorMessage.name && <div className='errorMessage'> {this.state.errorMessage.name[0]} </div>}</div>
                                    </div>
                                    <div className="input-box">
                                        <label>{strings.ic_number}<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ ic_number: e.target.value })} defaultValue={this.state.ic_number} type="text" placeholder={strings.please_ic_number} required />
                                        <div className='row'> {this.state.errorMessage.ic_number && <div className='errorMessage'> {this.state.errorMessage.ic_number[0]} </div>}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>{strings["upload_ic_front"]}<span className="compulsory">*</span></label>
                                        <input onChange={this.changeUploadIC} defaultValue={this.state.file1} type="file" name="ic" />
                                        <div className='row'> {this.state.errorMessage.file1 && <div className='errorMessage'> {this.state.errorMessage.file1[0]} </div>}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>{strings["upload_ic_back"]}<span className="compulsory">*</span></label>
                                        <input onChange={this.changeUploadDL} defaultValue={this.state.file2} type="file" name="drivinglicense" />
                                        <div className='row'> {this.state.errorMessage.file2 && <div className='errorMessage'> {this.state.errorMessage.file2[0]} </div>}</div>
                                    </div>

                                    <button onClick={() => this.handleBind()} className="send-application-button">Send Application</button>

                                </form>
                            </>
                            : null}
                    </section>
                </>
            </>
        );
    }
}
export default ApplicationMarketplaceMerchantScreen;
