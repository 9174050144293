import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";

class AccountSettingsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            bank_name: '',
            bank_account_holder: '',
            bank_account_no: '',
            ic_number: '',
            errorMessage: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            setTimeout(() => {
                this.getData();
            });
        }
    }

    getData() {
        let self = this;
        self.setState({ 'isLoading': true })
        axios({
            method: 'GET',
            url: api.bank,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data.error == 200) {
                self.setState({
                    'isLoading': false,
                    'bank_account_holder': (ress.data.message && ress.data.message.bank_account_holder) ? ress.data.message.bank_account_holder : '',
                    'bank_account_no': (ress.data.message && ress.data.message.bank_account_no) ? ress.data.message.bank_account_no : '',
                    'bank_name': (ress.data.message && ress.data.message.bank_name) ? ress.data.message.bank_name : '',
                    'ic_number': (ress.data.message && ress.data.message.ic_number) ? ress.data.message.ic_number : '',
                })
            }
        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false });
        });
    }

    onSave() {
        let self = this;
        axios({
            method: 'POST',
            url: api.bank,
            data: {
                bank_account_holder: this.state.bank_account_holder ?? '',
                bank_account_no: self.state.bank_account_no ?? '',
                bank_name: self.state.bank_name ?? '',
                ic_number: self.state.ic_number ?? '',
            },
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            if (ress.data.error == 200) {
                toast.success(ress.data.message)
                self.setState({ 'isLoading': false });
                self.getData();
            } else {
                toast.error(ress.data.message)
            }
        }).catch(function (erro) {
            toast.error(strings.server_error)
            self.setState({ 'isLoading': false });
        });
    }

    render() {
        return (
            <>
                <MenuBar />
                <div className="home-content-wrapper">
                    <SubHeader data={{ headerTitle: strings["Account_Settings"] }} />

                    <section className="form-section account-settings-section">
                        <form action="#" className="form">
                            <div className="input-box">
                                <label>{strings["bank_name"]}<span>*</span></label>
                                <input onChange={(e) => this.setState({ bank_name: e.target.value })} defaultValue={this.state.bank_name} type="text" placeholder="Enter Bank Name" required />
                                <div className='row'> {this.state.errorMessage.bank_name && <div className='errorMessage'> {this.state.errorMessage.bank_name[0]} </div>}</div>
                            </div>

                            <div className="input-box">
                                <label>Account Name<span>*</span></label>
                                <input onChange={(e) => this.setState({ bank_account_holder: e.target.value })} defaultValue={this.state.bank_account_holder} type="text" placeholder="Enter Account Name" required />
                                <div className='row'> {this.state.errorMessage.bank_account_holder && <div className='errorMessage'> {this.state.errorMessage.bank_account_holder[0]} </div>}</div>
                            </div>

                            <div className="input-box">
                                <label>Account Number<span>*</span></label>
                                <input onChange={(e) => this.setState({ bank_account_no: e.target.value })} defaultValue={this.state.bank_account_no} type="number" placeholder="Enter Account Number" required />
                                <div className='row'> {this.state.errorMessage.bank_account_no && <div className='errorMessage'> {this.state.errorMessage.bank_account_no[0]} </div>}</div>
                            </div>

                            <div className="input-box">
                                <label>IC Number<span>*</span></label>
                                <input onChange={(e) => this.setState({ ic_number: e.target.value })} defaultValue={this.state.ic_number} type="text" placeholder="Enter IC Number" required />
                                <div className='row'> {this.state.errorMessage.ic_number && <div className='errorMessage'> {this.state.errorMessage.ic_number[0]} </div>}</div>
                            </div>

                            <div className="actions-btn-wrapper">
                                <a onClick={() => this.onSave()} className="actions-btn">Save</a>
                            </div>
                        </form>
                    </section>

                </div>
            </>
        );
    }
}
export default AccountSettingsScreen;
