import React from "react";
import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import image from "../../config/image";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import _ from "lodash";
import images from "../../config/image";
import moment from "moment";
import SubHeader from "../../components/SubHeader";


class CheckoutScreen extends React.Component {
    constructor(props) {
        super(props);
        var restaurantId = this.props.match.params.restaurantid;
        var itemId = this.props.match.params.itemid;
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            restaurantId: restaurantId ?? '',
            itemId: itemId ?? '',
            first_load_completed: false,
            is_cart_empty: true,
            can_cod: false,
            can_credit: false,
            address_list: [],
            cart: {},
            cartitems: [],
            data: {},
            restaurant: {},
            pay_methods: [],
            payment_method: 0,
            address_id: null,
            delivery_fee: 0,
            removeAddressModal: false,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            this.setState({ USER_DATA: userData.data });
            setTimeout(() => {
                this.getCheckoutData(api.restaurant_cart);
            });
        }
    }

    getCheckoutData(url) {
        if (url == "" || url == null) {
            return true;
        }
        const self = this;
        self.setState({ 'isLoading': true });
        console.log("url:::::::::::", url);
        axios({
            method: 'get', url: url,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })
            if (ress.data.error == 200) {
                self.setState({ 'address_list': (ress.data.address_list) ? ress.data.address_list : [] })

                if (self.state.address_id == null) {
                    self.setState({ 'address_id': (ress.data.delivery_address && ress.data.delivery_address.id) ? ress.data.delivery_address.id : '' })
                }

                self.setState({ 'cart': (ress.data.cart && ress.data.cart) ? ress.data.cart : {} })
                self.setState({ 'cartitems': (ress.data.cart && ress.data.cart && ress.data.cart.items) ? ress.data.cart.items : [] })
                self.setState({ 'restaurant': (ress.data.cart && ress.data.restaurant) ? ress.data.restaurant : {} })
                self.setState({ 'delivery_address': (ress.data.delivery_address) ? ress.data.delivery_address : {} })
                self.setState({ 'data': (ress.data) ? ress.data : {} });
                self.setState({ 'pay_methods': (ress.data.payment_methods) ? ress.data.payment_methods : [] })
                self.setState({ 'can_cod': (ress.data.restaurant && ress.data.restaurant.can_cod) ? ress.data.restaurant.can_cod : false })
                self.setState({ 'can_credit': (ress.data.restaurant && ress.data.restaurant.can_credit) ? ress.data.restaurant.can_credit : false })
                self.setState({ 'restaurantId': (ress.data.restaurant && ress.data.restaurant.id) ? ress.data.restaurant.id : false })
                self.setState({ 'delivery_fee': (ress.data.restaurant && ress.data.restaurant.delivery_fee) ? ress.data.restaurant.delivery_fee : 0 })
                self.setState({ 'is_cart_empty': false });
                _.filter(ress.data.payment_methods, function (o) {
                    if (o.id == 1) {
                        self.setState({ 'payment_method': o.id });
                    }
                    if (o.id == 2 && !self.state.can_cod) {
                        self.setState({ 'payment_method': o.id });
                    }
                });
                self.setState({ first_load_completed: true });
            } else {
                self.props.history.replace({ pathname: `/cart-empty` })
                toast.error(ress.data.message);
            }
        }).catch(function (erro) {
            self.setState({ 'isLoading': false });
            toast.error(strings.server_error)
            self.setState({ first_load_completed: true });
        });
    }

    changeAddress(aid) {
        console.log((aid) ? api.restaurant_cart + "?address_id=" + aid : api.restaurant_cart);
        this.getCheckoutData((aid) ? api.restaurant_cart + "?address_id=" + aid : api.restaurant_cart);
        this.setState({ address_id: aid })
    }

    orderConfirm() {
        let self = this;
        self.setState({ 'isLoading': true })
        if (!self.state.restaurantId) {
            self.setState({ 'isLoading': false })
            toast.error("Restauratant id required!");
            return false;
        }
        axios.post(api.restaurant_user + self.state.restaurantId + "/checkout", {
            payment_method: self.state.payment_method,
            address_id: self.state.address_id,
        }, {
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            self.setState({ 'isLoading': false })
            if (response.data.error === 200) {
                self.setState({ 'isLoading': false, })
                toast.success(response.data.message);
                self.timeoutHandle = setTimeout(() => {
                    self.props.history.replace({ pathname: `/orders-success` })
                }, 1000);
            } else {
                self.setState({ 'isLoading': false })
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            self.setState({ 'isLoading': false })
            toast.error(strings.no_internet);
        });
    }

    updateCart(status, cart_id) {
        let self = this;
        self.setState({ 'isLoading': true })

        if (!self.state.restaurantId) {
            self.setState({ 'isLoading': false })
            toast.error("Restauratant id required!");
            return false;
        }

        axios.post(api.restaurant_user + self.state.restaurantId + "/cart/" + cart_id + "/update-quantity", {
            action: status,
        }, {
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            self.setState({ 'isLoading': false })
            if (response.data.error === 200) {
                self.setState({ 'isLoading': false, })
                toast.success(response.data.message);
                self.getCheckoutData((self.state.address_id) ? api.restaurant_cart + "?address_id=" + self.state.address_id : api.restaurant_cart);
            } else {
                self.setState({ 'isLoading': false })
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            self.setState({ 'isLoading': false })
            toast.error(strings.no_internet);
        });
    }

    removeCart(cart_id) {
        let self = this;
        self.setState({ 'isLoading': true })

        if (!self.state.restaurantId) {
            self.setState({ 'isLoading': false })
            toast.error("Restauratant id required!");
            return false;
        }

        axios.post(api.restaurant_user + self.state.restaurantId + "/cart/" + cart_id + "/delete", {}, {
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            self.setState({ 'isLoading': false })
            if (response.data.error === 200) {
                self.setState({ 'isLoading': false, })
                toast.success(response.data.message);
                // self.getCheckoutData(api.restaurant_cart);
                self.getCheckoutData((self.state.address_id) ? api.restaurant_cart + "?address_id=" + self.state.address_id : api.restaurant_cart);
            } else {
                self.setState({ 'isLoading': false })
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            self.setState({ 'isLoading': false })
            toast.error(strings.no_internet);
        });
    }

    comingSoon() {
        toast.warning(strings.coming_soon);
    }

    deleteAddreessConfitm(AID) {
        this.setState({ removeAddressModal: true, delete_id: AID });
    }
    deleteAddreess(AID) {
        let self = this;
        self.setState({ 'isLoading': true })
        axios({
            method: 'POST', url: api.FoodAddress + AID + '/delete',
            data: { id: AID },
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            self.setState({ 'isLoading': false })
            self.setState({ removeAddressModal: false, delete_id: null })
            if (response.data.error != 200) {
                toast.error(response.data.message);
            } else if (response.data.error === 200) {
                toast.success(response.data.message);
                self.getCheckoutData(api.restaurant_cart);
            }

        }).catch(function (error) {
            self.setState({ 'isLoading': false });
            toast.error(strings.no_internet);
        });
    }

    render() {
        return (
            <>
                <div className="add-cart-body">
                    <section className="topbar-section">
                        <div className="topbar-container-small">
                            <div className="topbar-left-small">
                                {this.state.restaurantId && this.state.itemId ?
                                    <Link to={`/restaurant-detail/${this.state.restaurantId}/${this.state.itemId}`}>
                                        <i className="fa-solid fa-chevron-left"></i>
                                    </Link>
                                    :
                                    <Link to={`/restaurant/${this.state.restaurantId}/${this.state.itemId}`}>
                                        <i className="fa-solid fa-chevron-left"></i>
                                    </Link>
                                }
                            </div>
                            <div className="topbar-center-small">My Cart</div>
                        </div>
                    </section>

                    {this.state.first_load_completed ?
                        <>
                            <section className="profile-section">
                                <div className="burger-img">
                                    {this.state.restaurant && this.state.restaurant.banner ?
                                        <img src={api.uploadPath + this.state.restaurant.banner} alt="" />
                                        :
                                        <img src={image["restaurant-background"]} alt="" />
                                    }
                                </div>
                                <div className="profile-container">
                                    <div className="profile-logo">
                                        {this.state.restaurant && this.state.restaurant.logo ?
                                            <img src={api.uploadPath + this.state.restaurant.logo} alt="" style={{ width: 57, height: 57, borderRadius: 100 }} />
                                            :
                                            <img src={image["burgertimes-logo"]} alt="" />
                                        }
                                    </div>
                                    <div className="profile-detail">
                                        <p className="profile-name">{this.state.restaurant.name}</p>
                                        <p className="profile-distance">{this.state.restaurant.distance ? this.state.restaurant.distance : 0}{"km - "}{"5.0"}</p>
                                    </div>
                                </div>
                            </section>

                            <section className="order-info-section">
                                <div className="order-info-container">
                                    <p className="order-info-title">{strings.order_info}</p>

                                    {/* <img className="burger-info-img" src={images["burger1"]} alt="" /> */}

                                    <div className="date-container">
                                        <div className="date-title">{strings.date}</div>
                                        <div className="date">{moment().format("DD MMM, YYYY")}</div>
                                    </div>

                                    <div className="time-container">
                                        <div className="time-title"> {strings.time}</div>
                                        <div className="time">{moment().format("hh:mm A")}</div>
                                    </div>

                                    <div className="item-container">
                                        <p className="item-title">{strings.items}</p>
                                        {this.state.cartitems.map((item, i) =>
                                            <>
                                                <div key={"1000_" + i}>
                                                    <div className="food-container">
                                                        <p className="food-title">{item.item_name}</p>
                                                        <div className="quantity-container cart-quantity">
                                                            <button onClick={() => this.updateCart("decrement", item.cart_id)} className="quantity-button" type="button"><i className="fa fa-minus"></i></button>
                                                            <div id="quantity">{number_format(item.item_quantity, 0, '.', ',')}</div>
                                                            <button onClick={() => this.updateCart("increment", item.cart_id)} className="quantity-button" type="button"><i className="fa fa-plus"></i></button>
                                                        </div>
                                                        <p className="price">RM{number_format((item.item_price * item.item_quantity), 2, '.', ',')}</p>
                                                        <div className="remove">
                                                            <a onClick={() => this.removeCart(item.cart_id)}>
                                                                <img src={images["bin"]} alt="" />
                                                            </a>
                                                        </div>
                                                    </div>

                                                    {item.addons.map((item, i) =>
                                                        <div key={"2000_" + i} className="food-container">
                                                            <p className="food-title">{item.addon_name}</p>
                                                            <div className="quantity-container cart-quantity"></div>
                                                            <p className="price">{number_format((item.addon_subtotal), 2, '.', ',')}</p>
                                                            <div className="remove"></div>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </section>

                            <hr className="separate-line" />

                            <section className="address-section">
                                <div className="address-container">
                                    <div className="address-title-box">
                                        <p className="address-title">{strings.address}</p>
                                        <Link to={"/delivery-address-add"} className="add-address">{strings.Add_Address}</Link>
                                    </div>

                                    <div className="address-scroll">
                                        {this.state.address_list.map((item, i) =>
                                            <>
                                                <div key={"3000_" + i} className="address-detail-container">
                                                    <div onClick={() => this.changeAddress(item.id)}>
                                                        {this.state.address_id == item.id ?
                                                            <div className="tick-button tick-green"><i className="fa-solid fa-check"></i></div>
                                                            :
                                                            <div className="tick-button"><i className="fa-solid fa-check"></i></div>
                                                        }
                                                    </div>
                                                    <div className="address-detail">
                                                        <p>{strings.receiver_name}: <span>{item.receiver_name}</span></p>
                                                        <p>Work: <span>{" +"}{item.mobile}</span></p>
                                                        <p>{item.full_address}</p>
                                                        <p>{strings.Postcode}: <span>{item.landmark ? item.landmark + ', ' : ''}</span>,  {strings.city}: <span>{item.city}</span></p>
                                                    </div>
                                                    <a onClick={() => this.deleteAddreessConfitm(item.id)}>
                                                        <img src={images["bin-grey"]} alt="" />
                                                    </a>
                                                </div>
                                                <hr className="line-in-container" />
                                            </>
                                        )}
                                    </div>
                                </div>

                                <div className="sub-total-container d-flex justify-content-between align-items-center">
                                    <div className="sub-total-title">{strings.sub_total}</div>
                                    <div className="sub-total">{strings.rm}{number_format(this.state.cart.subtotal ?? 0, 2, '.', ',')}</div>
                                </div>

                                <hr className="line-in-container" />

                                <div className="delivery-fee-container d-flex justify-content-between align-items-center">
                                    <div className="delivery-fee-title">{strings.delivery_fee}</div>
                                    <div className="delivery-fee">{strings.rm}{number_format(this.state.cart.subtotal_fee ?? 0, 2, '.', ',')}</div>
                                </div>

                                <hr className="line-in-container" />

                                <div className="payment-container d-flex justify-content-between align-items-center">

                                    {this.state.pay_methods.map((item, i) =>
                                        <>
                                            {(this.state.can_cod || this.state.can_credit) ?
                                                <div onClick={() => this.setState({ payment_method: item.id })} key={"4000_" + i} className="cod-container d-flex justify-content-between align-items-center">
                                                    <div className="cod-box d-flex justify-content-center align-items-center">
                                                        {item.id === this.state.payment_method ?
                                                            <div className="tick-button tick-green"><i className="fa-solid fa-check"></i></div>
                                                            :
                                                            <div className="tick-button"><i className="fa-solid fa-check"></i></div>
                                                        }
                                                        <div className="cod-title">{item.name}</div>
                                                    </div>

                                                    <div className="cod-fee">{strings.rm}{number_format(item.amount ?? 0, 2, '.', ',')}</div>
                                                </div>
                                                : null}
                                        </>
                                    )}

                                    <div className="discount-container d-flex justify-content-between align-items-center">
                                        <div className="discount-title">{strings.discount}</div>
                                        <div className="discount-fee">RM{number_format(this.state.cart.discount ?? 0, 2, '.', ',')}</div>
                                    </div>

                                </div>

                                <hr className="line-in-container" />

                                <div className="total-container d-flex justify-content-between align-items-center">
                                    <div className="total-title">{strings.total}</div>
                                    <div className="total">{strings.rm}{number_format(this.state.cart.total ?? 0, 2, '.', ',')}</div>
                                </div>

                                <hr className="line-in-container" />

                            </section>

                            <section className="checkout-section">
                                <div className="add-button-container">
                                    <a onClick={() => this.orderConfirm()} className="add-to-cart-button">Checkout</a>
                                </div>
                            </section>
                        </>
                        :
                        <></>
                    }
                    <Modal
                        isOpen={this.state.removeAddressModal}
                        onRequestClose={() => this.setState({ removeAddressModal: false })}
                        ariaHideApp={false}
                        className={"modal_main_container"}
                    >
                        <section key={"modal_1"} className="modal_section">
                            <div className="status-content-container" style={{ height: "90vh" }}>
                                <img src={images["screen-cancel-remove-icon"]} alt="" />
                                <h1>Confirm Remove<br />Address</h1>
                                <a onClick={() => this.deleteAddreess(this.state.delete_id)} className="status-option-1">Confirm</a>
                                <a onClick={() => this.setState({ removeAddressModal: false, delete_id: null })} className="status-option-2">Back</a>
                            </div>
                        </section>
                    </Modal>
                </div>

            </>
        );
    }
}
export default CheckoutScreen;
