import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";

class ChangePasswordScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            current_password: "",
            hide_current_password: true,
            password: "",
            hide_password: true,
            password_confirmation: "",
            hide_password_confirmation: true,
            errorMessage: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            setTimeout(() => {
                this.setState({ USER_DATA: userData.data });
            });
        }
    }

    onPressChangePassword() {
        const self = this;
        if (!this.state.current_password) {
            toast.error(strings.Current_Password_Required);
            return false;
        }
        else if (!this.state.password) {
            toast.error(strings.New_Password_Required);
            return false;
        }
        else if (!this.state.password_confirmation) {
            toast.error(strings.Confirm_Password_Required);
            return false;
        }
        else if (this.state.password != this.state.password_confirmation) {
            toast.error(strings.Password_and_Confirm_Password_not_match);
            return false;
        } else {
            axios({
                method: 'POST', url: api.LoginPasswordUpdate,
                data: {
                    current_password: self.state.current_password,
                    password: self.state.password,
                    password_confirmation: self.state.password_confirmation,
                    lang: self.state.lang
                },
                headers: {
                    'Authorization': 'Bearer ' + self.state.access_token,
                    'Content-Type': 'application/json',
                }
            }).then(function (response) {
                if (response.data.error == 202 || response.data.error == '202') {
                    if (response.data.errors && response.data.errors.length != 0) {
                        self.setState({ errorMessage: response.data.errors });
                    } else {
                        toast.error(response.data.message);
                    }
                } else {
                    toast.success(response.data.message);
                    localStorage.clear();
                    window.location.reload(false);
                }
            }).catch(function (error) {
                toast.error("danger", strings.no_internet);
            });
        }
    }

    render() {
        return (
            <>
                <MenuBar />
                <>
                    <div className="home-content-wrapper">
                        <SubHeader data={{ headerTitle: strings["Change_Password"] }} />

                        <section className="form-section change-password-section">

                            <form action="#" className="form">
                                <h3>Current Password</h3>
                                <div className="input-box otp-box">
                                    <label>Password<span className="compulsory">*</span></label>
                                    <input onChange={(e) => this.setState({ current_password: e.target.value })} type={"text"} placeholder="Enter Current Passowrd" required />
                                    <span onClick={() => this.setState({ hide_current_password: !this.state.hide_current_password })}><i className="fa-solid fa-eye-slash" id="toggler"></i></span>
                                    <div className='row'> {this.state.errorMessage.current_password && <div className='errorMessage'> {this.state.errorMessage.current_password[0]} </div>}</div>
                                </div>

                                <div className="new-password-box">
                                    <h3>New Password</h3>
                                    <div className="input-box password-input">
                                        <label>Password<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ password: e.target.value })} type={this.state.hide_password ? "text" : "password"} placeholder="Enter Password" required />
                                        <span onClick={() => this.setState({ hide_password: !this.state.hide_password })}><i className="fa-solid fa-eye-slash" id="toggler"></i></span>
                                        <div className='row'> {this.state.errorMessage.password && <div className='errorMessage'> {this.state.errorMessage.password[0]} </div>}</div>
                                    </div>

                                    <div className="input-box password-input">
                                        <label>Confirm Password<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ password_confirmation: e.target.value })} type={this.state.hide_password_confirmation ? "text" : "password"} placeholder="Enter New Password Again" required />
                                        <span onClick={() => this.setState({ hide_password_confirmation: !this.state.hide_password_confirmation })}><i className="fa-solid fa-eye-slash" id="toggler"></i></span>
                                        <div className='row'> {this.state.errorMessage.password_confirmation && <div className='errorMessage'> {this.state.errorMessage.password_confirmation[0]} </div>}</div>
                                    </div>
                                </div>

                                <div className="change-pwd-actions-btn-wrapper">
                                    <a onClick={() => this.onPressChangePassword()} className="actions-btn">Confirm</a>
                                </div>
                            </form>

                        </section>
                    </div>
                </>
            </>
        );
    }
}
export default ChangePasswordScreen;
