import React from "react";
import axios from "axios";
import api from "../config/api";
import { toast } from 'react-toastify';
import { Link, NavLink } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import strings from "./Language";
import { Helmet, HelmetProvider } from 'react-helmet-async';

class MenuBar extends React.Component {
    constructor(props) {
        super(props);
        var headerTitle = (this.props && this.props.data && this.props.data.headerTitle) ? this.props.data.headerTitle : "";
        this.state = {
            lang: strings.getLanguage(),
            is_mobile_menu: false,
            is_lang_menu: false,
            headerTitle: headerTitle,
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
                <header className="header" id="header">
                    <nav className="nav-container">
                        <div className="nav-menu" id="nav-menu">
                            <ul className="nav-list">
                                <li key={"li_00_1"} className="nav-item">
                                    <NavLink activeclassname="active" to={"/home"} className="nav-link">
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.8686 7.23097L16.5685 5.35635V2.35829C16.5685 2.02069 16.2938 1.74598 15.9562 1.74598H14.5089C14.1713 1.74598 13.8966 2.02069 13.8966 2.35829V3.17863L10.8231 0.673763C10.6008 0.49263 10.3078 0.392822 9.99808 0.392822C9.68959 0.392822 9.39796 0.492017 9.17686 0.672204L1.13145 7.23092C0.765902 7.52878 0.787834 7.78823 0.833424 7.91637C0.878902 8.04423 1.02536 8.25876 1.49494 8.25876H2.59661V16.8839C2.59661 17.4994 3.09603 18.0001 3.7099 18.0001H7.18896C7.79765 18.0001 8.27442 17.5098 8.27442 16.8839V13.3469C8.27442 13.0437 8.55302 12.7677 8.8589 12.7677H11.1968C11.4884 12.7677 11.7256 13.0275 11.7256 13.3469V16.8839C11.7256 17.4889 12.2482 18.0001 12.8668 18.0001H16.2902C16.904 18.0001 17.4035 17.4994 17.4035 16.8839V8.25876H18.5051C18.9747 8.25876 19.1211 8.04423 19.1666 7.91637C19.2122 7.78823 19.2342 7.52878 18.8686 7.23097Z"
                                                fill="#707070" />
                                        </svg>
                                        <span className="nav-name">Home</span>
                                    </NavLink>
                                </li>
                                <li key={"li_00_2"} className="nav-item">
                                    <a activeclassname="active" to={"/activity"} className="nav-link">
                                        <svg className="nav-icon" width="16" height="18" viewBox="0 0 16 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M15.5639 12.2375C15.4982 12.1584 15.4338 12.0792 15.3705 12.0029C14.5 10.9501 13.9735 10.3147 13.9735 7.33435C13.9735 5.79136 13.6043 4.52531 12.8767 3.57578C12.3403 2.87431 11.615 2.34218 10.6592 1.94892C10.6469 1.94207 10.6359 1.9331 10.6267 1.92241C10.2829 0.771099 9.34211 0 8.28101 0C7.2199 0 6.27947 0.771099 5.93567 1.92122C5.92649 1.93152 5.91565 1.94021 5.90362 1.94694C3.673 2.86522 2.58895 4.62699 2.58895 7.33316C2.58895 10.3147 2.06315 10.9501 1.19195 12.0017C1.12865 12.0781 1.06416 12.1556 0.998487 12.2363C0.652074 12.6541 0.580247 13.2352 0.814512 13.7247C1.05862 14.239 1.57889 14.5583 2.17274 14.5583H14.3936C14.9847 14.5583 15.5014 14.2394 15.7463 13.7275C15.9816 13.2378 15.9104 12.656 15.5639 12.2375Z"
                                                fill="#707070" />
                                            <path
                                                d="M8.28091 17.7246C9.44569 17.7237 10.516 17.0836 11.0678 16.0578C11.0937 16.0087 11.0921 15.9496 11.0634 15.9021C11.0347 15.8546 10.9833 15.8255 10.9277 15.8256H5.63488C5.57929 15.8254 5.52767 15.8543 5.49891 15.9019C5.47015 15.9495 5.46844 16.0087 5.49444 16.0578C6.0462 17.0834 7.1163 17.7235 8.28091 17.7246Z"
                                                fill="#707070" />
                                        </svg>

                                        <span className="nav-name">Activity</span>
                                    </a>
                                </li>
                                <li key={"li_00_3"} className="nav-item">
                                    <NavLink activeclassname="active" to={"/messages"} className="nav-link">
                                        <svg className="nav-icon" width="20" height="18" viewBox="0 0 20 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.507 0H2.43759C1.26867 0 0.321777 0.947447 0.321777 2.11581V11.7046C0.321777 12.8729 1.26867 13.8204 2.43759 13.8204H3.25718L1.58376 17.1771C1.46401 17.3651 1.49253 17.6102 1.65137 17.7647C1.74197 17.854 1.86048 17.8987 1.9796 17.8987C2.06899 17.8987 2.16078 17.8727 2.24084 17.8193L9.21245 13.8204H17.507C18.676 13.8204 19.6228 12.8729 19.6228 11.7046V2.11581C19.6228 0.947447 18.6759 0 17.507 0ZM5.62136 8.29186C4.8444 8.29186 4.21452 7.66198 4.21452 6.88502C4.21452 6.10806 4.8444 5.47817 5.62136 5.47817C6.39837 5.47817 7.02821 6.10806 7.02821 6.88502C7.02821 7.66198 6.39833 8.29186 5.62136 8.29186ZM9.97231 8.29186C9.19531 8.29186 8.56547 7.66198 8.56547 6.88502C8.56547 6.10806 9.19531 5.47817 9.97231 5.47817C10.7493 5.47817 11.3792 6.10806 11.3792 6.88502C11.3792 7.66198 10.7493 8.29186 9.97231 8.29186ZM14.3232 8.29186C13.5463 8.29186 12.9164 7.66198 12.9164 6.88502C12.9164 6.10806 13.5463 5.47817 14.3232 5.47817C15.1002 5.47817 15.7301 6.10806 15.7301 6.88502C15.7301 7.66198 15.1002 8.29186 14.3232 8.29186Z"
                                                fill="#707070" />
                                        </svg>

                                        <span className="nav-name">Messages</span>
                                    </NavLink>
                                </li>
                                <li key={"li_00_4"} className="nav-item">
                                    <NavLink activeclassname="active" to={"/profile"} className="nav-link">
                                        <svg className="nav-icon" width="18" height="20" viewBox="0 0 18 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.66 2.09337C10.8629 1.23273 9.74949 0.758789 8.52059 0.758789C7.28512 0.758789 6.16807 1.22987 5.37463 2.08517C4.57255 2.94991 4.18177 4.12514 4.27352 5.39418C4.45541 7.89785 6.3606 9.93453 8.52059 9.93453C10.6806 9.93453 12.5825 7.89825 12.7672 5.395C12.8602 4.13743 12.467 2.96466 11.66 2.09337Z"
                                                fill="#707070" />
                                            <path
                                                d="M15.7301 19.1103H1.31105C0.928841 19.1153 0.564724 18.9478 0.319748 18.6544C0.053486 18.3361 -0.0538366 17.9014 0.0256311 17.4619C0.371361 15.544 1.45033 13.9329 3.1462 12.8019C4.65283 11.7979 6.5613 11.2454 8.52057 11.2454C10.4798 11.2454 12.3883 11.7984 13.8949 12.8019C15.5908 13.9326 16.6698 15.5436 17.0155 17.4615C17.095 17.901 16.9877 18.3357 16.7214 18.654C16.4765 18.9475 16.1124 19.1152 15.7301 19.1103Z"
                                                fill="#707070" />
                                        </svg>

                                        <span className="nav-name">Profile</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>

            </>
        );
    }
}

export default MenuBar;