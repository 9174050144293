import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import _ from "lodash";
import images from "../../config/image";
import SubHeader from "../../components/SubHeader";
import DeliveryMenuBar from "../../components/DeliveryMenuBar";
const GOOGLE_MAPS_APIKEY = 'AIzaSyA_c6HVjCXCO6fSRNvxmiRxtHg3jAKzjz0';


class DeliveryOrdersScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            deliveryData: [],
            next_page_url: "",
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            this.setState({ USER_DATA: userData.data });
            setTimeout(() => {
                this.getDeliveryList(api.DeliveryHistory)
            });
        }
    }

    getDeliveryList(url) {
        if (url == "" || url == null) {
            return true;
        }

        let self = this;
        self.setState({ 'isLoading': true });
        axios({
            method: 'get', url: url,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false });
            if (ress.data.error == 200) {
                self.setState({
                    deliveryData: [...self.state.deliveryData, ...ress.data.message.data],
                    next_page_url: ress.data.message.next_page_url
                });
            }
        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false });
        });
    }

    render() {
        return (
            <>
                <DeliveryMenuBar />
                <>
                    <SubHeader data={{ headerTitle: strings["orders"], withLink: "delivery" }} />

                    <section className="orders-section" style={{ paddingBottom: 70 }}>
                        <div className="orders-container">
                            {this.state.deliveryData.map((item, i) =>
                                <div key={"1000_" + i}>
                                    <div className="order-row">
                                        <div className="order-col-1">
                                            {item.restaurant && item.restaurant.logo ?
                                                <img src={item.restaurant.logo} alt="" />
                                                :
                                                <img src={images["orders1"]} alt="" />
                                            }
                                        </div>
                                        <div className="order-col-2">
                                            <h2>{item.restaurant && item.restaurant.name ? item.restaurant.name : ""}  - {item.reference}</h2>
                                            <h3>{item.created_at}</h3>
                                        </div>
                                        <div className="order-col-3">
                                            <p>{item.status_word}</p>
                                        </div>
                                    </div>
                                    <hr className="order-row-bottom-line" />
                                </div>
                            )}

                            {this.state.next_page_url ?
                                <a className="view-more" onClick={() => this.getDeliveryList(this.state.next_page_url)}>View More</a>
                                : null}
                        </div>
                    </section>
                </>
            </>
        );
    }
}
export default DeliveryOrdersScreen;
