import React from "react";
import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import image from "../../config/image";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, storeUserData } from "../../components/Util";
import images from "../../config/image";
import SubHeader from "../../components/SubHeader";


class ApplicationDeliveryRiderScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            allow_to_apply: false,
            message: '',
            infodata: {},
            fullname: '',
            ic_number: '',
            ic_file: null,
            dl_file: null,
            errorMessage: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            setTimeout(() => {
                if (this.state.access_token) {
                    this.getData()
                }
            });
        }
    }

    getData() {
        let self = this;
        self.setState({ 'isLoading': true })
        axios({
            method: 'GET', url: api.ApplyforFoodRiderCheck + "?lang=" + self.state.lang,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })

            self.setState({ message: ress.data.message });
            self.setState({ allow_to_apply: (ress.data && ress.data.allow_to_apply) ? ress.data.allow_to_apply : false })
            self.setState({ infodata: ress.data.data });
        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false })
        });
    }

    changeUploadIC = (event) => {
        if (event && event.target && event.target.files) {
            this.setState({ ic_file: event.target.files[0] });
        }
    };

    changeUploadDL = (event) => {
        if (event && event.target && event.target.files) {
            this.setState({ dl_file: event.target.files[0] });
        }
    };

    handleBind() {
        let self = this;

        let formData = new FormData();
        formData.append("fullname", self.state.fullname);
        formData.append("ic_number", self.state.ic_number);
        formData.append("ic_file", self.state.ic_file);
        formData.append("dl_file", self.state.dl_file);

        axios({
            method: 'POST',
            url: api.ApplyforFoodRider,
            data: formData,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'multipart/form-data',
            }
        }).then(function (response) {
            self.setState({ 'isLoading': false })
            if (response.data.error != 200) {
                toast.error(response.data.message);
            } else if (response.data.error === 200) {
                toast.success(response.data.message);
                self.timeoutHandle = setTimeout(() => {
                    self.props.history.go('/application');
                }, 1000);
            }
        }).catch(function (error) {
            self.setState({ 'isLoading': false });
        })
    }

    render() {
        return (
            <>
                <>
                    <SubHeader data={{ headerTitle: strings["Apply_as_Delivery_Rider"] }} />

                    <section className="form-section delivery-rider-section">
                        <h2>{this.state.message}</h2>
                        {!this.state.allow_to_apply ?
                            <>
                                <form action="javascript:void(0)" className="form">
                                    <div className="input-box">
                                        <label>{strings["full_name_ic"]}</label>
                                        <div>{(this.state.infodata && this.state.infodata.fullname) ? this.state.infodata.fullname : ''}</div>
                                    </div>
                                    <div className="input-box">
                                        <label>{strings.ic_number}</label>
                                        <div>{(this.state.infodata && this.state.infodata.ic_number) ? this.state.infodata.ic_number : ''}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>Upload IC (front and back)</label>
                                        <div>
                                            {(this.state.infodata && this.state.infodata.ic_file) ?
                                                <img src={this.state.infodata.ic_file_} style={{ padding: 5, width: 75, height: 100, }} alt="" />
                                                : null}
                                        </div>
                                    </div>

                                    <div className="input-box">
                                        <label>Upload Driving License (front and back)</label>
                                        <div>
                                            {(this.state.infodata && this.state.infodata.dl_file) ?
                                                <img src={this.state.infodata.dl_file_} style={{ padding: 5, width: 75, height: 100, }} alt="" />
                                                : null}
                                        </div>
                                    </div>
                                </form>
                            </>
                            : null}
                        {this.state.allow_to_apply ?
                            <>
                                <form action="javascript:void(0)" className="form">
                                    <div className="input-box">
                                        <label>{strings["full_name_ic"]}<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ fullname: e.target.value })} defaultValue={this.state.fullname} type="text" placeholder={strings.please_fullname_as_ic} required />
                                        <div className='row'> {this.state.errorMessage.fullname && <div className='errorMessage'> {this.state.errorMessage.fullname[0]} </div>}</div>
                                    </div>
                                    <div className="input-box">
                                        <label>{strings.ic_number}<span className="compulsory">*</span></label>
                                        <input onChange={(e) => this.setState({ ic_number: e.target.value })} defaultValue={this.state.ic_number} type="text" placeholder={strings.please_ic_number} required />
                                        <div className='row'> {this.state.errorMessage.ic_number && <div className='errorMessage'> {this.state.errorMessage.ic_number[0]} </div>}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>Upload IC (front and back)<span className="compulsory">*</span></label>
                                        <input onChange={this.changeUploadIC} defaultValue={this.state.ic_file} type="file" name="ic" />
                                        <div className='row'> {this.state.errorMessage.ic_file && <div className='errorMessage'> {this.state.errorMessage.ic_file[0]} </div>}</div>
                                    </div>

                                    <div className="input-box">
                                        <label>Upload Driving License (front and back)<span className="compulsory">*</span></label>
                                        <input onChange={this.changeUploadDL} defaultValue={this.state.dl_file} type="file" name="drivinglicense" />
                                        <div className='row'> {this.state.errorMessage.dl_file && <div className='errorMessage'> {this.state.errorMessage.dl_file[0]} </div>}</div>
                                    </div>

                                    <button onClick={() => this.handleBind()} className="send-application-button">Send Application</button>

                                </form>
                            </>
                            : null}
                    </section>
                </>
            </>
        );
    }
}
export default ApplicationDeliveryRiderScreen;
