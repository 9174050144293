import React from "react";
import axios from "axios";
import api from "../config/api";
import { toast } from 'react-toastify';
import { Link, NavLink } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import strings from "./Language";
import { Helmet, HelmetProvider } from 'react-helmet-async';

class PublicMenuBar extends React.Component {
    constructor(props) {
        super(props);
        var headerTitle = (this.props && this.props.data && this.props.data.headerTitle) ? this.props.data.headerTitle : "";
        this.state = {
            lang: strings.getLanguage(),
            is_mobile_menu: false,
            is_lang_menu: false,
            headerTitle: headerTitle,
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
                <header className="header" id="header">
                    <nav className="nav-container">
                        <div className="nav-menu" id="nav-menu">
                            <ul className="nav-list">
                                <li key={"li_00_1"} className="nav-item">
                                    <NavLink to={"/"} className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')}>
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18.8686 7.23097L16.5685 5.35635V2.35829C16.5685 2.02069 16.2938 1.74598 15.9562 1.74598H14.5089C14.1713 1.74598 13.8966 2.02069 13.8966 2.35829V3.17863L10.8231 0.673763C10.6008 0.49263 10.3078 0.392822 9.99808 0.392822C9.68959 0.392822 9.39796 0.492017 9.17686 0.672204L1.13145 7.23092C0.765902 7.52878 0.787834 7.78823 0.833424 7.91637C0.878902 8.04423 1.02536 8.25876 1.49494 8.25876H2.59661V16.8839C2.59661 17.4994 3.09603 18.0001 3.7099 18.0001H7.18896C7.79765 18.0001 8.27442 17.5098 8.27442 16.8839V13.3469C8.27442 13.0437 8.55302 12.7677 8.8589 12.7677H11.1968C11.4884 12.7677 11.7256 13.0275 11.7256 13.3469V16.8839C11.7256 17.4889 12.2482 18.0001 12.8668 18.0001H16.2902C16.904 18.0001 17.4035 17.4994 17.4035 16.8839V8.25876H18.5051C18.9747 8.25876 19.1211 8.04423 19.1666 7.91637C19.2122 7.78823 19.2342 7.52878 18.8686 7.23097Z"
                                                fill="#707070" />
                                        </svg>
                                        <span className="nav-name">Home</span>
                                    </NavLink>
                                </li>
                                <li key={"li_00_4"} className="nav-item">
                                    <NavLink to={"/login"} className={({ isActive }) => (isActive ? "nav-link active" : 'nav-link')}>
                                        <svg className="nav-icon" width="18" height="20" viewBox="0 0 18 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M11.66 2.09337C10.8629 1.23273 9.74949 0.758789 8.52059 0.758789C7.28512 0.758789 6.16807 1.22987 5.37463 2.08517C4.57255 2.94991 4.18177 4.12514 4.27352 5.39418C4.45541 7.89785 6.3606 9.93453 8.52059 9.93453C10.6806 9.93453 12.5825 7.89825 12.7672 5.395C12.8602 4.13743 12.467 2.96466 11.66 2.09337Z"
                                                fill="#707070" />
                                            <path
                                                d="M15.7301 19.1103H1.31105C0.928841 19.1153 0.564724 18.9478 0.319748 18.6544C0.053486 18.3361 -0.0538366 17.9014 0.0256311 17.4619C0.371361 15.544 1.45033 13.9329 3.1462 12.8019C4.65283 11.7979 6.5613 11.2454 8.52057 11.2454C10.4798 11.2454 12.3883 11.7984 13.8949 12.8019C15.5908 13.9326 16.6698 15.5436 17.0155 17.4615C17.095 17.901 16.9877 18.3357 16.7214 18.654C16.4765 18.9475 16.1124 19.1152 15.7301 19.1103Z"
                                                fill="#707070" />
                                        </svg>

                                        <span className="nav-name">Login</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>

            </>
        );
    }
}

export default PublicMenuBar;