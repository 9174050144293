

import React, { useState } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { isAuthenticatedData } from './components/Util';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

import NotFound from './pages/NotFound'
import LoginScreen from "./pages/Auth/LoginScreen";
import RegisterScreen from "./pages/Auth/RegisterScreen";
import ForgotPasswordScreen from "./pages/Auth/ForgotPasswordScreen";
import HomeScreen from "./pages/Home";
import DeliveryScreen from './pages/Delivery';
import RestaurantScreen from './pages/Delivery/Restaurant';
import RestaurantDetailsScreen from './pages/Delivery/RestaurantDetails';
import CheckoutScreen from './pages/Delivery/Checkout';
import OrdersSuccess from './pages/Delivery/OrdersSuccess';
import CartEmptySuccess from './pages/Delivery/CartEmpty';
import RestaurantAllScreen from './pages/Delivery/RestaurantAll';
import DeliveryOrdersScreen from './pages/Delivery/DeliveryOrders';
import DeliveryAddressSuccess from './pages/Delivery/DeliveryAddress';
import DeliveryAddressAddSuccess from './pages/Delivery/DeliveryAddressAdd';
import ProfileScreen from './pages/Profile';
import MessagesScreen from './pages/Messages';
import PublicScreen from './pages/Public';
import PublicDeliveryScreen from './pages/Public/PublicDeliveryScreen';
import PublicRestaurantAllScreen from './pages/Public/Delivery/PublicRestaurantAll';
import PublicRestaurantScreen from './pages/Public/Delivery/PublicRestaurant';
import PublicRestaurantDetailsScreen from './pages/Public/Delivery/PublicRestaurantDetails';
import SettingsScreen from './pages/Profile/Settings';
import ProfileEditScreen from './pages/Profile/ProfileEdit';
import ChangePasswordScreen from './pages/Profile/ChangePassword';
import CreditScreen from './pages/Profile/Credit';
import CreditTransactionScreen from './pages/Profile/CreditTransaction';
import WithdrawalTransactionScreen from './pages/Profile/WithdrawalTransaction';
import ReferralCodeScreen from './pages/Profile/ReferralCode';
import AccountSettingsScreen from './pages/Profile/AccountSettings';
import PointsScreen from './pages/Profile/Points';
import AddressScreen from './pages/Address';
import AddressAddSuccess from './pages/Address/AddressAdd';
import AddressEditSuccess from './pages/Address/AddressEdit';
import ApplicationScreen from './pages/Application';
import ApplicationDeliveryRiderScreen from './pages/Application/ApplicationDeliveryRider.';
import ApplicationEhailingDriverScreen from './pages/Application/ApplicationEhailingDriver';
import ApplicationFoodMerchantScreen from './pages/Application/ApplicationFoodMerchant';
import ApplicationServiceProviderScreen from './pages/Application/ApplicationServiceProvider';
import ApplicationMarketplaceMerchantScreen from './pages/Application/ApplicationMarketplaceMerchant';
import TopupScreen from './pages/Credit/Topup';
import WithdrawScreen from './pages/Credit/Withdraw';
import TopupSuccessScreen from './pages/Credit/TopupSuccess';
import WithdrawSuccessScreen from './pages/Credit/WithdrawSuccess';
import CreditReportsScreen from './pages/Profile/CreditReports';

export const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          // <Component {...props} />
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  )
}

export const AuthRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to={{ pathname: '/home' }} />
          // <Component {...props} />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

const App = (props) => {

  const [forceRefresh] = useState(false)
  var userData = isAuthenticatedData();
  // const isAuthenticated = false;
  // const isAuthenticated = true;
  const isAuthenticated = userData.status ?? false;
  return (
    <>
      <BrowserRouter forceRefresh={forceRefresh} >
        <Switch>
          <AuthRoute exact path="/login" component={LoginScreen} isAuthenticated={isAuthenticated} />
          <AuthRoute exact path="/register" component={RegisterScreen} isAuthenticated={isAuthenticated} />
          <AuthRoute exact path="/forgot-password" component={ForgotPasswordScreen} isAuthenticated={isAuthenticated} />
          <AuthRoute exact path="/public/delivery" component={PublicDeliveryScreen} isAuthenticated={isAuthenticated} />
          <AuthRoute exact path="/public/delivery/restaurant-all" component={PublicRestaurantAllScreen} isAuthenticated={isAuthenticated} />
          <AuthRoute exact path="/public/delivery/restaurant/:restaurantid" component={PublicRestaurantScreen} isAuthenticated={isAuthenticated} />
          <AuthRoute exact path="/public/delivery/restaurant-detail/:restaurantid/:itemid" component={PublicRestaurantDetailsScreen} isAuthenticated={isAuthenticated} />

          <Route exact path="/" component={isAuthenticated ? HomeScreen : PublicScreen} />

          <PrivateRoute exact path="/home" component={HomeScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/delivery" component={DeliveryScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/delivery-orders" component={DeliveryOrdersScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/delivery-address" component={DeliveryAddressSuccess} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/delivery-address-add" component={DeliveryAddressAddSuccess} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/restaurant-all" component={RestaurantAllScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/restaurant/:restaurantid" component={RestaurantScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/restaurant-detail/:restaurantid/:itemid" component={RestaurantDetailsScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/checkout" component={CheckoutScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/checkout/:restaurantid/:itemid" component={CheckoutScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/cart-empty" component={CartEmptySuccess} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/orders-success" component={OrdersSuccess} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/messages" component={MessagesScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/profile" component={ProfileScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/profile/settings" component={SettingsScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/profile/edit" component={ProfileEditScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/profile/change-password" component={ChangePasswordScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/profile/credit" component={CreditScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/profile/credit/reports" component={CreditReportsScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/profile/credit/transaction" component={CreditTransactionScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/profile/withdrawal/transaction" component={WithdrawalTransactionScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/profile/referral-code" component={ReferralCodeScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/profile/settings/account" component={AccountSettingsScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/profile/points" component={PointsScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/address" component={AddressScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/address/add" component={AddressAddSuccess} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/address/edit" component={AddressEditSuccess} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/application" component={ApplicationScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/application/delivery-rider" component={ApplicationDeliveryRiderScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/application/ehailing-driver" component={ApplicationEhailingDriverScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/application/food-merchant" component={ApplicationFoodMerchantScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/application/service-provider" component={ApplicationServiceProviderScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/application/marketplace-merchant" component={ApplicationMarketplaceMerchantScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/topup-credit" component={TopupScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/topup-credit-success" component={TopupSuccessScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/withdraw-credit" component={WithdrawScreen} isAuthenticated={isAuthenticated} />
          <PrivateRoute exact path="/withdraw-credit-success" component={WithdrawSuccessScreen} isAuthenticated={isAuthenticated} />
          
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
      />

    </>
  );
}
export default App;