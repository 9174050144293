const en = {
    "BeliBeli": "BeliBeli",
    "en": "English",
    "my": "Malaysia",

    Belibeli: "Belibeli",

    //Common Word
    confirm: "Confirm",
    coming_soon: "Coming Soon",
    processing: "Processing...",
    error: "Error",
    success: "Success",
    no_internet: "Something went wrong please try again",
    unable_to_connect: "Unable to connect server please try again later",
    server_error: "Unable to connect server please try again later",
    serverMaintenance: "Application is under maintenance",
    netInfo_msg: 'No Internet connection.',
    record_empty: 'Data not found!',
    connected: "Connected",
    cancel: "Cancel",
    close: "Close",
    no: "No",
    yes: "Yes",
    verify: "Verify",
    save: "Save",
    Log_In: "Log In",
    no_account_yet_sign_up: 'No account yet? Sign up!',
    get_an_account: 'Get an account',
    Sign_up_on_Belibeli: "Sign up on Belibeli.",
    already_a_member: "Already a member? ",
    lang: "简体中文",
    lang_id: "zh",
    search: "Search",
    copied: "Copied",
    logout: "Logout",
    confirm_logout: "Confirm logout?",

    //Version Update
    version_msg: "New APP Version Available\n Current Version is {0} \nNew Version is {1}",
    download: "Download",
    version_update: "Version Update",
    version: "Version",

    //Auth
    name: "Name",
    enter_name: "Enter name",
    mobile_number: "Mobile Number",
    Send_OTP: "Send OTP",
    enter_mobile_number: "Enter Mobile Number",
    email: "Email",
    enter_email: "Enter Email",
    Email_Address: "Email Address",
    enter_email_address: "Please Enter Email Address",
    New_Password: "New Password",
    Confirm_Password: "Confirm Password",
    enter_new_Password: "Enter New Password",
    enter_Confirm_Password: "Enter New Password Again",
    otp: "OTP",
    enter_otp: "Enter OTP",
    Get_Code: "Get Code",
    password: "Password",
    enter_password: "Enter Password",
    Referral_Code: "Referral Code",
    Share_or_copy_your_Referral_QR_code: "Share or copy your Referral QR code",
    enter_Referral_Code: "Enter referral code (If any)",
    optional: "Optional",
    SignUp: "Sign Up",
    SignUpFb: "Sign Up with Facebook",
    SignUpGmail: "Sign Up with Google",
    Privacy_Policy: "Privacy Policy ",
    Terms_of_Service: "Terms of Service ",
    and: " and ",
    apply: " apply ",
    invalid_login_details: "Invalid Login Details",

    home: "Home",
    app: "Home",
    activity: "Activity",
    messages: "Messages",
    profile: "Profile",
    orders: "Orders",

    bego_point: 'Belibeli Point',
    bego_points: 'Belibeli Points',
    bego_credit: 'Belibeli Credit',
    bego_coupon: 'Belibeli Coupon',

    mall: "Mall",
    e_hailing: "E-Hailing",
    delivery: "Delivery",
    handyman: "Handyman",
    services: "Services",
    feed: "Feed",
    notification: "Notification",

    change_language: "Change Language",
    fpx: 'FPX',
    ewallet: 'Ewallet',

    choose_image: "Please upload image",
    please_id_card_name: 'Please enter ID Card Name',
    please_fullname_as_ic: 'Please enter full name as per IC',
    please_ic_number: 'Please enter IC number',
    please_upload_id: 'Please upload IC',
    please_upload_license: 'Please upload Driving License',

    annotation: 'Annotation',
    flat_no: 'House / Flat / Block NO.',
    enter_flat_no: 'Please enter House / Flat / Block NO.',
    landmark: 'landmark',
    landmark_placeholder: 'Example: red house, lobby, yellow shop blue roof',
    enter_landmark: 'Please enter landmark',
    receiver_name: 'Receiver Name',
    enter_receiver_name: 'Please enter receiver name',
    food_address: 'Food Address',
    set_default: 'Set Default',
    change: 'Change',
    enter_address: 'Enter Address',
    phone: 'Phone Number',
    address: 'Address',
    city: 'City',

    //Merchant
    merchant_name: 'Name',
    registration_file: 'Registration file',
    trade_license_file: 'Trade license file.',
    apply_for_merchant: 'Apply for Merchant',
    please_merchant_name: 'Please enter merchant name.',
    please_upload_registration_file: 'Please upload registration file.',
    please_upload_trade_license_file: 'Please upload trade license file.',
    enter_select_address: 'Please Select Address',

    //Apply AS
    Apply_as_Food_Merchant: 'Apply as Food Merchant',
    Apply_as_Service_Provider: 'Apply as Service Provider',
    Apply_as_E_Hailing_Driver: 'Apply as E-Hailing Driver',
    Apply_as_Marketplace_Merchant: "Apply as Marketplace Merchant",
    apply_for_rider: 'Apply for E-Hailing Driver',
    apply_for_food_rider: 'Apply for Delivery Rider',
    browse: 'Browse',
    no_file: 'No file selected.',
    please_vehicle_id: 'Please enter vehicle id',
    please_fullname_as_ic: 'Please enter full name as per IC',
    please_ic_number: 'Please enter IC number',
    please_upload_id: 'Please upload IC',
    please_upload_license: 'Please upload Driving License',
    vehicle: 'Vehicle',
    full_name_ic: 'Full Name As Per IC',
    ic_number: 'IC Number',
    ic: 'IC',
    upload_ic: 'Upload IC',
    upload_ic_from_and_back: 'Upload IC (front and back)',
    dl: 'Driving License',
    upload_dl: 'Upload Driving License',
    select: 'Select',
    Send_Application: 'Send Application',
    Your_Vehicle_Type: 'Your Vehicle Type',
    Name_of_Business_Shop: 'Name of Business/Shop',
    enter_Name_of_Business_Shop: "Enter Business/Shop Name",
    Business_Registration_File: "Business Registration File",
    Shop_Service_Description: "Shop Service Description",
    enter_Shop_Service_Description: "Enter about your shop",
    Service_Delivery_Type: "Service Delivery Type",
    Door_to_Door: "Door to Door",
    Walk_In: "Walk In",
    Service_Category: "Service Category",
    Trade_License_File: "Trade License File",
    Business_Address: "Business Address",
    enter_Business_Address: "Enter Business Address here",
    Locality: "Locality",
    Enter_Locality: "Enter Locality",
    state: "State",
    enter_state: "Enter State",
    country: "Country",
    enter_country: "Enter Country",
    Name_of_Business_Shop: "Name of Business Shop",
    enter_Name_of_Business_Shop: "Enter Name of Business Shop",
    upload_ic_front: "Upload IC (front)",
    upload_ic_back: "Upload IC (back)",

    //Vendor
    apply_for_vendor: 'Apply for Vendor',
    choose_image: "Please upload image",
    please_id_card_name: 'Please enter ID Card Name',

    //Services
    services: 'Services',
    booking: 'Booking',

    invoice: "Invoice",
    order_info: "Order Info",
    order_no: "Order No.",
    date: "Date",
    items: "Items",
    qty: "Qty",
    price: "Price",
    total: "Total",
    sub_total: "Sub Total",
    delivery_fee: "Delivery Fee",
    discount: "Discount",
    checkout: "Checkout",
    checkout_rm: "Checkout - RM ",
    rm: "RM",
    ride_summary: "Ride Summary",
    ride_ID: "Ride ID",
    Ride_Date: "Ride Date",
    Distance: "Distance",
    fare: "Fare",
    gst: "GST",
    Payable_Total: "Payable Total",
    from: "From",
    to: "To",
    Activity_History: "Activity History",
    Wallet_Updates: "Wallet Updates",
    Point_Updates: "Point Updates",
    Delivery: "Delivery",
    Mall: "Mall",
    Services: "Services",
    Recent_History: "Recent History",
    You_have_no_Mall_History: "You have no Mall History.",
    Fill_it_up_with_your_first_Mall_purchase: "Fill it up with your first Mall purchase.",
    Enter_Mall: "Enter Mall",
    You_have_no_E_Hailing_History: "You have no E-Hailing History.",
    Fill_it_up_with_your_first_ride: "Fill it up with your first ride.",
    Enter_E_Hailing: "Enter E-Hailing",
    You_have_no_Delivery_History: "You have no Delivery History.",
    Fill_it_up_with_your_first_order: "Fill it up with your first order.",
    Enter_Delivery: "Enter Delivery",
    You_have_no_Services_History: "You have no Services History.",
    Fill_it_up_with_your_first_booking: "Fill it up with your first booking.",
    Enter_Services: "Enter Services",
    Order_Details: "Order Details",
    Delivery_Address: "Delivery Address",
    Discount_Point: "Discount Point",
    item_sub_total: "Item(s) Subtotal ",
    Shipping_Fee_Subtotal: "Shipping Fee Subtotal",
    item: "item",
    Total_Order_Amount: "Total Order Amount",
    Order_ID: "Order_ID",
    Order_Date: "Order Date",
    Order_Time: "Order Time",
    Tracking_info: "Tracking info",
    Receive: "Receive",
    Cancel: "Cancel",
    no_record: "You have no record found!",
    Wallet_Updates: "Wallet Updates",
    Top_Up_Transaction_History: "Top Up Transaction History",
    View_more: "View More",
    Add_Address: "Add Address",
    Address: "Address",
    Enter_City: "Enter City",
    Enter_Postcode: "Enter Postcode",
    Postcode: "Postcode",
    work: "Work",
    other: "Other",
    back: "Back",
    edit: "Edit",
    login: "Login",
    Forgot_Password: "Forgot Password?",
    Privacy_Policy: "Privacy Policy",
    Terms_of_Service: "Terms of Service",
    No_account_yet: "No account yet?",
    Login_to_Belibeli: "Login to Belibeli",
    Access_your_account: "Access your account",
    Apply_as_Delivery_Rider: "Apply as Delivery Rider",

    My_Cart: "My Cart",
    time: "Time",
    address_not_found_please_add_first: "Address not found! Please add the address first.",
    Address_Name: "Address Name",
    name_of_address_optional: "Name of this address (optional)",
    address_line_1: "Address Line 1",
    you_have_no_data_found: "You have no data found!",
    you_have: "You have ",
    Restaurant_Profile: "Restaurant Profile",
    km: "KM",
    Add_on: "Add-on",
    Add_to_cart: "Add to Cart",
    search_for_restaurant: "Search for restaurant",
    location: "Location",
    select_address: "Select Address",
    Search_Restaurant: "Search Restaurant",
    Belibelis_Restaurant_picks: "Belibeli's Restaurant picks",
    View_all: "View all",
    Keep_discovering_more_shop: "Keep discovering more shop",
    Good_Morning_: "Good Morning, ",
    Good_Evening_: "Good Evening, ",
    Where_are_you_going: "Where are you going?",
    Current_Location: "Current Location",
    Where_to: "Where To?",
    Select_an_Option: "Select an Option:",
    Request_Ride: "Request Ride",
    Confirm_Booking: "Confirm Booking?",
    News_discovery: "News discovery",
    Feeds: "Feeds",
    Feeds_not_found: "Feed not found!",
    Message: "Message",
    Message_not_found: "Message not found!",
    Activity_History: "Activity History",
    Notifications: "Notifications",
    Order: "Order",
    Search_for_something: "Search for something...",
    Popular_Items: "Popular Items",
    Cart: "Cart",
    Cart_is_Empty: "Cart is Empty",
    Check_out: "Check out",
    Seller: "Seller",
    Top_Hits: "Top Hits",
    Shipping_Address: "Shipping Address",
    Manage_Address: "Manage Address",
    Points: "Points",
    You_Pay: "You Pay",
    Payment_Method: "Payment Method",
    Online: "Online",
    Place_Order: "Place Order",
    Available_on_Stock: "Available on Stock",
    Out_on_Stock: "Out on Stock",
    Info: "Info",
    Specification: "Specification",
    Quantity: "Quantity",

    Change_Password: "Change Password",
    Current_Password: "Current Password",
    Enter_Current_Password: "Enter Current Password",
    Current_Password_Required: "Current Password Required",
    New_Password_Required: "New Password Required",
    Confirm_Password: "Confirm Password",
    Confirm_Password_Required: "Confirm Password Required",
    Password_and_Confirm_Password_not_match: "Password and Confirm Password not match",

    Please_enter_full_name: "Please enter full name.",
    Please_enter_mobile_number: "Please enter mobile number.",
    Edit_Profile: "Edit Profile",
    Full_Name: "Full Name",
    Enter_full_name_here: "Enter full name here",
    Add_or_edit_address: "Add or edit address",
    Share_or_scan_Referral_QR_code: "Share or scan Referral QR code",
    Application: "Application",
    Application_apply_for: "Apply for Marketplace Merchant, E-Hailing Driver, Delivery Rider, Food Merchant",
    Policies: "Policies",
    Policies_info: "Privacy Policy & Shipping/Fulfillment Policy",
    Setting: "Setting",
    Account_Settings: "Account Settings",
    Contact_Us: "Contact Us",
    Contact_Us_info: "Contact us about your inquiries",
    Referral: "Referral",
    get: "Get",
    For_every_new_user_you_refer: "For every new user you refer",
    Share: "Share",
    Referral_History: "Referral History",
    Claim_Points: "Claim Points",
    Settings: "Settings",
    Current_language: "Current language",
    English: "English",
    Services_Categoty: "Services Categoty",
    Top_Service_Providers: "Top Service Providers",
    Search_shop: "Search shop",
    Plumbing: "Plumbing",
    Popular_Services: "Popular Services",
    Vendor_Profile: "Vendor Profile",
    Shop: "Shop",
    Gallery: "Gallery",
    Reviews: "Reviews",
    About: "About",
    contact: "Contact",
    Business_hours: "Business hours",
    Open_hours: "Open hours",
    Available_For_Booking: "Available For Booking",
    Service_Type: "Service Type",
    Base_price: "Base price",
    Customer_Reviews: "Customer Reviews",
    Request_Services: "Request Services",
    What_Services_Are_You_Looking_for: "What Services Are You Looking for?",
    Nearby_Service_Provider: "Nearby Service Provider",
    News_and_Promotions: "News and Promotions",
    copy: "Copy",
    Point_camera_at_OR_code: "Point camera at OR code",
    Change_Camera: "Change Camera",
    View_History: "View History",

    Bookings: "Bookings",
    Book: "Book",
    History: "History",
    Booking_History: "Booking History",
    id: "ID",
    Extra_Fee: "Extra Fee",
    Payment_Type: "Payment Type",
    Coupon_Applied: "Coupon Applied",
    None: "None",
    Pick_Date_Time: "Pick a Date & Time",
    Note: "Note",
    Write_Note: "Write your note here...",
    Payment: "Payment",
    Book_a_Service: "Book a Service",
    Next: "Next",
    Add_Service_Address: "Add Service Address",
    Saved_Address: "Saved Address",

    Hi: "Hi",
    Top_Up: "Top Up",
    Top_Up_Amount: "Top Up Amount",
    Top_Up_Belibeli_Credit: "Top Up Belibeli Credit",
    S_PAY_Global: "S PAY Global",

    Withdraw: "Withdraw",
    Withdrawal_Belibeli_Credit: "Withdrawal Belibeli Credit",
    Withdrawal_Amount: "Withdrawal Amount",
    Belibeli_Credit_Transaction_History: "Belibeli Credit Transaction History",
    Completed_Transaction_History: "Completed Transaction History",

    Top_Up_History: "Top Up History",
    Withdrawal_History: "Withdrawal History",
    Completed_Withdrawal_History: "Completed Withdrawal History",
    No_Referral: "No Referral",
    With_Referral: "With Referral",
    bank_account: "Bank Account",
    bank_name: "Bank name",
    enter_bank_name: "Enter bank name",
    account_name: "Account name",
    enter_account_name: "Enter account name",
    account_number: "Account number",
    enter_account_number: "Enter account number",

    id_not_found: "ID not found!",
    edit_Address: "Edit Address",
    active: "Active",
    Use_Address: "Use Address",
    Set_As_Default_Address: "Set As Default Address",

    close_account: "Close Account",
    confirm_close_account: "Confirm close account?",
    permanent_delete_your_account: "Permanent delete your account ",
    permanent_delete_account_msg: "We received request of your account close, will proceed with in 15 days.",

    invalid_otp: "Invalid OTP please enter valid OTP.",
    otp_Required: "OTP Required",
    searching_for_nearby_restaurant: "Searching For Nearby Restaurant",
    enter_amount_min_1: "Please enter the minimum amount of 1.",
};
export default en;