import React from "react";
import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import image from "../../config/image";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, storeUserData } from "../../components/Util";
import images from "../../config/image";
import SubHeader from "../../components/SubHeader";


class ApplicationScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
        }
    }
    render() {
        return (
            <>
                <>
                    <SubHeader data={{ headerTitle: strings["Application"] }} />

                    <section className="application-section">
                        <div className="application-container">
                            <div className="application-row">
                                <div className="application-col-1">
                                    <div className="application-icon">
                                        <img src={images["rider-icon"]} alt="" />
                                    </div>
                                    <div className="application-detail">
                                        <h2 className="application-h2">Delivery Rider</h2>
                                    </div>
                                </div>
                                <div className="application-col-2">
                                    <Link to={"/application/delivery-rider"}><img src={images["arrow-right-icon"]} alt="" /></Link>
                                </div>
                            </div>

                            <hr className="credit-row-bottom-line" />

                            <div className="application-row">
                                <div className="application-col-1">
                                    <div className="application-icon">
                                        <img src={images["ehailing-icon"]} alt="" />
                                    </div>
                                    <div className="application-detail">
                                        <h2 className="application-h2">E-Hailing Driver</h2>
                                    </div>
                                </div>
                                <div className="application-col-2">
                                    <Link to={"/application/ehailing-driver"}><img src={images["arrow-right-icon"]} alt="" /></Link>
                                </div>
                            </div>

                            <hr className="credit-row-bottom-line" />

                            <div className="application-row">
                                <div className="application-col-1">
                                    <div className="application-icon">
                                        <img src={images["merchant-icon"]} alt="" />
                                    </div>
                                    <div className="application-detail">
                                        <h2 className="application-h2">Food Merchant</h2>
                                    </div>
                                </div>
                                <div className="application-col-2">
                                    <Link to={"/application/food-merchant"}><img src={images["arrow-right-icon"]} alt="" /></Link>
                                </div>
                            </div>

                            <hr className="credit-row-bottom-line" />

                            <div className="application-row">
                                <div className="application-col-1">
                                    <div className="application-icon">
                                        <img src={images["service-icon"]} alt="" />
                                    </div>
                                    <div className="application-detail">
                                        <h2 className="application-h2">Service Provider</h2>
                                    </div>
                                </div>
                                <div className="application-col-2">
                                    <Link to={"/application/service-provider"}><img src={images["arrow-right-icon"]} alt="" /></Link>
                                </div>
                            </div>

                            <hr className="credit-row-bottom-line" />

                            <div className="application-row">
                                <div className="application-col-1">
                                    <div className="application-icon">
                                        <img src={images["marketplace-icon"]} alt="" />
                                    </div>
                                    <div className="application-detail">
                                        <h2 className="application-h2">Marketplace Merchant</h2>
                                    </div>
                                </div>
                                <div className="application-col-2">
                                    <Link to={"/application/marketplace-merchant"}><img src={images["arrow-right-icon"]} alt="" /></Link>
                                </div>
                            </div>

                            <hr className="credit-row-bottom-line" />
                        </div>
                    </section>
                </>
            </>
        );
    }
}
export default ApplicationScreen;
