import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";
import moment from "moment";
import QRCode from "react-qr-code";

class ReferralCodeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            shareModal: false,
            copyModal: false,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            setTimeout(() => {
                this.setState({ USER_DATA: userData.data });
            });
        }
    }

    copyToClipboard(string) {
        navigator.clipboard.writeText(string)
        this.setState({ copyModal: true });
    }

    onShare() {
        this.setState({ shareModal: true });
    }

    render() {
        return (
            <>
                <div className="credit-body" style={{ height: window.innerHeight }}>

                    <SubHeader data={{ headerTitle: strings["Referral"], not_display_help: true }} />

                    <section className="referral-code-section">
                        <div className="referral-code-container">
                            <h1>Share or copy your Referral QR Code</h1>
                            <div className="qrcode-wrapper">
                                <QRCode
                                    size={200}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%", padding: 20 }}
                                    value={this.state.USER_DATA.user && this.state.USER_DATA.user.refcode ? this.state.USER_DATA.user.refcode : ""}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <div className="referral-code-btns">
                                <a onClick={() => this.copyToClipboard(this.state.USER_DATA.user && this.state.USER_DATA.user.refcode ? this.state.USER_DATA.user.refcode : "")}>
                                    <div className="referral-code-btn-wrapper">
                                        <div className="referral-code-btn">
                                            <img src={images["copy-icon"]} alt="" />
                                        </div>
                                        <p>Copy</p>
                                    </div>
                                </a>

                                <button id="shareBtn" onClick={() => this.onShare()}>
                                    <div className="referral-code-btn-wrapper">
                                        <div className="referral-code-btn">
                                            <img src={images["share-icon"]} alt="" />
                                        </div>
                                        <p>Share</p>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </section>

                </div>
                <Modal
                    isOpen={this.state.shareModal}
                    onRequestClose={() => this.setState({ shareModal: false })}
                    ariaHideApp={true}
                    className={"modal_main_container"}
                >
                    <div className="modal_section">
                        <div className="shareToModal" style={{ display: "block" }}>
                            <div className="shareToModal-content">
                                <div className="shareTo-section">
                                    <h1>Share to</h1>
                                    <div className="shareTo-container">
                                        <div className="shareTo-wrapper">
                                            <img src={images["facebook"]} alt="" />
                                            <p>Facebook</p>
                                        </div>

                                        <div className="shareTo-wrapper">
                                            <img src={images["whatsapp"]} alt="" />
                                            <p>WhatsApp</p>
                                        </div>

                                        <div className="shareTo-wrapper">
                                            <img src={images["message"]} alt="" />
                                            <p>Message</p>
                                        </div>
                                    </div>
                                    <div className="">
                                        <button className="close_btn" onClick={() => this.setState({ shareModal: false })}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.copyModal}
                    onRequestClose={() => this.setState({ copyModal: false })}
                    ariaHideApp={false}
                    className={"modal_main_container"}
                >
                    <div className="modal_section">
                        <section className="popup-section">
                            <div className="popup-container">
                                <div className="top">
                                    <img src={images["link-copied"]} alt="" />
                                    <p>Link Copied</p>
                                </div>

                                <div className="back-button"><a onClick={() => this.setState({ copyModal: false })}>OK</a></div>
                            </div>
                        </section>
                    </div>
                </Modal>
            </>
        );
    }
}
export default ReferralCodeScreen;
