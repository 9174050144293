import React from "react";

import Header from "../../components/Header";
import SubHeader from "../../components/SubHeader";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import PublicMenuBar from "../../components/PublicMenuBar";

class LoginScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLang: localStorage.getItem("language"),
            languageModal: false,
            username: null,
            password: null,
            errorMessage: [],
        }
    }

    async componentDidMount() {
    }

    /**
 * Change Language
 * @param {string} obj 
 */
    async handleChange() {
        let obj = this.state.selectedLang;
        if (obj === "my") {
            strings.setLanguage('my');
            await localStorage.setItem("language", 'my');
            this.setState({ selectedLang: 'my' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ selectedLang: 'en' });
            window.location.reload();
        }
    };

    onPressLogin() {
        const self = this;
        axios({
            method: 'POST',
            url: api.Login,
            data: {
                username: this.state.username ?? '',
                password: this.state.password ?? '',
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': this.state.lang,
            }
        }).then(async function (ress) {
            console.log(ress.data);
            if (ress.data.error == 202 || ress.data.error == '202') {
                if (ress.data.errors && ress.data.errors.length != 0) {
                    self.setState({ errorMessage: ress.data.errors });
                } else {
                    toast.error(ress.data.message);
                }
            } else {
                await localStorage.setItem('user_token', (ress.data.access_token) ? ress.data.access_token : '');
                await localStorage.setItem('isAuthenticated', true);
                toast.success(ress.data.message);
                self.props.history.go('/home');
                return false;
            }
        }).catch(function (err) {
            if (err && err.response && (err.response.status == 422)) {
                if (err && err.response && err.response.data && err.response.data.errors) {
                    self.setState({ errorMessage: err.response.data.errors });
                }
            }
            if (err && err.response && err.response.data && (err.response.data.error === '202' || err.response.data.error === 202)) {
                let msg = err.response.data && err.response.data.message ? err.response.data.message : strings.something_wrong;
                toast.error(msg);
            } else {
                toast.error(strings.invalid_login_details);
            }
        });
    }

    render() {
        return (
            <>
                {/* <PublicMenuBar /> */}
                {/* <Header data={{ headerTitle: strings["BeliBeli"] }} /> */}
                <SubHeader data={{ headerTitle: strings["BeliBeli"] }} />
                <>
                    <section className="login-signup-header-section">
                        <div className="login-signup-header-container">
                            <div className="change-language">
                                <a onClick={() => this.setState({ languageModal: true })}><p>{strings[this.state.selectedLang]}</p></a>
                            </div>


                            <div className="login-title">
                                <p>Access you account</p>
                                <h1>Login to Belibeli</h1>
                                <p>No account yet? <Link to={'/register'}><span> Sign Up</span></Link></p>
                            </div>
                        </div>
                    </section>

                    <section className="form-section login-section">
                        <div className="form">
                            <div className="input-box">
                                <label>Mobile Number<span>*</span></label>
                                <input onChange={(e) => this.setState({ username: e.target.value })} defaultValue={this.state.username} type="number" placeholder="Enter Mobile Number" />
                                <div className='row'> {this.state.errorMessage.username && <div className='errorMessage'> {this.state.errorMessage.username[0]} </div>}</div>
                            </div>
                            <div className="input-box">
                                <label>Password<span>*</span></label>
                                <input onChange={(e) => this.setState({ password: e.target.value })} defaultValue={this.state.password} type="password" placeholder="Enter Password" />
                                <div className='row'> {this.state.errorMessage.password && <div className='errorMessage'> {this.state.errorMessage.password[0]} </div>}</div>
                            </div>

                            <div className="login-action-btn">
                                <button onClick={() => this.onPressLogin()}>Log In</button>
                                <Link to={"/forgot-password"}><p>Forgot Password?</p></Link>
                            </div>

                            <div className="privacy-policy">
                                <p><span>Privacy Policy</span> and <span>Terms of Service</span> apply</p>
                            </div>
                        </div>
                    </section>

                    <Modal
                        isOpen={this.state.languageModal}
                        onRequestClose={() => this.setState({ languageModal: false })}
                        ariaHideApp={false}
                        className={"modal_main_container"}
                    >
                        <div className="modal_section">
                            <section className="popup-section">
                                <div className="popup-container choose-language">
                                    <div className="choose-language-box">
                                        <p className="current-language">Current Language: <span className="language-span">{strings[this.state.selectedLang]}</span></p>
                                        <div className="language-box">
                                            <p>Select Language</p>
                                            <div className="language-option">
                                                <div className="language">
                                                    <input onChange={(e) => this.setState({ selectedLang: e.target.value })} defaultValue={'en'} defaultChecked={this.state.selectedLang == 'en' ? true : false} type="radio" id="check-english" name="language" />
                                                    <div className="check-green"><i className="fa-solid fa-check"></i></div>
                                                    <label htmlFor="check-english">{strings['en']}</label>
                                                </div>

                                                <div className="language">
                                                    <input onChange={(e) => this.setState({ selectedLang: e.target.value })} defaultValue={'my'} defaultChecked={this.state.selectedLang == 'my' ? true : false} type="radio" id="check-malaysia" name="language" />
                                                    <div className="check-green"><i className="fa-solid fa-check"></i></div>
                                                    <label htmlFor="check-malaysia">{strings['my']}</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="language-button">
                                        <div className="cancel-button" onClick={() => this.setState({ languageModal: false })}>Cancel</div>
                                        <button className="change-language-button" onClick={() => this.handleChange()}>Change Language</button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Modal>
                </>
            </>
        );
    }
}
export default withRouter(LoginScreen);
