import en from './en';
import my from './my';
import LocalizedStrings from 'react-localization';
let strings = new LocalizedStrings({
    en:en,
    my:my,
});
if (localStorage.getItem('language')) {
    strings.setLanguage(localStorage.getItem('language'));
}
else{
    localStorage.setItem("language", 'en');
    strings.setLanguage('en');
}

export default strings;