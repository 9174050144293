import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";

class WithdrawSuccessScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            amount: "1",
            data: [],
            paymentType: 1,
            USER_DATA: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
        }
    }

    render() {
        return (
            <>
                <section className="popup-section">
                    <div className="popup-container credit-success-container">
                        <img src={images["credit-success-icon"]} alt="" />
                        <div className="credit-success">
                            <h2>Withdraw Success!</h2>
                            <p>You have withdrawn RM<span>100.00</span> successfully! <span>(ID: 123872398)</span></p>
                        </div>
                        <div className="popup-button-box">
                            <Link to={"/withdraw-credit"} className="ok-button">OK</Link>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
export default WithdrawSuccessScreen;
