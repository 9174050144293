import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";
import moment from "moment";

class WithdrawalTransactionScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            data: [],
            next_page_url: null,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            setTimeout(() => {
                this.setState({ USER_DATA: userData.data });
                this.getReport(api.withdraw);
            });
        }
    }

    getReport(API) {
        const self = this;
        self.setState({ 'isLoading': true, next_page_url: null })
        axios({
            method: 'GET', url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })
            if (ress.data.error == 200) {
                self.setState({
                    data: [...self.state.data, ...ress.data.data.data],
                    next_page_url: ress.data.message.next_page_url
                });
            }
        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false });
        });
    }

    render() {
        return (
            <>
                <div className="credit-body" style={{ height: window.innerHeight }}>

                    <SubHeader data={{ headerTitle: strings["Completed_Withdrawal_History"], not_display_help: true }} />

                    <section className="withdrawal-section">
                        <div className="withdraw-container">
                            <h2 className="transaction-title">{strings["Completed_Withdrawal_History"]}</h2>
                            {this.state.data.map((item, i) =>
                                <div key={"1000_" + i}>
                                    <div className="withdraw-row">
                                        <div className="withdraw-col-1">
                                            <div className="withdraw-icon">
                                                <img src={images["topup-icon"]} alt="" />
                                            </div>
                                            <div className="withdraw-detail">
                                                <h2 className="withdraw-id">{item.bank_account_no.replace(/.(?=.{5})/g, 'x')}</h2>
                                                <h3 className="withdraw-process">{item._status}</h3>
                                                <h3 className="withdraw-date">{moment(item.created_at).format("DD MMM YYYY")}</h3>
                                            </div>
                                        </div>
                                        <div className="withdraw-col-2">
                                            <div className="withdraw-amount">
                                                <p>{strings.rm} <span>{(item.amount > 0) ? "+" : ""}{number_format(item.amount, 2, '.', ',')}</span></p>
                                            </div>
                                            <div className="withdraw-status processing"><p style={{ color: item.status_color }}>{item.status_word}</p></div>
                                        </div>
                                    </div>

                                    <hr className="credit-row-bottom-line" />
                                </div>
                            )}
                            {this.state.next_page_url ?
                                <a className="view-more" onClick={() => this.getReport(this.state.next_page_url)}>View More</a>
                                : null}
                        </div>
                    </section>
                </div>
            </>
        );
    }
}
export default WithdrawalTransactionScreen;
