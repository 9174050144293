const BaseURL = 'https://wego.global';
const ServerURL = BaseURL + '/api/';
const api = {
    SiteURL: BaseURL,
    //App Update and Version
    iosApp: BaseURL + "app-download",
    androidApp: "market://details?id=com.stallion.wego",
    AndroidVersionPublic: ServerURL + "app/version?platform=android&app_name=member",
    IOSVersionPublic: ServerURL + "app/version?platform=ios&app_name=member",

    uploadPath: BaseURL + "/upload/",
    ProductImage_URL: BaseURL + '/upload/products/',
    ShopLogo_URL: BaseURL + '/upload/vendors/',
    ProfilePic_URL: BaseURL + '/upload/',
    Slider_URL: BaseURL + '/upload/slider/',
    QRSharelink: BaseURL + '/register?ref=',
    VendorLogo_URL: BaseURL + '/upload/vendors/',
    Category_icon_URL: BaseURL + '/upload/category/icons/',
    VehicleImage_URL: BaseURL + '/upload/vehicle/',
    ICImage_URL: BaseURL + '/upload/vehicle-provider/ic/',
    DLImage_URL: BaseURL + '/upload/vehicle-provider/dl/',

    //Login & Register
    Login: ServerURL + 'login',
    Register: ServerURL + 'register',
    RegisterOTP: ServerURL + 'register-otp',
    getUserData: ServerURL + 'user/profile/info',
    ForgotPassword: ServerURL + 'forgot-password',
    ForgotPasswordOtp: ServerURL + 'forgot-password/otp',

    //Dashboard
    DashboardSlider: ServerURL + 'banner/data',
    sectionList: ServerURL + 'section/list',

    //Edit Profile 
    ProfileEdit: ServerURL + 'user/profile/update',
    UpdateDP: ServerURL + 'user/profile/avatar/update',
    LoginPasswordUpdate: ServerURL + 'user/profile/password/update',

    //Report
    BegoPointReport: ServerURL + 'user/report/bego-point/data',
    BegoCreditReport: ServerURL + 'user/report/bego-credit/data',
    BegoCouponReport: ServerURL + 'user/report/bego-coupon/data',

    //Mall
    CategoryIcon: BaseURL + '/upload/category/icons/',
    CategoryBanner: BaseURL + '/upload/category/',
    CategoryList: ServerURL + 'mall/category/list',
    ProductList: ServerURL + 'mall/category/',
    ProductSingle: ServerURL + 'mall/product/',
    PopularProducts: ServerURL + 'mall/product/list/popular',
    CartToAddProduct: ServerURL + 'user/mall/cart/add',
    CartList: ServerURL + 'user/mall/cart',
    UpdateCartItem: ServerURL + 'user/mall/cart/update',
    RemoveCartItem: ServerURL + 'user/mall/cart/delete',
    CheckOutData: ServerURL + 'user/mall/checkout',
    CheckOutPost: ServerURL + 'user/mall/checkout',
    OrderList: ServerURL + 'user/mall/order/list',
    getOrderData: ServerURL + 'user/mall/order/',
    orderAction: ServerURL + 'user/mall/order/status/update',

    //Vendor Mall
    VendorMallList: ServerURL + 'mall/guest/merchant/list',
    VendorMallDetails: ServerURL + 'mall/guest/merchant/',
    ShopGiveRating: ServerURL + 'user/mall/merchant/rating',

    //Address
    Address: ServerURL + 'user/mall/address/',
    AddressList: ServerURL + 'user/mall/address/data',
    AddressAdd: ServerURL + 'user/mall/address/add',

    //TopUp
    TopUpAdd: ServerURL + 'user/top-up/add',
    TopUpList: ServerURL + 'user/top-up/data',
    Deposit: ServerURL + "user/ad/deposit",
    DepositCheck: ServerURL + "user/top-up/inquiry",
    Deposit2C2P: BaseURL + "/sarawak-pay/public",
    DepositEGHL: BaseURL + "/eghl/public",

    ReferralList: ServerURL + 'user/my-account/referral',

    //Vendor Apply
    ApplyforVendorCheck: ServerURL + 'user/mall/merchant/become',
    ApplyforVendor: ServerURL + 'user/mall/merchant/become',

    //Rider Apply
    ApplyforRiderCheck: ServerURL + 'user/ehailing/become-driver/info',
    ApplyforRider: ServerURL + 'user/ehailing/become-driver/apply',
    VehicleListAll: ServerURL + 'user/ehailing/vehicle-list-all',
    //Rider Apply

    //Service Provider Apply
    ApplyforServiceProvider: ServerURL + 'user/services/become-provider/apply',

    ApplyforFoodRiderCheck: ServerURL + 'user/restaurant/become-rider/apply',
    ApplyforFoodRider: ServerURL + 'user/restaurant/become-rider/apply',

    //Merchant Apply
    ApplyforMerchantCheck: ServerURL + 'user/restaurant/become-merchant/apply',
    ApplyforMerchant: ServerURL + 'user/restaurant/become-merchant/apply',

    // E-Hailing
    VehicleListByCity: ServerURL + 'user/ehailing/vehicle-list-by-city',
    DoBooking: ServerURL + 'user/ehailing/do-booking',
    DriverListByLocation: ServerURL + 'user/ehailing/driver-list-by-location',
    BookingHistory: ServerURL + 'user/ehailing/order/list',
    EHailingDeliveryHistoryData: ServerURL + 'user/ehailing/order/detail/',

    // Food-Delivery
    restaurant: ServerURL + 'restaurant/',
    restaurant_user: ServerURL + 'user/restaurant/',
    restaurant_list: ServerURL + 'restaurant/list',
    restaurant_cart: ServerURL + 'user/restaurant/cart/data',
    DeliveryHistory: ServerURL + 'user/restaurant/order/list',
    DeliveryHistoryData: ServerURL + 'user/restaurant/order/',

    FoodAddress: ServerURL + 'user/restaurant/address/',
    FoodAddressList: ServerURL + 'user/restaurant/address/data',
    FoodAddressAdd: ServerURL + 'user/restaurant/address/add',

    Notification: ServerURL + 'user/notification',
    NotificationId: ServerURL + 'user/notification/update-id',

    withdraw: ServerURL + 'user/withdraw',

    // Services
    services_address: ServerURL + 'user/services/address/',
    services_address_data: ServerURL + 'user/services/address/data',
    services_address_add: ServerURL + 'user/services/address/add',

    services_slider: ServerURL + 'services/slider',
    services_news_promotion: ServerURL + 'services/news-promotion',
    services_product_popular: ServerURL + 'services/product/popular',
    services_shop: ServerURL + 'services/shop/',
    services_shop_near: ServerURL + 'services/shop/near',
    services_shop_search: ServerURL + 'services/shop/search',
    services_category: ServerURL + 'services/category',
    services_shop_top: ServerURL + 'services/shop/top',

    services_shop_user: ServerURL + 'user/services/shop/',
    services_order: ServerURL + 'user/services/order',
    services_cart: ServerURL + 'user/services/my/cart',
    services_checkout: ServerURL + 'user/services/my/checkout',
    services_cart_apply_coupon: ServerURL + 'user/services/my/cart/apply-coupon',
    services_cart_remove_service: ServerURL + 'user/services/my/cart/remove-service',
    services_upload_path: ServerURL + 'services/shop/gallery/',

    bank: ServerURL + 'user/bank',
};
export default api;