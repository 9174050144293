import React from "react";
import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import _ from "lodash";
import images from "../../config/image";


class RestaurantAllScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            data: [],
            mainData: [],
            next_page_url: '',
            latitude: 0,
            longitude: 0,
            isFetching: false,
            isLoading: true,
        }
    }

    async componentDidMount() {
        let self = this;
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            self.setState({ access_token: token });
            var default_address = localStorage.getItem('default_address');
            var default_address_data = JSON.parse(default_address);
            setTimeout(() => {
                if (token) {
                    if (default_address_data) {
                        self.setState({ latitude: default_address_data.lat });
                        self.setState({ longitude: default_address_data.lng });
                        setTimeout(() => {
                            if (self.state.latitude && self.state.longitude) {
                                var API = api.restaurant_list + "?lat=" + self.state.latitude + "&lng=" + self.state.longitude
                                self.getRestaurantList(API);
                            }
                        });
                    } else {
                        setTimeout(() => {
                            self.getAddressList();
                        });
                    }
                }
            });

        }
    }

    getAddressList() {
        let self = this;
        self.setState({ 'defaultADdress': {} });
        self.setState({ 'isLoading': true })
        axios({
            method: 'GET', url: api.FoodAddressList + "?lang=" + self.state.lang,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })
            self.setState({ 'addressData': ress.data.message });
            navigator.geolocation.getCurrentPosition((position) => {
                self.setState({ latitude: position.coords.latitude });
                self.setState({ longitude: position.coords.longitude });
                setTimeout(() => {
                    if (self.state.latitude && self.state.longitude) {
                        var API = api.restaurant_list + "?lat=" + self.state.latitude + "&lng=" + self.state.longitude
                        self.getRestaurantList(API);
                    }
                });
            });
        }).catch(function (erro) {
            toast.error(strings.server_error)
            self.setState({ 'isLoading': false });
        });
    }

    getRestaurantList(url) {
        if (url == "" || url == null) {
            return true;
        }
        let self = this;
        var API = url;
        var pos = url.search("page=");
        if (pos > -1) {
            API = url + "&lat=" + self.state.latitude + "&lng=" + self.state.longitude;
        }

        self.setState({ 'isLoading': true })
        axios({
            method: 'GET', url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })
            if (ress.data.error == 200) {
                if (ress.data.message && ress.data.message.data) {
                    self.setState({
                        data: [...self.state.data, ...ress.data.message.data],
                        mainData: [...self.state.mainData, ...ress.data.message.data],
                        next_page_url: ress.data.message.next_page_url
                    })
                }
            } else {
                toast.error(ress.data.message);
            }
        }).catch(function (erro) {
            toast.error(strings.server_error)
            self.setState({ 'isLoading': false });
        });
    }

    handleChange = event => {
        this.setState({
            serach: event.target.value
        }, this.filterHandler);
    };

    filterHandler = () => {
        let search_string = this.state.serach;
        var data = _.filter(this.state.mainData, function (data) {
            return _.includes(data.name, search_string);
        });
        this.setState({ 'data': data })
    }

    render() {
        return (
            <>
                <section className="topbar-section">
                    <div className="topbar-container topbar-restaurant">
                        <div className="topbar-only-left">
                            <Link to={"/delivery"}><i className="fa-solid fa-chevron-left"></i></Link>
                        </div>
                        <div className="search-input-box restaurant-search">
                            <span className="search-icon">
                                <img src={images["search-icon"]} className="search-icon-img" alt="" />
                            </span>
                            <input onChange={this.handleChange} defaultValue={this.state.serach} type="text" placeholder="Search Restaurant" />
                        </div>
                    </div>
                </section>

                <section className="orders-section restaurant-order-section">
                    <div className="orders-container">
                        {this.state.data.map((item, i) =>
                            <>
                                <Link to={`/restaurant/${item.id}`} key={"1000_" + i} className="order-row">
                                    <div className="order-col-1">
                                        {item.logo ?
                                            <img src={api.uploadPath + item.logo} alt="" />
                                            :
                                            <img src={images["orders1"]} alt="" />
                                        }
                                    </div>
                                    <div className="order-col-2 restaurant-order">
                                        <h2 className="restaurant-h2">{item.name}</h2>
                                        <h3 className="restaurant-h3">{item.address}</h3>
                                    </div>
                                    <div className="order-col-3">
                                        <div className="rating">
                                            <img src={images["star"]} alt="" />
                                            <p>5.0</p>
                                        </div>
                                        <div className="restaurant-distance"><p>{item.distance} {strings.km}</p></div>
                                    </div>
                                </Link>
                                <hr className="order-row-bottom-line" />
                            </>
                        )}

                        {this.state.next_page_url ?
                            <a className="view-more" onClick={() => this.getRestaurantList(this.state.next_page_url)}>View More</a>
                            : null}
                    </div>
                </section>
            </>
        );
    }
}
export default RestaurantAllScreen;
