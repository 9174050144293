const images = {
    "restaurant-background": require("../images/restaurant-background.png"),
    "burgertimes-logo": require("../images/burgertimes-logo.png"),
    "burger-big": require("../images/burger-big.png"),
    "bin": require("../images/bin.png"),
    "bin-grey": require("../images/bin-grey.png"),
    "burger1": require("../images/burger1.png"),
    "screen-confirm-success-icon": require("../images/screen-confirm-success-icon.png"),
    "cart-button": require("../images/cart-button.png"),
    "screen-cart-empty": require("../images/screen-cart-empty.png"),
    "search-icon": require("../images/search-icon.png"),
    "help-icon": require("../images/help-icon.png"),
    "orders1": require("../images/orders1.png"),
    "screen-cancel-remove-icon": require("../images/screen-cancel-remove-icon.png"),
    "change-language": require("../images/change-language.png"),
    "account-settings": require("../images/account-settings.png"),
    "logout": require("../images/logout.png"),
    "edit-profile-icon": require("../images/edit-profile-icon.png"),
    "topup-icon": require("../images/topup-icon.png"),
    "withdraw-icon": require("../images/withdraw-icon.png"),
    "qr-code": require("../images/qr-code.png"),
    "copy-icon": require("../images/copy-icon.png"),
    "share-icon": require("../images/share-icon.png"),
    "facebook": require("../images/facebook.png"),
    "whatsapp": require("../images/whatsapp.png"),
    "message": require("../images/message.png"),
    "link-copied": require("../images/link-copied.png"),
    "screen-cancel-remove-icon": require("../images/screen-cancel-remove-icon.png"),
    "belibeli-points": require("../images/belibeli-points.png"),
    "arrow-right-icon": require("../images/arrow-right-icon.png"),
    "rider-icon": require("../images/rider-icon.png"),
    "ehailing-icon": require("../images/ehailing-icon.png"),
    "merchant-icon": require("../images/merchant-icon.png"),
    "service-icon": require("../images/service-icon.png"),
    "marketplace-icon": require("../images/marketplace-icon.png"),
    "fpx-logo": require("../images/fpx-logo.png"),
    "sarawake": require("../images/sarawake.png"),
    "credit-success-icon": require("../images/credit-success-icon.png"),
    "password-changed": require("../images/password-changed.png"),
    "signup-complete": require("../images/signup-complete.png"),
    "location-icon": require("../images/location-icon.png"),
    "dropdown-icon": require("../images/dropdown-icon.png"),
    "home-delivery-background": require("../images/home-delivery-background.png"),
    "credit-point1": require("../images/credit-point1.png"),
    "credit-point2": require("../images/credit-point2.png"),
    "picks1": require("../images/picks1.png"),
    "news1": require("../images/news1.png"),
    "orders1": require("../images/orders1.png"),
    "star": require("../images/star.png"),
    "feature1": require("../images/feature1.png"),
    "feature2": require("../images/feature2.png"),
    "feature3": require("../images/feature3.png"),
    "feature4": require("../images/feature4.png"),
    "e-hailing": require("../images/e-hailing.png"),
    "messages-icon": require("../images/messages-icon.png"),
    "nextarrow-icon": require("../images/nextarrow-icon.png"),
    "map-icon": require("../images/map-icon.png"),
    "referralcode-icon": require("../images/referralcode-icon.png"),
    "application-icon": require("../images/application-icon.png"),
    "policies-icon": require("../images/policies-icon.png"),
    "settings-icon": require("../images/settings-icon.png"),
    "contactus-icon": require("../images/contactus-icon.png"),
    "closeacc-icon": require("../images/closeacc-icon.png"),
    "location-pin": require("../images/location-pin.png"),
}
export default images;

