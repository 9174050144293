import React from "react";
import Header from "../../../components/Header";
import strings from "../../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../../config/api";
import image from "../../../config/image";
import axios from "axios";
import MenuBar from "../../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../../components/Util";
import _ from "lodash";
import SubHeader from "../../../components/SubHeader";


class PublicRestaurantDetailsScreen extends React.Component {
    constructor(props) {
        super(props);
        var restaurantId = this.props.match.params.restaurantid;
        var itemId = this.props.match.params.itemid;
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            restaurantId: restaurantId,
            itemId: itemId,
            is_data_empty: true,
            quantity: 1,
            item_addon_ids: [],
            data: [],
            resData: {},
        }
    }

    async componentDidMount() {
        setTimeout(() => {
            this.getItemData();
            this.getRestaurantData();
        });
    }

    getRestaurantData() {
        let self = this;
        axios({
            method: 'GET',
            url: api.restaurant + self.state.restaurantId + "/all-detail",
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            console.log(ress.data.message);
            self.setState({ resData: ress.data.message })
        }).catch(function (erro) {
            toast.error(strings.server_error)
            self.setState({ 'isLoading': false });
        });
    }

    getItemData() {
        let self = this;
        self.setState({ 'isLoading': true });
        axios({
            method: 'GET', url: api.restaurant + self.state.restaurantId + "/item/" + self.state.itemId + "/detail",
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })
            if (ress.data.error == 200) {
                self.setState({ 'data': ress.data.message })
                self.setState({ 'is_data_empty': false })
            } else {
                self.setState({ 'is_data_empty': true })
                toast.warning(ress.data.message);
            }
        }).catch(function (erro) {
            toast.error(strings.server_error)
            self.setState({ 'isLoading': false });
        });
    }

    AddToCart() {
        let self = this;
        var item_addon_ids = _.filter(this.state.item_addon_ids, function (o) {
            if (o.checked)
                return o.item_addon_id;
        });
        var json = {
            "overwrite_restaurant": "true",
            "quantity": this.state.quantity,
            "item_addon_ids": _.map(item_addon_ids, 'item_addon_id'),
        }
        self.setState({ 'isLoading': true })
        axios({
            method: 'POST',
            url: api.restaurant_user + self.state.restaurantId + "/item/" + self.state.itemId + "/add-to-cart",
            data: json,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false });
            if (ress.data.error == 200) {
                toast.success(ress.data.message);
                self.props.history.replace({ pathname: `/checkout/${self.state.restaurantId}/${self.state.itemId}` })
            } else {
                toast.warning(ress.data.message);
            }
        }).catch(function (erro) {
            toast.error(strings.server_error)
            self.setState({ 'isLoading': false });
        });
    }

    handleCheck(id) {
        var addonsIds = this.state.item_addon_ids;
        if (addonsIds.some(item => id === item)) {
            addonsIds.filter(function (elem) {
                return elem != id;
            });
        } else {
            addonsIds.push(id);
        }
        this.setState({ 'item_addon_ids': addonsIds })
    }

    totalRM() {
        var total = (this.state.data.selling_price * this.state.quantity);
        var item_addon_price = _.filter(this.state.item_addon_ids, function (o) {
            if (o.checked)
                return o.price;
        });
        var add_on_total = _.sumBy(item_addon_price, 'price');
        return (total + add_on_total);
    }

    comingSoon() {
        toast.warning(strings.coming_soon);
    }

    render() {
        return (
            <>
                <>
                    <SubHeader data={{ headerTitle: strings["BeliBeli"] }} />

                    <div className="menu-detail-body">
                        <section className="profile-section">
                            <div className="burger-img">
                                {this.state.resData && this.state.resData.banner ?
                                    <img src={api.uploadPath + this.state.resData.banner} alt="" />
                                    :
                                    <img src={image["restaurant-background"]} alt="" />
                                }
                            </div>
                            <div className="profile-container">
                                <div className="profile-logo">
                                    {this.state.resData && this.state.resData.logo ?
                                        <img src={api.uploadPath + this.state.resData.logo} alt="" style={{ width: 57, height: 57, borderRadius: 100 }} />
                                        :
                                        <img src={image["burgertimes-logo"]} alt="" />
                                    }
                                </div>
                                <div className="profile-detail">
                                    <p className="profile-name">{this.state.resData.name}</p>
                                    <p className="profile-distance">{this.state.resData.distance ? this.state.resData.distance : 0 }{"km - "}{"5.0"}</p>
                                </div>
                            </div>
                        </section>

                        <section className="burger-detail-section">
                            <div className="burger-detail-container">
                                <div className="burger-big-img">
                                    {this.state.data && this.state.data.thumbnail ?
                                        <img src={api.uploadPath + this.state.data.thumbnail} alt="" />
                                        :
                                        <img src={image["restaurant-background"]} alt="" />
                                    }
                                </div>
                                <p className="burger-name">{this.state.data.name}</p>
                                <p className="burger-price">{strings.rm}{this.state.data.selling_price ? number_format(this.state.data.selling_price, 2, '.', ',') : "0.00"}</p>
                            </div>
                        </section>

                        <section className="add-on-section">
                            <div className="add-on-container">
                                <p className="add-on-title">Add On</p>
                                <p className="add-on-detail">-</p>
                            </div>
                        </section>

                        <section className="quantity-section">
                            <div className="quantity-container">
                                <button onClick={() => this.setState({ 'quantity': ((this.state.quantity > 1) ? this.state.quantity - 1 : 1) })} className="quantity-button" type="button"><i className="fa fa-minus"></i></button>
                                <div id="quantity">{number_format(this.state.quantity, 0, '.', ',')}</div>
                                <button onClick={() => this.setState({ 'quantity': (this.state.quantity + 1) })} className="quantity-button" type="button"><i className="fa fa-plus"></i></button>
                            </div>
                        </section>

                        <section className="add-button-section">
                            <div className="add-button-container">
                                <Link to={"/login"} className="add-to-cart-button">{strings.Add_to_cart}{" - "} {strings.rm}{number_format((this.state.data.selling_price && this.state.quantity) ? this.state.data.selling_price * this.state.quantity : 0, 2, '.', ',')}</Link>
                            </div>
                        </section>
                    </div>

                </>
            </>
        );
    }
}
export default PublicRestaurantDetailsScreen;
