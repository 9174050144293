import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";

class ProfileEditScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            name: null,
            email: null,
            mobile: null,
            avatar: null,
            errorMessage: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            setTimeout(() => {
                console.log(this.state.USER_DATA);
                this.setState({
                    USER_DATA: userData.data,
                    name: (userData.data.user && userData.data.user.name) ? userData.data.user.name : null,
                    email: (userData.data.user && userData.data.user.email) ? userData.data.user.email : null,
                    mobile: (userData.data.user && userData.data.user.mobile) ? userData.data.user.mobile : null
                });
            });
        }
    }

    onPressEditProfile() {
        let self = this;
        console.log(self.state.name);
        if (self.state.name == '' || self.state.name == '') {
            toast.error(strings.Please_enter_full_name);
            return false;
        } else if (self.state.email == '' || self.state.email == '') {
            toast.error(strings.enter_email_address);
            return false;
        } else if (self.state.mobile == '' || self.state.mobile == '') {
            toast.error(strings.Please_enter_mobile_number);
            return false;
        }
        self.setState({ 'isLoading': true })
        axios({
            method: 'post', url: api.ProfileEdit,
            data: {
                name: self.state.name,
                email: self.state.email,
                mobile: self.state.mobile,
            },
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (response) {
            self.setState({ 'isLoading': false })
            if (response.data.error == 202 || response.data.error == '202') {
                if (response.data.errors && response.data.errors.length != 0) {
                    self.setState({ errorMessage: response.data.errors });
                } else {
                    toast.error(response.data.message);
                }
            } else {
                toast.success(response.data.message);
                self.componentDidMount();
            }
        }).catch(function (error) {
            self.setState({ 'isLoading': false })
            toast.error("danger", strings.no_internet);
        });
    }

    changeProfileImage = (event) => {
        this.setState({ avatar: event.target.files[0] });
        if (this.state.avatar) {
            setTimeout(() => {
                this.updateDP();
            });
        }
    };

    updateDP() {
        let self = this;
        self.setState({ 'isLoading': true })
        let formData = new FormData();
        console.log(self.state.avatar);
        formData.append("avatar", self.state.avatar);

        axios({
            method: 'POST', url: api.UpdateDP,
            data: formData,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'multipart/form-data',
            }
        }).then(function (response) {
            self.setState({ 'isLoading': false })
            if (response.data.error != 200) {
                toast.error(response.data.message);
            } else if (response.data.error === 200) {
                self.componentDidMount();
                toast.success(response.data.message);
            }
        }).catch(function (error) {
            self.setState({ 'isLoading': false });
        })
    }

    render() {
        return (
            <>
                <MenuBar />
                <>
                    <div className="home-content-wrapper">
                        <SubHeader data={{ headerTitle: strings["Edit_Profile"] }} />

                        <section className="edit-profile-section">
                            <div className="edit-profile-container">
                                <div className="edit-profile">
                                    {(this.state.USER_DATA && this.state.USER_DATA.user && this.state.USER_DATA.user.avatar) ?
                                        <img className="rounded-profile" src={api.ProfilePic_URL + this.state.USER_DATA.user.avatar} alt="" style={{ borderWidth: 0.5, borderStyle: "solid", borderRadius: 100, borderColor: "gray" }} />
                                        :
                                        <div className="rounded-profile" style={{ borderWidth: 0.5, borderStyle: "solid", borderRadius: 100, borderColor: "gray" }}></div>
                                    }
                                    <label className="edit-profile-icon" htmlFor="file-input">
                                        <img className="edit-profile-icon" src={images["edit-profile-icon"]} alt="" />
                                    </label>
                                    <input id="file-input" onChange={this.changeProfileImage} type="file" style={{ display: "none" }} />
                                </div>
                                <h2>{this.state.USER_DATA.user && this.state.USER_DATA.user.name ? this.state.USER_DATA.user.name : ""}</h2>
                                <h2>{this.state.USER_DATA.user && this.state.USER_DATA.user.mobile ? this.state.USER_DATA.user.mobile : ""}</h2>
                            </div>
                        </section>

                        <section className="form-section edit-profile-form-section">
                            <form action="#" className="form">
                                <div className="input-box">
                                    <label>Full Name<span>*</span></label>
                                    <input onChange={(e) => this.setState({ name: e.target.value ? e.target.value : null })} defaultValue={this.state.name} type="text" placeholder="Enter Username" />
                                </div>

                                <div className="input-box">
                                    <label>Mobile Number<span>*</span></label>
                                    <input onChange={(e) => this.setState({ mobile: e.target.value ? e.target.value : null })} defaultValue={this.state.mobile} type="number" placeholder="Enter Mobile Number" />
                                </div>

                                <div className="input-box">
                                    <label>Email Address<span>*</span></label>
                                    <input onChange={(e) => this.setState({ email: e.target.value ? e.target.value : null })} defaultValue={this.state.email} type="text" placeholder="Enter Email Address" />
                                </div>

                                <div className="actions-btn-wrapper">
                                    <a onClick={() => this.onPressEditProfile()} className="actions-btn">Save</a>
                                </div>

                                <div className="edit-change-pwd">
                                    <p>Change Password?</p>
                                    <Link to={"/profile/change-password"} className="actions-btn">Change Password</Link>
                                </div>
                            </form>
                        </section>


                    </div>
                </>
            </>
        );
    }
}
export default ProfileEditScreen;
