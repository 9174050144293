import React from "react";
import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import _ from "lodash";
import images from "../../config/image";
import SubHeader from "../../components/SubHeader";


class RestaurantScreen extends React.Component {
    constructor(props) {
        super(props);
        var restaurantId = this.props.match.params.restaurantid;
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            restaurantId: restaurantId,
            data: [],
            categories: [],
            categoriesItem: [],
            activeTab: '',
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            this.setState({ USER_DATA: userData.data });
            setTimeout(() => {
                this.getRestaurantData();
            });
        }
    }

    getRestaurantData() {
        let self = this;
        axios({
            method: 'GET',
            url: api.restaurant + self.state.restaurantId + "/all-detail",
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            self.setState({ data: ress.data.message })
            self.setState({ categories: (ress.data.message && ress.data.message.categories) ? ress.data.message.categories : [] });
            self.setState({ activeTab: (ress.data.message && ress.data.message.categories && ress.data.message.categories[0] && ress.data.message.categories[0].id) ? ress.data.message.categories[0].id : '1' })
            self.setState({ categoriesItem: (ress.data.message && ress.data.message.categories && ress.data.message.categories[0] && ress.data.message.categories[0].items) ? ress.data.message.categories[0].items : [] })
        }).catch(function (erro) {
            toast.error(strings.server_error)
            self.setState({ 'isLoading': false });
        });
    }

    render() {
        return (
            <>
                <>
                    <SubHeader data={{ headerTitle: strings["BeliBeli"] }} />

                    <section className="profile-section">
                        <div className="burger-img">
                            {this.state.data && this.state.data.banner ?
                                <img src={api.uploadPath + this.state.data.banner} alt="" />
                                :
                                <img src={images["restaurant-background"]} alt="" />
                            }
                        </div>
                        <div className="profile-container">
                            <div className="profile-logo">
                                {this.state.data && this.state.data.logo ?
                                    <img src={api.uploadPath + this.state.data.logo} alt="" style={{ width: 57, height: 57, borderRadius: 100 }} />
                                    :
                                    <img src={images["burgertimes-logo"]} alt="" />
                                }
                            </div>
                            <div className="profile-detail">
                                <p className="profile-name">{this.state.data.name}</p>
                                <p className="profile-distance">{this.state.data.distance ? this.state.data.distance : 0 + "km"}{" - "}{"5.0"}</p>
                            </div>
                        </div>
                    </section>

                    <section className="menu-section">
                        <div className="tabs">
                            <ul className="list-slider">
                                {this.state.categories.map((item, i) =>
                                    <li key={"1000_" + i} title="Features">
                                        <label className={(this.state.activeTab == item.id) ? "indicator" : "border-transparent"}
                                            onClick={() => this.setState({ activeTab: item.id, categoriesItem: item.items })} htmlFor="tab1" role="button">{item.name}</label>
                                    </li>
                                )}
                            </ul>
                            <div className="content">
                                <section className="a-la-carte-section">
                                    <div className="a-la-carte-container">
                                        {this.state.categoriesItem.map((item, i) =>
                                            <Link key={"2000_" + i} to={`/restaurant-detail/${this.state.restaurantId}/${item.id}`} className="burger-box">
                                                {item.thumbnail ?
                                                    <img src={api.uploadPath + item.thumbnail} alt="" />
                                                    :
                                                    <img src={images["burger1"]} alt="" />
                                                }
                                                <p>{item.name}</p>
                                            </Link>
                                        )}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>

                    <section className="cart-section">
                        <Link to={"/checkout"}>
                            <div className="cart-container">
                                <img src={images["cart-button"]} alt="" />
                            </div>
                        </Link>
                    </section>

                </>
            </>
        );
    }
}
export default withRouter(RestaurantScreen);
