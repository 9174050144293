import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import _ from "lodash";
import images from "../../config/image";
import SubHeader from "../../components/SubHeader";
import DeliveryMenuBar from "../../components/DeliveryMenuBar";
import PublicDeliveryMenuBar from "../../components/PublicDeliveryMenuBar";
const GOOGLE_MAPS_APIKEY = 'AIzaSyA_c6HVjCXCO6fSRNvxmiRxtHg3jAKzjz0';


class PublicDeliveryScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            sliderImages: [],
            sectionList: [],
            errorMessage: [],
            data: [],
            defaultADdress: '',
            latitude: 0,
            longitude: 0,
            isFetching: false,
            isLoading: false,
        }
    }

    async componentDidMount() {
        navigator.geolocation.getCurrentPosition((position) => {
            this.setState({ latitude: position.coords.latitude });
            this.setState({ longitude: position.coords.longitude });
            setTimeout(() => {
                this.getRestaurantList();
            });
        });
        this.getSlider();
        this.getSectionList();
    }

    getSlider() {
        let self = this;
        axios({
            method: 'GET',
            url: api.DashboardSlider + "?lang=" + self.state.lang,
            headers: {
                'Content-Type': 'application/json',
            }
        }
        ).then(function (ress) {
            self.setState({ sliderImages: ress.data.message });
        }).catch(function (erro) {
            toast.error(strings.server_error)
        });
    }

    getSectionList() {
        let self = this;
        axios({
            method: 'GET',
            url: api.sectionList + "?lang=" + self.state.lang,
            headers: {
                'Content-Type': 'application/json',
            }
        }
        ).then(function (ress) {
            self.setState({ sectionList: ress.data.message })
        }).catch(function (erro) {
            toast.error(strings.server_error);
        });
    }

    getAddressList() {
        let self = this;
        self.setState({ 'defaultADdress': {} });
        axios({
            method: 'GET',
            url: api.FoodAddressList + "?lang=" + self.state.lang,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            self.setState({ 'addressData': ress.data.message });
            var is_default_address = false;
            var is_default_data = {};
            _.forEach(ress.data.message, function (value, key) {
                if (value.is_default || key == 0) {
                    self.setState({ 'defaultADdress': value });
                    is_default_address = true;
                    is_default_data = value;
                }
            });
            if (!is_default_address) {
                navigator.geolocation.getCurrentPosition((position) => {
                    self.setState({ latitude: position.coords.latitude });
                    self.setState({ longitude: position.coords.longitude });
                    setTimeout(() => {
                        self.getAddressFromCoordinates(position.coords.latitude, position.coords.longitude);
                        self.getRestaurantList();
                    });
                });
            } else {
                self.setState({ latitude: is_default_data.lat });
                self.setState({ longitude: is_default_data.lng });
                self.getRestaurantList();
            }

        }).catch(function (erro) {
            toast.error(strings.server_error)
            self.setState({ 'isLoading': false });
        });
    }

    getAddressFromCoordinates(latitude, longitude) {
        let self = this;
        var URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_APIKEY}`
        fetch(URL).then(response => response.json()).then(responseJson => {
            if (responseJson['status'] == 'OK') {
                var address = responseJson['results'][0]['formatted_address'];
                var landmark = "";
                var city = "";
                var address_components = responseJson['results'][0]['address_components'];
                if (address_components) {
                    address_components.map((component, key) => {
                        const componentType = component.types[0];
                        var search_string = '';
                        switch (componentType) {
                            case "locality": {
                                landmark = `${component.long_name}`;
                                break;
                            }
                            case "political": {
                                city = component.long_name;
                                break;
                            }
                        }
                    });
                }

                setTimeout(() => {

                    var json = {
                        annotation: "Other",
                        flat_no: "_",
                        address: address,
                        landmark: landmark,
                        city: city,
                        lat: latitude,
                        lng: longitude,
                        is_default: true,
                    };

                    axios.post(api.FoodAddressAdd, json, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(function (response) {
                        self.setState({ 'isLoading': false });
                        self.getSlider();
                        self.getSectionList();
                        self.getAddressList();

                    }).catch(function (error) {
                    });
                });
            }
        }).catch(e => {
            console.warn(e)
            self.setState({ 'isLoading': false });
        });
    }

    getRestaurantList() {
        let self = this;
        self.setState({ 'isLoading': true })
        var API = api.restaurant_list;
        if (self.state.latitude && self.state.longitude) {
            API = api.restaurant_list + "?lat=" + self.state.latitude + "&lng=" + self.state.longitude;
        }
        axios({
            method: 'GET', url: API,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })
            if (ress.data.error == 200) {
                if (ress.data.message && ress.data.message.data) {
                    // var finalData = _.filter(ress.data.message.data, function (o) {
                    //     if (o.is_open) {
                    //         return o;
                    //     }
                    // });
                    self.setState({ 'data': ress.data.message.data })
                }
            } else {
                toast.error(ress.data.message);
                self.setState({ 'address_modal': true });
            }
        }).catch(function (erro) {
            toast.error(strings.server_error)
            self.setState({ 'isLoading': false });
        });
    }

    render() {
        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            lazyLoad: true,
            speed: 2500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        const settings2 = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 2500,
            slidesToShow: 3,
            slidesToScroll: 3
        };
        return (
            <>
                <PublicDeliveryMenuBar />
                <>
                    <div className="home-content-wrapper">
                        <SubHeader data={{ headerTitle: strings["BeliBeli"] }} />

                        <section className="search-bar">
                            <Link to={"/public/delivery/restaurant-all"}>
                                <div className="search-input-box">
                                    <span className="search-icon">
                                        <img src={images["search-icon"]} className="search-icon-img" alt="" />
                                    </span>
                                    <input type="text" placeholder="Search Restaurant" />
                                </div>
                            </Link>
                        </section>

                        <section className="home-banner-section">
                            <div className="home-banner-container">
                                <Slider {...settings}>
                                    {this.state.sliderImages.map((item, i) =>
                                        <div key={"1000_" + i} className="home-banner-image">
                                            {item.file_en ?
                                                <img src={api.Slider_URL + item.file_en} alt="" />
                                                :
                                                <img src={images["home-delivery-background"]} alt="" />
                                            }
                                        </div>
                                    )}
                                </Slider>
                            </div>
                        </section>

                        <section className="credit-point-section">
                            <div className="credit-container">
                                <div className="credit-icon">
                                    <img src={images["credit-point1"]} alt="" />
                                </div>
                                <div className="credit-detail">
                                    <h3>Belibeli Credit</h3>
                                    <p>{strings.rm} {this.state.USER_DATA && this.state.USER_DATA.credit && this.state.USER_DATA.credit.bego_credit ? number_format(this.state.USER_DATA.credit.bego_credit, 2, '.', ',') : '0.00'}</p>
                                </div>
                            </div>

                            <div className="point-container">
                                <div className="point-icon">
                                    <img src={images["credit-point2"]} alt="" />
                                </div>
                                <div className="point-detail">
                                    <h3>Belibeli Point</h3>
                                    <p>{this.state.USER_DATA && this.state.USER_DATA.credit && this.state.USER_DATA.credit.bego_point ? number_format(this.state.USER_DATA.credit.bego_point, 2, '.', ',') : '0.00'}</p>
                                </div>
                            </div>
                        </section>
                        <hr className="credit-point-separator" />


                        <section className="picks-section">
                            <div className="picks-container">
                                <div className="pick-title-row">
                                    <h1>Belibeli's Restaurant picks</h1>
                                    <Link to={"/public/delivery/restaurant-all"} className="pick-view-all">View All</Link>
                                </div>
                                <div className="pick-carousel-wrapper">
                                    <div className="pick-carousel">
                                        <OwlCarousel loop items={2.5}>
                                            {this.state.data.map((item, i) =>
                                                <Link to={`/restaurant/${item.id}`} key={"2000_" + i}>
                                                    <div className="pick-card pick-card-1">
                                                        {item.banner ?
                                                            <img src={api.uploadPath + item.banner} alt="" />
                                                            :
                                                            <img src={images["picks1"]} alt="" />
                                                        }
                                                        <div className="pick-text-wrap">{item.name} (Siang Siang Corner)</div>
                                                        <div className="pick-distance">{item.distance + "km"}{" - "}{"5.0"}</div>
                                                    </div>
                                                </Link>
                                            )}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="news-section">
                            <div className="news-container">
                                <h1>Discovery More Shops</h1>
                                <div className="news-row">
                                    {this.state.sectionList.map((item, i) =>
                                        <div key={"3000_" + i} className="news-col how-to-order-item">
                                            {item.banner ?
                                                <img src={api.uploadPath + item.banner} alt="" />
                                                :
                                                <img src={images["news1"]} alt="" />
                                            }
                                            <p>{item.title ?? ''}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </section>
                    </div>

                    <section className="cart-delivery-section">
                        <Link to={"/login"}>
                            <div className="cart-delivery-container">
                                <img src={images["cart-button"]} alt="" />
                            </div>
                        </Link>
                    </section>
                </>
            </>
        );
    }
}
export default PublicDeliveryScreen;
