import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";

class SettingsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            selectedLang: strings.getLanguage(),
            languageModal: false,
            access_token: '',
            USER_DATA: {},
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            setTimeout(() => {
                this.setState({ USER_DATA: userData.data });
            });
        }
    }

    Logout() {
        localStorage.clear();
        window.location.reload(false);
    }

    async handleChange() {
        let obj = this.state.selectedLang;
        console.log(obj);
        if (obj === "my") {
            strings.setLanguage('my');
            await localStorage.setItem("language", 'my');
            this.setState({ selectedLang: 'my' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ selectedLang: 'en' });
            window.location.reload();
        }
    };

    render() {
        return (
            <>
                <MenuBar />
                <div className="home-content-wrapper">
                    <SubHeader data={{ headerTitle: strings["Settings"] }} />

                    <section className="setting-features-section">
                        <div className="setting-features-container">
                            <a onClick={() => this.setState({ languageModal: true })}>
                                <div className="setting-feature-row">
                                    <div className="setting-feature-col-1">
                                        <img src={images["change-language"]} alt="" />
                                    </div>
                                    <div className="setting-feature-col-2">
                                        <h2>Change Language</h2>
                                        <h3>Current language: {strings[this.state.selectedLang]}</h3>
                                    </div>
                                </div>
                            </a>
                            <hr />

                            <Link to={"/profile/settings/account"}>
                                <div className="setting-feature-row">
                                    <div className="setting-feature-col-1">
                                        <img src={images["account-settings"]} alt="" />
                                    </div>
                                    <div className="setting-feature-col-2">
                                        <h2>Account Settings</h2>
                                    </div>
                                </div>
                            </Link>
                            <hr />

                            <a onClick={() => this.Logout()}>
                                <div className="setting-feature-row">
                                    <div className="setting-feature-col-1">
                                        <img src={images["logout"]} alt="" />
                                    </div>
                                    <div className="setting-feature-col-2">
                                        <h2>Logout</h2>
                                    </div>
                                </div>
                            </a>
                            <hr />

                        </div>
                    </section>
                </div>

                <Modal
                    isOpen={this.state.languageModal}
                    onRequestClose={() => this.setState({ languageModal: false })}
                    ariaHideApp={false}
                    className={"modal_main_container"}
                >
                    <div className="modal_section">
                        <section className="popup-section">
                            <div className="popup-container choose-language">
                                <div className="choose-language-box">
                                    <p className="current-language">Current Language: <span className="language-span">{strings[this.state.selectedLang]}</span></p>
                                    <div className="language-box">
                                        <p>Select Language</p>
                                        <div className="language-option">
                                            <div className="language">
                                                <input onChange={(e) => this.setState({ selectedLang: e.target.value })} defaultValue={'en'} defaultChecked={this.state.selectedLang == 'en' ? true : false} type="radio" id="check-english" name="language" />
                                                <div className="check-green"><i className="fa-solid fa-check"></i></div>
                                                <label htmlFor="check-english">{strings['en']}</label>
                                            </div>

                                            <div className="language">
                                                <input onChange={(e) => this.setState({ selectedLang: e.target.value })} defaultValue={'my'} defaultChecked={this.state.selectedLang == 'my' ? true : false} type="radio" id="check-malaysia" name="language" />
                                                <div className="check-green"><i className="fa-solid fa-check"></i></div>
                                                <label htmlFor="check-malaysia">{strings['my']}</label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="language-button">
                                    <div className="cancel-button" onClick={() => this.setState({ languageModal: false })}>Cancel</div>
                                    <button className="change-language-button" onClick={() => this.handleChange()}>Change Language</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </Modal>
            </>
        );
    }
}
export default SettingsScreen;
