import React from "react";
import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import image from "../../config/image";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, storeUserData } from "../../components/Util";
import images from "../../config/image";
import moment from "moment";


class OrdersSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
        }
    }

    comingSoon() {
        toast.warning(strings.coming_soon);
    }

    render() {
        return (
            <>
                <>
                    <section className="status-content-section">
                        <div className="status-content-container">
                            <img src={images["screen-confirm-success-icon"]} alt="" />
                            <h1>Order Confirmed</h1>
                            <h2>{moment().format("YYYY-MM-DD hh:mm:ss")}</h2>
                            <h3>Order Confirmed</h3>
                            <Link className="status-option-1" to={"/delivery-orders"}>Check Orders</Link>
                        </div>
                    </section>
                </>
            </>
        );
    }
}
export default OrdersSuccess;
