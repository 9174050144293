import React from "react";
import GoogleMap from 'google-maps-react-markers'
import Autocomplete from "react-google-autocomplete";
import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import image from "../../config/image";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, storeUserData } from "../../components/Util";
import images from "../../config/image";
import SubHeader from "../../components/SubHeader";
const GOOGLE_MAPS_APIKEY = 'AIzaSyA_c6HVjCXCO6fSRNvxmiRxtHg3jAKzjz0';
const Marker = props => {
    return <div className="SuperAwesomePin">
        <img src={images["location-pin"]} alt="" width={50} height={50} />
    </div>
}
class DeliveryAddressAddSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            flat_no: '',
            address: "",
            landmark: "",
            receiver_name: "",
            city: "",
            mobile: "",
            latitude: 2.296471,
            longitude: 111.8897003,
            defaultZoom: 12,
            annotation: "Other",
            search_string: null,
            setMapMarkerReady: false,
            errorMessage: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            setTimeout(() => {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.setState({ latitude: position.coords.latitude });
                    this.setState({ longitude: position.coords.longitude });
                    setTimeout(() => {
                        this.getAddressFromCoordinates(position.coords.latitude, position.coords.longitude);
                    });
                });
            });
        }
    }

    getAddressFromCoordinates(latitude, longitude, is_start_address = false) {
        let self = this;
        var URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_APIKEY}`
        fetch(URL).then(response => response.json()).then(responseJson => {
            if (responseJson['status'] == 'OK') {
                var format_address = responseJson['results'][0]['formatted_address'];
                self.setState({ address: format_address });
                self.setState({ address1: format_address });
                self.setState({ latitude: latitude });
                self.setState({ longitude: longitude });

                var address_components = responseJson['results'][0]['address_components'];
                if (address_components) {
                    address_components.map((component, key) => {
                        const componentType = component.types[0];

                        var search_string = '';
                        switch (componentType) {
                            case "street_number": {
                                search_string = `${component.long_name} ${format_address}`;
                                self.setState({ search_string: search_string });
                                break;
                            }

                            case "route": {
                                search_string += component.short_name;
                                self.setState({ search_string: search_string });
                                break;
                            }

                            case "postal_code": {
                                var postcode = `${component.long_name}`;
                                self.setState({ landmark: postcode });
                                break;
                            }

                            case "postal_code_suffix": {
                                var postcode = `${component.long_name}`;
                                self.setState({ landmark: postcode });
                                break;
                            }
                            case "locality":
                                var locality = component.long_name;
                                self.setState({ city: locality });
                                break;

                            case "administrative_area_level_1": {
                                var state = component.short_name;
                                self.setState({ state: state });
                                break;
                            }
                            case "country":
                                var country = component.long_name;
                                self.setState({ country: country });
                                break;
                        }
                    });
                }

                setTimeout(() => {
                    self.setState({ setMapReady: true, setMapMarkerReady: true });
                });
            }
        }).catch(e => {
            console.warn(e)
        });
    }

    AddAddressPress() {
        let self = this;
        self.setState({ 'isLoading': true })

        if (!self.state.annotation) {
            self.setState({ 'isLoading': false })
            toast.error("Enter Annotation");
            return false;
        }
        if (!self.state.address) {
            self.setState({ 'isLoading': false })
            toast.error("Enter Address");
            return false;
        }
        if (!self.state.landmark) {
            self.setState({ 'isLoading': false })
            toast.error("Enter Landmark");
            return false;
        }


        var json = {
            annotation: self.state.annotation,
            flat_no: self.state.flat_no,
            address: self.state.address,
            landmark: self.state.landmark,
            city: self.state.city,
            receiver_name: self.state.receiver_name,
            mobile: self.state.mobile,
            lat: self.state.latitude,
            lng: self.state.longitude,
            is_default: true,
        };

        axios.post(api.FoodAddressAdd, json, {
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            self.setState({ 'isLoading': false })
            if (response.data.error === 200) {
                self.setState({ 'isLoading': false, })
                self.setState({ pincode: '', address: '', province: '', city: '', phone: '', password: '' })
                toast.success(response.data.message);
                self.timeoutHandle = setTimeout(() => {
                    self.props.history.replace(`/delivery-address`)
                    return false;
                }, 1000);

            } else {
                self.setState({ 'isLoading': false });
                toast.error(response.data.message);
            }
        }).catch(function (error) {
            self.setState({ 'isLoading': false });
            toast.error(strings.no_internet);
        });
    }

    comingSoon() {
        toast.warning(strings.coming_soon);
    }

    handleSelect = async (value) => {
        try {
            if (value && value.geometry && value.geometry.location) {
                this.setState({ setMapReady: false })
                var lat = value.geometry.location.lat();
                var lng = value.geometry.location.lng();
                console.log(value, lat, lng);
                this.getAddressFromCoordinates(lat, lng, true);
            }
            this.setState({ address: (value && value.formatted_address) ? value.formatted_address : "" });
        } catch (error) {
            console.error('Error:', error);
        }
    };


    render() {
        return (
            <>
                <>
                    <SubHeader data={{ headerTitle: strings["address"], withLink: "delivery-address" }} />


                    <section className="map-section">
                        <div style={{ height: '230px', width: '100%' }}>
                            {this.state.setMapReady ?
                                <GoogleMap
                                    apiKey={GOOGLE_MAPS_APIKEY}
                                    defaultCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
                                    defaultZoom={this.state.defaultZoom}
                                    mapMinHeight="230"
                                    onGoogleApiLoaded={({ map, maps }) => {
                                        console.log("map, maps", map, maps);
                                        this.setState({ setMapReady: true })
                                    }}
                                    options={{ gestureHandling: 'none'}}
                                    onChange={(map) => this.setState({ defaultZoom: map.zoom })}
                                >
                                    {this.state.setMapMarkerReady ?
                                        <Marker
                                            lat={this.state.latitude}
                                            lng={this.state.longitude}
                                            markerId={this.state.address}
                                            draggable={true}
                                            zIndex={9999999999}
                                            onDragEnd={(e, { latLng }) => {
                                                this.setState({ setMapMarkerReady: false });
                                                setTimeout(() => {

                                                    this.getAddressFromCoordinates(latLng.lat, latLng.lng, true);
                                                });
                                                console.log(latLng);
                                            }}
                                        />
                                        : null
                                    }

                                </GoogleMap>
                                : null}
                        </div>
                    </section>

                    <section className="form-section">
                        <form action="#" className="form">
                            <div className="input-box">
                                <label>{strings["Address_Name"]}</label>
                                <input onChange={(e) => this.setState({ flat_no: e.target.value })} defaultValue={this.state.flat_no} type="text" placeholder="Name of this address (optional)" />
                                <div className='row'> {this.state.errorMessage.flat_no && <div className='errorMessage'> {this.state.errorMessage.flat_no[0]} </div>}</div>
                            </div>
                            <div className="input-box">
                                <label>{strings["address_line_1"]}</label>
                                <Autocomplete
                                    apiKey={GOOGLE_MAPS_APIKEY}
                                    onPlaceSelected={this.handleSelect}
                                    value={this.state.address}
                                    onChange={(e) => this.setState({ address: e.target.value })}
                                    options={{
                                        componentRestrictions: { country: "my" },
                                    }}
                                />
                                <div className='row'> {this.state.errorMessage.address && <div className='errorMessage'> {this.state.errorMessage.address[0]} </div>}</div>
                            </div>
                            <div className="input-box">
                                <label>{strings["Postcode"]}</label>
                                <input onChange={(e) => this.setState({ landmark: e.target.value })} defaultValue={this.state.landmark} type="text" placeholder="Enter Postcode" required />
                                <div className='row'> {this.state.errorMessage.landmark && <div className='errorMessage'> {this.state.errorMessage.landmark[0]} </div>}</div>
                            </div>

                            <div className="input-box">
                                <label>{strings["city"]}</label>
                                <input onChange={(e) => this.setState({ city: e.target.value })} defaultValue={this.state.city} type="text" placeholder="Enter City" required />
                                <div className='row'> {this.state.errorMessage.city && <div className='errorMessage'> {this.state.errorMessage.city[0]} </div>}</div>
                            </div>

                            <div className="input-box">
                                <label>{strings["receiver_name"]}</label>
                                <input onChange={(e) => this.setState({ receiver_name: e.target.value })} defaultValue={this.state.receiver_name} type="text" placeholder="Please enter receiver name" />
                                <div className='row'> {this.state.errorMessage.receiver_name && <div className='errorMessage'> {this.state.errorMessage.receiver_name[0]} </div>}</div>
                            </div>

                            <div className="input-box mobile-box">
                                <label>{strings["mobile_number"]}</label>
                                <input onChange={(e) => this.setState({ mobile_number: e.target.value })} defaultValue={this.state.mobile_number} className="mobile-field" type="text" placeholder="Enter Mobile Number" />
                                <p>+6 <span>|</span></p>
                                <div className='row'> {this.state.errorMessage.mobile_number && <div className='errorMessage'> {this.state.errorMessage.mobile_number[0]} </div>}</div>
                            </div>

                            <div className="place-box">
                                <h3>Select Place</h3>
                                <div className="place-option">
                                    <div className="place">
                                        <input onClick={() => this.setState({ annotation: "Work" })} type="radio" id="check-work" name="annotation" />
                                        <label htmlFor="check-work">{strings["work"]}</label>
                                    </div>
                                    <div className="place">
                                        <input onClick={() => this.setState({ annotation: "Home" })} type="radio" id="check-home" name="annotation" />
                                        <label htmlFor="check-home">{strings["home"]}</label>
                                    </div>
                                    <div className="place">
                                        <input onClick={() => this.setState({ annotation: "Other" })} type="radio" id="check-other" name="annotation" />
                                        <label htmlFor="check-other">{strings["other"]}</label>
                                    </div>
                                </div>
                                <div className='row'> {this.state.errorMessage.annotation && <div className='errorMessage'> {this.state.errorMessage.annotation[0]} </div>}</div>
                            </div>

                            <button onClick={() => { this.AddAddressPress() }}>Add Address</button>
                        </form>
                    </section>
                </>
            </>
        );
    }
}
export default DeliveryAddressAddSuccess;
