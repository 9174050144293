import React from "react";
import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import image from "../../config/image";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, storeUserData } from "../../components/Util";
import images from "../../config/image";
import SubHeader from "../../components/SubHeader";
import DeliveryMenuBar from "../../components/DeliveryMenuBar";
import _ from "lodash";


class DeliveryAddressSuccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            data: [],
            delete_id: null,
            is_edit: false,
            removeAddressModal: false,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            setTimeout(() => {
                if (this.state.access_token) {
                    this.getAddressList()
                }
            });
        }
    }

    getAddressList() {
        let self = this;
        self.setState({ 'isLoading': true })
        console.log("self.state.access_token", self.state.access_token);
        axios({
            method: 'GET', url: api.FoodAddressList,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(async function (ress) {
            self.setState({ 'isLoading': false })
            self.setState({ 'data': ress.data.message })

            if (ress.data.message && ress.data.message.length > 0) {
                var is_default_address = false;
                var is_default_data = {};
                _.forEach(ress.data.message, function (value, key) {
                    if (value.is_default || key == 0) {
                        self.setState({ 'defaultADdress': value });
                        is_default_address = true;
                        is_default_data = value;
                    }
                });
                if (is_default_address) {
                    await localStorage.setItem('default_address', JSON.stringify(is_default_data));
                }
            }

        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false });
        });
    }

    deleteAddreessConfitm(AID) {
        this.setState({ removeAddressModal: true, delete_id: AID });
    }

    deleteAddreess(AID) {
        let self = this;
        self.setState({ 'isLoading': true })
        axios({
            method: 'POST', url: api.FoodAddress + AID + '/delete',
            data: { id: AID },
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            self.setState({ 'isLoading': false })
            self.setState({ removeAddressModal: false, delete_id: null })
            if (response.data.error != 200) {
                toast.error(response.data.message);
            } else if (response.data.error === 200) {
                toast.success(response.data.message);
                self.getAddressList()
            }

        }).catch(function (error) {
            self.setState({ 'isLoading': false });
            toast.error(strings.no_internet);
        });
    }

    setDefaultAddress(item) {
        let self = this;

        if (item.is_default) {
            self.props.history.replace(`/delivery`)
            return false;
        }

        if (!item.id || item.id == '') {
            self.setState({ 'isLoading': false })
            toast.error(strings.enter_select_address);
            return false;
        }

        self.setState({ 'isLoading': true })
        axios({
            method: 'POST',
            url: api.FoodAddress + item.id + "/default",
            headers: {
                'Authorization': 'Bearer ' + this.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false });
            if (ress.data.error == 200) {
                self.props.history.replace(`/delivery`)
                return false;
            } else {
                toast.error(ress.data.message);
            }
        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false });
        });
    }

    comingSoon() {
        toast.warning(strings.coming_soon);
    }

    render() {
        return (
            <>
                <>
                    <SubHeader data={{ headerTitle: strings["address"], withLink: "delivery" }} />
                    <section className="address-section">
                        <div className="address-container">
                            <div className="address-title-box">
                                <p className="address-title">{strings.address}</p>
                                <Link to={"/delivery-address-add"} className="add-address">{strings.Add_Address}</Link>
                            </div>

                            {this.state.data.map((item, i) =>
                                <>
                                    <div key={"1000_" + i} className="address-detail-container">
                                        <div onClick={() => this.setDefaultAddress(item)}>
                                            {item.is_default ?
                                                <div className="tick-button tick-green"><i className="fa-solid fa-check"></i></div>
                                                :
                                                <div className="tick-button"><i className="fa-solid fa-check"></i></div>
                                            }
                                        </div>
                                        <div className="address-detail">
                                            <p>{strings.receiver_name}: <span>{item.receiver_name}</span></p>
                                            <p>Work: <span>{" +"}{item.mobile}</span></p>
                                            <p>{item.full_address}</p>
                                            <p>{strings.Postcode}: <span>{item.landmark ? item.landmark + ', ' : ''}</span>,  {strings.city}: <span>{item.city}</span></p>
                                        </div>
                                        <a onClick={() => this.deleteAddreessConfitm(item.id)}>
                                            <img src={images["bin-grey"]} alt="" />
                                        </a>
                                    </div>
                                    <hr key={"1000_hr" + i} className="line-in-container" />
                                </>
                            )}
                        </div>

                    </section>

                    <Modal
                        isOpen={this.state.removeAddressModal}
                        onRequestClose={() => this.setState({ removeAddressModal: false })}
                        ariaHideApp={false}
                        className={"modal_main_container"}
                    >
                        <section key={"modal_1"} className="modal_section">
                            <div className="status-content-container">
                                <img src={images["screen-cancel-remove-icon"]} alt="" />
                                <h1>Confirm Remove<br />Address</h1>
                                <a onClick={() => this.deleteAddreess(this.state.delete_id)} className="status-option-1">Confirm</a>
                                <a onClick={() => this.setState({ removeAddressModal: false, delete_id: null })} className="status-option-2">Back</a>
                            </div>
                        </section>
                    </Modal>
                </>
            </>
        );
    }
}
export default DeliveryAddressSuccess;
