import React from "react";
import axios from "axios";
import api from "../config/api";
import { toast } from 'react-toastify';
import { Link, NavLink, withRouter } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import strings from "./Language";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import images from "../config/image";

class SubHeader extends React.Component {
    constructor(props) {
        super(props);
        var headerTitle = (this.props && this.props.data && this.props.data.headerTitle) ? this.props.data.headerTitle : "";
        var withLink = (this.props && this.props.data && this.props.data.withLink) ? this.props.data.withLink : "";
        var not_display_help = (this.props && this.props.data && this.props.data.not_display_help) ? this.props.data.not_display_help : false;
        this.state = {
            lang: strings.getLanguage(),
            headerTitle: headerTitle,
            withLink: withLink ? withLink : null,
            not_display_help: not_display_help ? not_display_help : false,
        }
    }

    async componentDidMount() {

    }

    handleGoBack = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <>
                <HelmetProvider>
                    <Helmet>
                        <title>{strings["BeliBeli"]} | {this.state.headerTitle}</title>
                    </Helmet>
                </HelmetProvider>

                {/* Top Bar */}
                <section className="topbar-section">
                    <div className="topbar-container">
                        <div className="topbar-left">
                            {this.state.withLink ?
                                <Link to={`/${this.state.withLink}`}><i className="fa-solid fa-chevron-left"></i></Link>
                                :
                                <a onClick={this.handleGoBack}><i className="fa-solid fa-chevron-left"></i></a>
                            }
                        </div>
                        <div className="topbar-center-small">{this.state.headerTitle}</div>
                        <div className="topbar-right">
                            {this.state.not_display_help ? null :
                                <>
                                    <div className="topbar-helpicon"><img src={images["help-icon"]} alt="" /></div>
                                    <div className="topbar-helpline">Helpline</div>
                                </>
                            }
                        </div>
                    </div>
                </section>

            </>
        );
    }
}

export default withRouter(SubHeader);