import React from "react";
import axios from "axios";
import api from "../config/api";
import { toast } from 'react-toastify';
import { Link, NavLink } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import strings from "./Language";
import { Helmet, HelmetProvider } from 'react-helmet-async';

class DeliveryMenuBar extends React.Component {
    constructor(props) {
        super(props);
        var headerTitle = (this.props && this.props.data && this.props.data.headerTitle) ? this.props.data.headerTitle : "";
        this.state = {
            lang: strings.getLanguage(),
            is_mobile_menu: false,
            is_lang_menu: false,
            headerTitle: headerTitle,
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <>
                <header className="header" id="header">
                    <nav className="nav-container">
                        <div className="nav-menu" id="nav-menu">
                            <ul className="nav-list">
                                <li key={"li_01_1"} className="nav-item">
                                    <NavLink to={'/delivery'} className={({isActive}) => (isActive ? "nav-link active" : 'nav-link')}>
                                        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 16C4.16667 16 3.45833 15.7083 2.875 15.125C2.29167 14.5417 2 13.8333 2 13H0V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16V4H19L22 8V13H20C20 13.8333 19.7083 14.5417 19.125 15.125C18.5417 15.7083 17.8333 16 17 16C16.1667 16 15.4583 15.7083 14.875 15.125C14.2917 14.5417 14 13.8333 14 13H8C8 13.8333 7.70833 14.5417 7.125 15.125C6.54167 15.7083 5.83333 16 5 16ZM5 14C5.28333 14 5.52083 13.9042 5.7125 13.7125C5.90417 13.5208 6 13.2833 6 13C6 12.7167 5.90417 12.4792 5.7125 12.2875C5.52083 12.0958 5.28333 12 5 12C4.71667 12 4.47917 12.0958 4.2875 12.2875C4.09583 12.4792 4 12.7167 4 13C4 13.2833 4.09583 13.5208 4.2875 13.7125C4.47917 13.9042 4.71667 14 5 14ZM17 14C17.2833 14 17.5208 13.9042 17.7125 13.7125C17.9042 13.5208 18 13.2833 18 13C18 12.7167 17.9042 12.4792 17.7125 12.2875C17.5208 12.0958 17.2833 12 17 12C16.7167 12 16.4792 12.0958 16.2875 12.2875C16.0958 12.4792 16 12.7167 16 13C16 13.2833 16.0958 13.5208 16.2875 13.7125C16.4792 13.9042 16.7167 14 17 14ZM16 9H20.25L18 6H16V9Z" fill="#707070" />
                                        </svg>
                                        <span className="nav-name">Delivery</span>
                                    </NavLink>
                                </li>

                                <li key={"li_01_2"} className="nav-item">
                                    <NavLink to={'/delivery-orders'} className={({isActive}) => (isActive ? "nav-link active" : 'nav-link')}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 14C5.28333 14 5.52083 13.9042 5.7125 13.7125C5.90417 13.5208 6 13.2833 6 13C6 12.7167 5.90417 12.4792 5.7125 12.2875C5.52083 12.0958 5.28333 12 5 12C4.71667 12 4.47917 12.0958 4.2875 12.2875C4.09583 12.4792 4 12.7167 4 13C4 13.2833 4.09583 13.5208 4.2875 13.7125C4.47917 13.9042 4.71667 14 5 14ZM5 10C5.28333 10 5.52083 9.90417 5.7125 9.7125C5.90417 9.52083 6 9.28333 6 9C6 8.71667 5.90417 8.47917 5.7125 8.2875C5.52083 8.09583 5.28333 8 5 8C4.71667 8 4.47917 8.09583 4.2875 8.2875C4.09583 8.47917 4 8.71667 4 9C4 9.28333 4.09583 9.52083 4.2875 9.7125C4.47917 9.90417 4.71667 10 5 10ZM5 6C5.28333 6 5.52083 5.90417 5.7125 5.7125C5.90417 5.52083 6 5.28333 6 5C6 4.71667 5.90417 4.47917 5.7125 4.2875C5.52083 4.09583 5.28333 4 5 4C4.71667 4 4.47917 4.09583 4.2875 4.2875C4.09583 4.47917 4 4.71667 4 5C4 5.28333 4.09583 5.52083 4.2875 5.7125C4.47917 5.90417 4.71667 6 5 6ZM8 14H14V12H8V14ZM8 10H14V8H8V10ZM8 6H14V4H8V6ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2Z" fill="#707070" />
                                        </svg>
                                        <span className="nav-name">Orders</span>
                                    </NavLink>
                                </li>

                                <li key={"li_01_3"} className="nav-item">
                                    <NavLink to={'/messages'} className={({isActive}) => (isActive ? "nav-link active" : 'nav-link')}>
                                        <svg className="nav-icon" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.507 0H2.43759C1.26867 0 0.321777 0.947447 0.321777 2.11581V11.7046C0.321777 12.8729 1.26867 13.8204 2.43759 13.8204H3.25718L1.58376 17.1771C1.46401 17.3651 1.49253 17.6102 1.65137 17.7647C1.74197 17.854 1.86048 17.8987 1.9796 17.8987C2.06899 17.8987 2.16078 17.8727 2.24084 17.8193L9.21245 13.8204H17.507C18.676 13.8204 19.6228 12.8729 19.6228 11.7046V2.11581C19.6228 0.947447 18.6759 0 17.507 0ZM5.62136 8.29186C4.8444 8.29186 4.21452 7.66198 4.21452 6.88502C4.21452 6.10806 4.8444 5.47817 5.62136 5.47817C6.39837 5.47817 7.02821 6.10806 7.02821 6.88502C7.02821 7.66198 6.39833 8.29186 5.62136 8.29186ZM9.97231 8.29186C9.19531 8.29186 8.56547 7.66198 8.56547 6.88502C8.56547 6.10806 9.19531 5.47817 9.97231 5.47817C10.7493 5.47817 11.3792 6.10806 11.3792 6.88502C11.3792 7.66198 10.7493 8.29186 9.97231 8.29186ZM14.3232 8.29186C13.5463 8.29186 12.9164 7.66198 12.9164 6.88502C12.9164 6.10806 13.5463 5.47817 14.3232 5.47817C15.1002 5.47817 15.7301 6.10806 15.7301 6.88502C15.7301 7.66198 15.1002 8.29186 14.3232 8.29186Z" fill="#707070" />
                                        </svg>
                                        <span className="nav-name">Messages</span>
                                    </NavLink>
                                </li>

                                <li key={"li_01_4"} className="nav-item">
                                    <NavLink to={'/home'} className={({isActive}) => (isActive ? "nav-link active" : 'nav-link')}>
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.8686 7.23097L16.5685 5.35635V2.35829C16.5685 2.02069 16.2938 1.74598 15.9562 1.74598H14.5089C14.1713 1.74598 13.8966 2.02069 13.8966 2.35829V3.17863L10.8231 0.673763C10.6008 0.49263 10.3078 0.392822 9.99808 0.392822C9.68959 0.392822 9.39796 0.492017 9.17686 0.672204L1.13145 7.23092C0.765902 7.52878 0.787834 7.78823 0.833424 7.91637C0.878902 8.04423 1.02536 8.25876 1.49494 8.25876H2.59661V16.8839C2.59661 17.4994 3.09603 18.0001 3.7099 18.0001H7.18896C7.79765 18.0001 8.27442 17.5098 8.27442 16.8839V13.3469C8.27442 13.0437 8.55302 12.7677 8.8589 12.7677H11.1968C11.4884 12.7677 11.7256 13.0275 11.7256 13.3469V16.8839C11.7256 17.4889 12.2482 18.0001 12.8668 18.0001H16.2902C16.904 18.0001 17.4035 17.4994 17.4035 16.8839V8.25876H18.5051C18.9747 8.25876 19.1211 8.04423 19.1666 7.91637C19.2122 7.78823 19.2342 7.52878 18.8686 7.23097Z" fill="#707070" />
                                        </svg>
                                        <span className="nav-name">Home</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>

            </>
        );
    }
}

export default DeliveryMenuBar;