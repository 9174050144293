import React from "react";

import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, storeUserData } from "../../components/Util";

import images from "../../config/image";
import SubHeader from "../../components/SubHeader";

class MessagesScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            data: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            this.setState({ access_token: token });
            await storeUserData(token);
            setTimeout(() => {
                this.loadNotification(api.Notification);
            });
        }
    }

    loadNotification(API) {
        if (API) { } else { return false };
        let self = this;
        self.setState({ 'isLoading': true })

        axios({
            method: 'GET',
            url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })
            console.log(ress.data);
            self.setState({
                data: [...self.state.data, ...(ress.data.message && ress.data.message.data) ? ress.data.message.data : []],
                next_page_url: (ress.data.message && ress.data.message.next_page_url) ? ress.data.message.next_page_url : null,
                prev_page_url: (ress.data.message && ress.data.message.prev_page_url) ? ress.data.message.prev_page_url : null,
            })
        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false });
        });
    }

    render() {
        return (
            <>
                {/* <MenuBar /> */}
                <>
                    <div className="home-content-wrapper">

                        <SubHeader data={{ headerTitle: strings["messages"] }} />

                        <section className="messages-section">
                            <div className="messages-container">

                                {this.state.data.map((item, i) =>
                                    <div key={"1000_" + i}>
                                        <div className="message-row">
                                            <div className="message-col-1">
                                                <img src={images["messages-icon"]} alt="" />
                                            </div>
                                            <div className="message-col-2">
                                                {/* <h2>Order Canceled</h2> */}
                                                <h3>{item._description}</h3>
                                                <h3>{item._created_at}</h3>
                                            </div>
                                        </div>
                                        <hr className="message-row-bottom-line" />
                                    </div>
                                )}

                                {this.state.next_page_url ?
                                    <a className="view-more" onClick={() => this.loadNotification(this.state.next_page_url)}>View More</a>
                                    : null}
                            </div>
                        </section>
                    </div>
                </>
            </>
        );
    }
}
export default MessagesScreen;
