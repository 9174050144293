import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";

class RegisterScreen extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.history);
        this.state = {
            selectedLang: localStorage.getItem("language"),
            languageModal: false,
            successModal: false,
            name: null,
            mobile: null,
            otp: null,
            email: null,
            password: null,
            password_show: false,
            password_confirmation: null,
            password_confirmation_show: false,
            errorMessage: [],
        }
    }

    async componentDidMount() {
    }

    /**
 * Change Language
 * @param {string} obj 
 */
    async handleChange() {
        let obj = this.state.selectedLang;
        if (obj === "my") {
            strings.setLanguage('my');
            await localStorage.setItem("language", 'my');
            this.setState({ lang: 'my' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ lang: 'en' });
            window.location.reload();
        }
    };

    onSubmit() {
        const self = this;
        axios({
            method: 'POST',
            url: api.Register,
            data: {
                name: this.state.name ?? '',
                mobile: this.state.mobile ?? '',
                otp: this.state.otp ?? '',
                email: this.state.email ?? '',
                password: this.state.password ?? '',
                password_confirmation: this.state.password_confirmation ?? '',
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': this.state.lang,
            }
        }).then(async function (ress) {
            console.log(ress.data);
            if (ress.data.error == 202 || ress.data.error == '202') {
                if (ress.data.errors && ress.data.errors.length != 0) {
                    self.setState({ errorMessage: ress.data.errors });
                } else {
                    toast.error(ress.data.message);
                }
            } else {
                // await localStorage.setItem('user_data', JSON.stringify((ress.data.user) ? ress.data.user : []));
                // await localStorage.setItem('user_token', (ress.data.access_token) ? ress.data.access_token : '');
                toast.success(ress.data.message);
                self.setState({ successModal: true });
                return false;
            }
        }).catch(function (err) {
            if (err && err.response && (err.response.status === '422')) {
                if (err && err.response && err.response.data && err.response.data.errors) {
                    self.setState({ errorMessage: err.response.data.errors });
                }
            }
            if (err && err.response && err.response.data && (err.response.data.error === '202' || err.response.data.error === 202)) {
                let msg = err.response.data && err.response.data.message ? err.response.data.message : strings.something_wrong;
                toast.error(msg);
            } else {
                toast.error(strings.invalid_login_details);
            }
        });
    }

    sendOtp() {
        const self = this;
        axios({
            method: 'POST',
            url: api.RegisterOTP,
            data: {
                mobile: this.state.mobile ?? '',
                email: this.state.email ?? '',
            },
            headers: { 'Content-Type': 'application/json', }
        }).then(function (ress) {
            console.log(ress.data);
            if (ress.data.error == 202 || ress.data.error == '202') {
                if (ress.data.errors && ress.data.errors.length != 0) {
                    self.setState({ errorMessage: ress.data.errors });
                } else {
                    toast.error(ress.data.message);
                }
            } else {
                toast.success(ress.data.message);
            }
        }).catch(function (err) {
            if (err && err.response && (err.response.status === '422')) {
                if (err && err.response && err.response.data && err.response.data.errors) {
                    self.setState({ errorMessage: err.response.data.errors });
                }
            }
            if (err && err.response && err.response.data && (err.response.data.error === '202' || err.response.data.error === 202)) {
                let msg = err.response.data && err.response.data.message ? err.response.data.message : strings.something_wrong;
                toast.error(msg);
            } else {
                toast.error(strings.server_error);
            }
        });
    }

    render() {
        return (
            <>
                <SubHeader data={{ headerTitle: "Sign Up" }} />
                <>
                    <section className="login-signup-header-section">
                        <div className="login-signup-header-container">
                            <div className="change-language">
                                <a onClick={() => this.setState({ languageModal: true })}><p>{strings[this.state.selectedLang]}</p></a>
                            </div>

                            <div className="signup-title">
                                <p>Get an account</p>
                                <h1>Sign Up to Belibeli</h1>
                            </div>
                        </div>
                    </section>

                    <section className="form-section signup-section">
                        <div className="form">
                            <div className="input-box">
                                <label>Name<span>*</span></label>
                                <input onChange={(e) => this.setState({ name: e.target.value })} defaultValue={this.state.name} type="text" placeholder="Enter Name" />
                                <div className='row'> {this.state.errorMessage.name && <div className='errorMessage'> {this.state.errorMessage.name[0]} </div>}</div>
                            </div>

                            <div className="input-box">
                                <label>Mobile Number<span>*</span></label>
                                <input onChange={(e) => this.setState({ mobile: e.target.value })} defaultValue={this.state.mobile} type="number" placeholder="Enter Mobile Number" />
                                <div className='row'> {this.state.errorMessage.mobile && <div className='errorMessage'> {this.state.errorMessage.mobile[0]} </div>}</div>
                            </div>

                            <div className="input-box">
                                <label>Email Address<span>*</span></label>
                                <input onChange={(e) => this.setState({ email: e.target.value })} defaultValue={this.state.email} type="text" placeholder="Enter Email Address" />
                                <div className='row'> {this.state.errorMessage.email && <div className='errorMessage'> {this.state.errorMessage.email[0]} </div>}</div>
                            </div>

                            <div className="input-box password-input">
                                <label>Password<span className="compulsory">*</span></label>
                                <input onChange={(e) => this.setState({ password: e.target.value })} defaultValue={this.state.password} type={this.state.password_show ? "text" : "password"} placeholder="Enter Password" />
                                <span onClick={() => this.setState({ password_show: !this.state.password_show })}><i className="fa-solid fa-eye-slash" id="toggler"></i></span>
                                <div className='row'> {this.state.errorMessage.password && <div className='errorMessage'> {this.state.errorMessage.password[0]} </div>}</div>
                            </div>

                            <div className="input-box password-input">
                                <label>Confirmation Password<span className="compulsory">*</span></label>
                                <input onChange={(e) => this.setState({ password_confirmation: e.target.value })} defaultValue={this.state.password_confirmation} type={this.state.password_confirmation_show ? "text" : "password"} placeholder="Enter Confirmation Password" />
                                <span onClick={() => this.setState({ password_confirmation_show: !this.state.password_confirmation_show })}><i className="fa-solid fa-eye-slash" id="toggler"></i></span>
                                <div className='row'> {this.state.errorMessage.password_confirmation && <div className='errorMessage'> {this.state.errorMessage.password_confirmation[0]} </div>}</div>
                            </div>

                            <div className="input-box">
                                <label>OTP<span>*</span></label>
                                <div className="otp-row">
                                    <input onChange={(e) => this.setState({ otp: e.target.value })} defaultValue={this.state.otp} type="number" placeholder="Enter OTP" />
                                    <a onClick={() => this.sendOtp()}>Get Code</a>
                                </div>
                                <div className='row'> {this.state.errorMessage.otp && <div className='errorMessage'> {this.state.errorMessage.otp[0]} </div>}</div>
                            </div>

                            <div className="signup-action-btn">
                                <button onClick={() => this.onSubmit()}>Sign Up</button>
                                <Link to={"/forgot-password"}><p>Forgot Password?</p></Link>
                            </div>

                            <div className="privacy-policy">
                                <p><span>Privacy Policy</span> and <span>Terms of Service</span> apply</p>
                            </div>
                        </div>
                    </section>

                    <Modal
                        isOpen={this.state.languageModal}
                        onRequestClose={() => this.setState({ languageModal: false })}
                        ariaHideApp={false}
                        className={"modal_main_container"}
                    >
                        <div className="modal_section">
                            <section className="popup-section">
                                <div className="popup-container choose-language">
                                    <div className="choose-language-box">
                                        <p className="current-language">Current Language: <span className="language-span">{strings[this.state.selectedLang]}</span></p>
                                        <div className="language-box">
                                            <p>Select Language</p>
                                            <div className="language-option">
                                                <div className="language">
                                                    <input onChange={(e) => this.setState({ selectedLang: e.target.value })} defaultValue={'en'} defaultChecked={this.state.selectedLang == 'en' ? true : false} type="radio" id="check-english" name="language" />
                                                    <div className="check-green"><i className="fa-solid fa-check"></i></div>
                                                    <label htmlFor="check-english">{strings['en']}</label>
                                                </div>

                                                <div className="language">
                                                    <input onChange={(e) => this.setState({ selectedLang: e.target.value })} defaultValue={'my'} defaultChecked={this.state.selectedLang == 'my' ? true : false} type="radio" id="check-malaysia" name="language" />
                                                    <div className="check-green"><i className="fa-solid fa-check"></i></div>
                                                    <label htmlFor="check-malaysia">{strings['my']}</label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="language-button">
                                        <div className="cancel-button" onClick={() => this.setState({ languageModal: false })}>Cancel</div>
                                        <button className="change-language-button" onClick={() => this.handleChange()}>Change Language</button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={this.state.successModal}
                        ariaHideApp={false}
                        className={"modal_main_container"}
                    >
                        <div className="modal_section">
                            <div className="popup-body">
                                <section className="popup-section">
                                    <div className="popup-container">
                                        <div className="top">
                                            <img src={images["signup-complete"]} alt="" />
                                            <p>Sign Up Complete</p>
                                        </div>

                                        <div className="back-button"><Link to={"/"}>Back</Link></div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </Modal>
                </>
            </>
        );
    }
}
export default RegisterScreen;
