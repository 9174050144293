import React from 'react'
import { useLocation } from 'react-router-dom'
import strings from "./../../components/Language";
import Header from '../../components/Header';

const NotFound = () => {
  const location = useLocation()

  return (
    <>
      <Header data={{ headerTitle: strings["BeliBeli"] }} />
      <>

        <section className="form-section login-section text-center mt-5">
          <div className="form">
            <div className="form-cover error-middle">
              <h1>404</h1>
              <p>
                {strings.we_could_not_find} <u>{location.pathname}</u> {strings.page}
              </p>
            </div>
          </div>
        </section>
      </>
    </>
  )
}

export default NotFound
