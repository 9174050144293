import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";
import SubHeader from "../../components/SubHeader";
import images from "../../config/image";
import moment from "moment";

class PointsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            errorMessage: [],
            data: [],
            next_page_url: null,
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            setTimeout(() => {
                this.setState({ USER_DATA: userData.data });
                this.getReport(api.BegoPointReport);
            });
        }
    }

    getReport(API) {
        if (API == "" || API == null) {
            return false;
        }
        const self = this;
        self.setState({ 'isLoading': true })
        axios({
            method: 'GET', url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            self.setState({ 'isLoading': false })
            if (ress.data.error == 200) {
                self.setState({
                    data: [...self.state.data, ...ress.data.message.data],
                    next_page_url: ress.data.message.next_page_url
                });
            }
        }).catch(function (erro) {
            toast.error(strings.server_error);
            self.setState({ 'isLoading': false });
        });
    }

    render() {
        return (
            <>
                <div className="home-content-wrapper">
                    <SubHeader data={{ headerTitle: strings["bego_point"] }} />
                    <section className="points-info-section">
                        <div className="tabs">
                            <div className="content">
                                <section className="all-history-section">
                                    {this.state.data.map((item, i) =>
                                        <>
                                            <div key={"1000_" + i}>
                                                <div className="points-info-container">
                                                    <div className="checkin-details">
                                                        <h1>Daily Check-In</h1>
                                                        <h2>{item._description ?? "-"}</h2>
                                                        <h2>{moment(item.created_at).format("MM-DD-YYY hh:ss")}</h2>
                                                    </div>
                                                    <div className="checkin-points">
                                                        <h2>{strings.rm}{" "}{number_format(item.balance, 2, '.', ',')}</h2>
                                                        <h1>{strings.rm}{" "}{(item.amount > 0) ? "+" : ""}{number_format(item.amount, 2, '.', ',')}</h1>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </>
                                    )}

                                    {this.state.next_page_url ?
                                        <a className="view-more" onClick={() => this.getReport(this.state.next_page_url)}>View More</a>
                                        : null}
                                </section>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}
export default PointsScreen;
