import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import images from "../../config/image";

class ProfileScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            showCloseAccountAlert: false
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            setTimeout(() => {
                console.log(userData.data);
                this.setState({ USER_DATA: userData.data });
            });
        }
    }

    Logout() {
        localStorage.clear();
        window.location.reload(false);
    }

    hideCloseAccountAlert = () => {
        this.setState({ showCloseAccountAlert: false });
    };

    _onPressCloseAccount() {
        // ToastMessage("success", strings.permanent_delete_account_msg);
        toast.success(strings.permanent_delete_account_msg);
        this.setState({ showCloseAccountAlert: false });
    }

    render() {
        return (
            <>
                <MenuBar />
                <>
                    <div className="home-content-wrapper">
                        <Header data={{ headerTitle: strings["profile"] }} />

                        <section className="profile-page-section">
                            <div className="profile-page-container">
                                <div className="profile-page-row">
                                    <div className="profile-page-col-1">
                                        {(this.state.USER_DATA && this.state.USER_DATA.user && this.state.USER_DATA.user.avatar) ?
                                            <img src={api.ProfilePic_URL + this.state.USER_DATA.user.avatar} alt="" style={{ width: 50, height: 50, borderWidth: 0.5, borderStyle: "solid", borderRadius: 100, borderColor: "gray" }} />
                                            :
                                            <div style={{ width: 50, height: 50, borderWidth: 0.5, borderStyle: "solid", borderRadius: 100, borderColor: "gray" }}></div>
                                        }
                                    </div>
                                    <div className="profile-page-col-2">
                                        <h2>{this.state.USER_DATA.user && this.state.USER_DATA.user.name ? this.state.USER_DATA.user.name : ""}</h2>
                                        <h3>Edit Profile</h3>
                                    </div>
                                    <Link to={"/profile/edit"}>
                                        <div className="profile-page-col-3">
                                            <img src={images["nextarrow-icon"]} alt="" />
                                        </div>
                                    </Link>
                                </div>
                                <hr className="profile-page-row-bottom-line" />
                            </div>
                        </section>

                        <section className="profile-features-section">
                            <div className="profile-features-container">
                                <div className="profile-feature-row">
                                    <div className="profile-feature-col-1">
                                        <img src={images["credit-point1"]} alt="" />
                                    </div>
                                    <div className="profile-feature-col-2">
                                        <h2>BeliBeli Credit</h2>
                                        <h3 className="profile-text-red">{strings.rm}{this.state.USER_DATA && this.state.USER_DATA.credit && this.state.USER_DATA.credit.bego_credit ? number_format(this.state.USER_DATA.credit.bego_credit, 2, '.', ',') : '0.00'}</h3>
                                    </div>
                                    <Link to={"/profile/credit"}>
                                        <div className="profile-feature-col-3">
                                            <img src={images["nextarrow-icon"]} alt="" />
                                        </div>
                                    </Link>
                                </div>

                                <div className="profile-feature-row">
                                    <div className="profile-feature-col-1">
                                        <img src={images["credit-point2"]} alt="" />
                                    </div>
                                    <div className="profile-feature-col-2">
                                        <h2>BeliBeli Points</h2>
                                        <h3 className="profile-text-red">{this.state.USER_DATA && this.state.USER_DATA.credit && this.state.USER_DATA.credit.bego_point ? number_format(this.state.USER_DATA.credit.bego_point, 2, '.', ',') : '0.00'}</h3>
                                    </div>
                                    <Link to={"/profile/points"}>
                                        <div className="profile-feature-col-3">
                                            <img src={images["nextarrow-icon"]} alt="" />
                                        </div>
                                    </Link>
                                </div>

                                <div className="profile-feature-row">
                                    <div className="profile-feature-col-1">
                                        <img src={images["map-icon"]} alt="" />
                                    </div>
                                    <div className="profile-feature-col-2">
                                        <h2>Address</h2>
                                        <h3>Add or edit address</h3>
                                    </div>
                                    <Link to={"/address"}>
                                        <div className="profile-feature-col-3">
                                            <img src={images["nextarrow-icon"]} alt="" />
                                        </div>
                                    </Link>
                                </div>

                                <div className="profile-feature-row">
                                    <div className="profile-feature-col-1">
                                        <img src={images["referralcode-icon"]} alt="" />
                                    </div>
                                    <div className="profile-feature-col-2">
                                        <h2>Referral Code</h2>
                                        <h3>Share or scan QR code</h3>
                                    </div>
                                    <Link to={"/profile/referral-code"}>
                                        <div className="profile-feature-col-3">
                                            <img src={images["nextarrow-icon"]} alt="" />
                                        </div>
                                    </Link>
                                </div>

                                <div className="profile-feature-row">
                                    <div className="profile-feature-col-1">
                                        <img src={images["application-icon"]} alt="" />
                                    </div>
                                    <div className="profile-feature-col-2">
                                        <h2>Application</h2>
                                        <h3>Apply for Marketplace Merchant, E-Hailing Driver, Delivery Rider, Food Merchant</h3>
                                    </div>
                                    <Link to={"/application"}>
                                        <div className="profile-feature-col-3">
                                            <img src={images["nextarrow-icon"]} alt="" />
                                        </div>
                                    </Link>
                                </div>

                                <div className="profile-feature-row">
                                    <div className="profile-feature-col-1">
                                        <img src={images["policies-icon"]} alt="" />
                                    </div>
                                    <div className="profile-feature-col-2">
                                        <h2>Policies</h2>
                                        <h3>Privacy Policy & Shipping/Fulfillment Policy</h3>
                                    </div>
                                    <a href="https://wego.global/privacy-policy">
                                        <div className="profile-feature-col-3">
                                            <img src={images["nextarrow-icon"]} alt="" />
                                        </div>
                                    </a>
                                </div>

                                <div className="profile-feature-row">
                                    <div className="profile-feature-col-1">
                                        <img src={images["settings-icon"]} alt="" />
                                    </div>
                                    <div className="profile-feature-col-2">
                                        <h2>Settings</h2>
                                        <h3>Account Settings</h3>
                                    </div>
                                    <Link to={"/profile/settings"}>
                                        <div className="profile-feature-col-3">
                                            <img src={images["nextarrow-icon"]} alt="" />
                                        </div>
                                    </Link>
                                </div>

                                <div className="profile-feature-row">
                                    <div className="profile-feature-col-1">
                                        <img src={images["contactus-icon"]} alt="" />
                                    </div>
                                    <div className="profile-feature-col-2">
                                        <h2>Contact Us</h2>
                                        <h3>Contact us about your inquiries</h3>
                                    </div>
                                    <a href="https://wa.me/60198896393">
                                        <div className="profile-feature-col-3">
                                            <img src={images["nextarrow-icon"]} alt="" />
                                        </div>
                                    </a>
                                </div>

                                <div className="profile-feature-row">
                                    <div className="profile-feature-col-1">
                                        <img src={images["closeacc-icon"]} alt="" />
                                    </div>
                                    <div className="profile-feature-col-2">
                                        <h2>Close Account</h2>
                                        <h3>Permanent delete your account</h3>
                                    </div>
                                    <a onClick={() => this.setState({ showCloseAccountAlert: true })}>
                                        <div className="profile-feature-col-3">
                                            <img src={images["nextarrow-icon"]} alt="" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </section>
                    </div>

                    <Modal
                        isOpen={this.state.showCloseAccountAlert}
                        onRequestClose={() => this.setState({ showCloseAccountAlert: false })}
                        ariaHideApp={false}
                        className={"modal_main_container"}
                    >
                        <div className="modal_section">
                            <section className="status-content-section-delete-account">
                                <div className="status-content-container">
                                    <img src={images['screen-cancel-remove-icon']} alt="" />
                                    <h1>Confirm Close Account?</h1>
                                    <h2>All information will be lost</h2>
                                    <a className="status-option-1" onClick={() => this._onPressCloseAccount()}>Confirm</a>
                                    <a className="status-option-2" onClick={() => this.setState({ showCloseAccountAlert: false })}>Back</a>
                                </div>
                            </section>
                        </div>
                    </Modal>
                </>
            </>
        );
    }
}
export default ProfileScreen;
