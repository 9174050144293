import React from "react";

import Header from "../../components/Header";
import strings from "../../components/Language";
import { Link, withRouter } from "react-router-dom";
import Modal from 'react-modal';
import { toast } from "react-toastify";
import api from "../../config/api";
import axios from "axios";
import MenuBar from "../../components/MenuBar";
import { getToken, getUserData, number_format, storeUserData } from "../../components/Util";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import images from "../../config/image";

class HomeScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            access_token: '',
            USER_DATA: {},
            sliderImages: [],
            sectionList: [],
            errorMessage: [],
        }
    }

    async componentDidMount() {
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            this.setState({ access_token: token });
            var userData = await getUserData();
            setTimeout(() => {
                this.setState({ USER_DATA: userData.data });
                this.getSlider();
                this.getSectionList();
            });
        }
    }

    getSlider() {
        let self = this;
        axios({
            method: 'GET',
            url: api.DashboardSlider + "?lang=" + self.state.lang,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }
        ).then(function (ress) {
            self.setState({ sliderImages: ress.data.message });
        }).catch(function (erro) {
            toast.error(strings.server_error)
        });
    }

    getSectionList() {
        let self = this;
        axios({
            method: 'GET',
            url: api.sectionList + "?lang=" + self.state.lang,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json',
            }
        }
        ).then(function (ress) {
            self.setState({ sectionList: ress.data.message })
        }).catch(function (erro) {
            toast.error(strings.server_error);
        });
    }

    render() {
        const settings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 2500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (
            <>
                <MenuBar />
                <>
                    <div className="home-content-wrapper">
                        <Header data={{ headerTitle: strings["BeliBeli"] }} />

                        <section className="home-banner-section">
                            <div className="home-banner-container">
                                <Slider {...settings}>
                                    {this.state.sliderImages.map((item, i) =>
                                        <div key={"1000_" + i} className="home-banner-image">
                                            {item.file_en ?
                                                <img src={api.Slider_URL + item.file_en} alt="" />
                                                :
                                                <img src={images["home-delivery-background"]} alt="" />
                                            }
                                        </div>
                                    )}
                                </Slider>
                            </div>
                        </section>

                        <section className="credit-point-section">
                            <Link to={"/profile/credit"} className="credit-container">
                                <div className="credit-icon">
                                    <img src={images["credit-point1"]} alt="" />
                                </div>
                                <div className="credit-detail">
                                    <h3>Belibeli Credit</h3>
                                    <p>{strings.rm} {this.state.USER_DATA && this.state.USER_DATA.credit && this.state.USER_DATA.credit.bego_credit ? number_format(this.state.USER_DATA.credit.bego_credit, 2, '.', ',') : '0.00'}</p>
                                </div>
                            </Link>

                            <Link to={"/profile/points"} className="point-container">
                                <div className="point-icon">
                                    <img src={images["credit-point2"]} alt="" />
                                </div>
                                <div className="point-detail">
                                    <h3>Belibeli Point</h3>
                                    <p>{this.state.USER_DATA && this.state.USER_DATA.credit && this.state.USER_DATA.credit.bego_point ? number_format(this.state.USER_DATA.credit.bego_point, 2, '.', ',') : '0.00'}</p>
                                </div>
                            </Link>
                        </section>
                        <hr className="credit-point-separator" />

                        <section className="feature-section">
                            <div className="feature-container">
                                <div className="feature-col delivery-item">
                                    <Link to="/delivery">
                                        <img src={images["feature1"]} alt="" />
                                        <h3>Delivery</h3>
                                    </Link>
                                </div>
                                <div className="feature-col ehailing-item">
                                    <img src={images["feature2"]} alt="" />
                                    <h3>E-Hailing</h3>
                                </div>
                                <div className="feature-col mall-item">
                                    <img src={images["feature3"]} alt="" />
                                    <h3>Mall</h3>
                                </div>
                                <div className="feature-col services-item">
                                    <img src={images["feature4"]} alt="" />
                                    <h3>Services</h3>
                                </div>
                            </div>
                        </section>

                        <section className="e-hailing-section">
                            <div className="e-hailing-container">
                                <h1>E-Hailing</h1>
                                <img src={images["e-hailing"]} alt="" />
                            </div>
                        </section>

                        <section className="news-section">
                            <div className="news-container">
                                <h1>News discovery</h1>
                                <div className="news-row">
                                    {this.state.sectionList.map((item, i) =>
                                        <div key={"2000_" + i} className="news-col how-to-order-item">
                                            {item.banner ?
                                                <img src={api.uploadPath + item.banner} alt="" />
                                                :
                                                <img src={images["news1"]} alt="" />
                                            }
                                            <p>{item.title ?? ''}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </section>
                    </div >
                </>
            </>
        );
    }
}
export default HomeScreen;
